import Phaser from "phaser";
import sceneFlow from "../../json/sceneFlow.json";
import jsonData from "../../json/speech.json";
import jsonScenario from "../../json/scenarioSpeech.json";
import jsonConsole from "../../json/consoleSpeech.json";
import jsonVisualiser from "../../json/visualiserSpeech.json";
import { loadAssets } from "../../lib/util";

export default class PreloadScene extends Phaser.Scene {
  sceneParam!: string | null;
  loadResult!: boolean;
  videoParam!: string | null;
  skipTo!: string | null;
  channelParam!: string | null;

  constructor() {
    super("PreloadScene");
  }

  preload() {}

  init() {
    this.skipTo = new URL(window.location.href).searchParams.get("skip");
    this.channelParam = new URL(window.location.href).searchParams.get("ch");
  }

  create() {
    this.scene.setActive(false);
    const startSceneEnvVar = process.env.START_SCENE;
    let sceneToLoad;

    if (this.channelParam) {
      sceneToLoad = sceneFlow[this.channelParam];
    }
    if (this.skipTo) {
      this.scene.start(this.skipTo);
    } else if (sceneToLoad) {
      if (sceneToLoad[0].SceneType === "AssetLoaderScene") {
        this.scene.start(sceneToLoad[0].SceneType, {
          data: {
            chapter: this.channelParam,
            sceneToLoad: {
              sceneName: sceneToLoad[0].Data.SceneType,
              data: {
                video: sceneToLoad[0].Data.Video,
                nextVideo: sceneToLoad[0].Data.NextVideo,
                nextScene: sceneToLoad[0].Data.NextScene,
                json: sceneToLoad[0].Data.Json,
                skipEnable: sceneToLoad[0].Data.SkipEnable ?? false,
                fadeType: sceneToLoad[0].Data.FadeType,
                endOfExperience: sceneToLoad[0].Data.EndOfExperience ?? false,
              },
            },
          },
        });
      } else {
        this.scene.start(sceneToLoad[0].SceneType, {
          data: {
            video: sceneToLoad[0].Video,
            nextVideo: sceneToLoad[0].NextVideo,
            nextScene: sceneToLoad[0].NextScene,
            json: sceneToLoad[0].Json,
            skipEnable: sceneToLoad[0].SkipEnable ?? false,
            fadeType: sceneToLoad[0].FadeType,
            endOfExperience: sceneToLoad[0].EndOfExperience ?? false,
          },
        });
      }
    } else if (typeof startSceneEnvVar === "string") {
      this.scene.start(startSceneEnvVar);
    } else this.scene.start("HomeSceneNew");
  }
}
