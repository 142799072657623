import Phaser from "phaser";
import { colors } from "../../lib/colors";

const buttonColor = colors.purple40; //purple
const speechBubbleColor = colors.neutral;

class SpeechBubble {
  id!: number;
  scene: Phaser.Scene;
  bubbleType!: "button" | "response";
  speechBubbleClick?: () => void;
  domElement!: Phaser.GameObjects.DOMElement;
  isInteractive!: boolean;
  fontStyle!: any;
  speechObj: any;
  bubblePosition: any;
  button!: Phaser.GameObjects.Graphics;
  buttonOverlay!: Phaser.GameObjects.Graphics;
  bubbleText!: Phaser.GameObjects.Text;
  bubbleContainer!: Phaser.GameObjects.Container;
  currentPosition!: { x: number; y: number };

  constructor(scene: Phaser.Scene, speechBubbleClick?: any) {
    this.scene = scene;
    this.speechBubbleClick = speechBubbleClick;
  }

  generateBubble(scene: Phaser.Scene, speechObj, bubblePosition, template) {
    this.speechObj = speechObj;
    this.bubblePosition = bubblePosition;
    this.id = speechObj["Id"];
    this.isInteractive = speechObj.Interactive === "true";

    if (template === "mindworker") {
      if (speechObj.Interactive !== "true") {
        const speechBubble = document.createElement("div");
        speechBubble.className = "speech-bubble-container speaker mindworker";
        speechBubble.innerHTML = `<div class="speech-bubble speaker"><p>${speechObj.SpeechText}</p></div>`;

        this.domElement = scene.add.dom(
          window.innerWidth - 440 - 24,
          parseInt(this.scene.game.canvas.style.height),
          speechBubble
        );

        this.domElement.setDepth(350);
        this.domElement.setOrigin(0.5, 1);
        this.domElement.alpha = 0;

        // this snippet aligns the mindworker's speech bubble to the left
        const speechBubbleMaxWidth = 480;
        const speechBubbleWidth = (
          speechBubble.querySelector(".speech-bubble") as HTMLElement
        )?.offsetWidth;
        if (speechBubbleWidth < speechBubbleMaxWidth) {
          const spacer = speechBubbleMaxWidth - speechBubbleWidth;
          speechBubble.style.left = `calc(50% - ${spacer}px)`;
        }
      } else {
        const speechBubble = document.createElement("div");
        speechBubble.className =
          "speech-bubble-container interactive mindworker";
        speechBubble.innerHTML = `<div class="speech-bubble interactive"><p>${speechObj.SpeechText}</p></div>`;

        const hasPos = bubblePosition || bubblePosition.x;
        if (hasPos) {
          speechBubble.classList.add("has-x-pos");
          speechBubble.style.setProperty(
            "--button-pos-x",
            `${bubblePosition || bubblePosition?.x}px`
          );
        }

        this.domElement = scene.add.dom(
          bubblePosition.x,
          parseInt(this.scene.game.canvas.style.height) - 64,
          speechBubble
        );

        this.domElement.setDepth(350);
        this.domElement.setOrigin(0.5, 1);
        this.domElement.setAlpha(1);
        this.domElement?.node?.classList?.add("smooth-fade");

        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            this.domElement?.node?.classList?.add("spawned");
          });
        });

        setTimeout(() => {
          this.domElement?.node?.classList?.remove("smooth-fade");
        }, 300);

        this.scene.add.tween({
          targets: this.domElement,
          duration: 300,
          alpha: 1,
          ease: "Power1",
        });

        speechBubble.onclick = () => {
          if (template == "mindworker") {
            // CSS transitions added in index.css
            this.domElement.node.classList.add("selected");

            // transition bubble to left edge
            const newX =
              window.innerWidth -
              this.domElement.width -
              window.__CUIO__.safeAreaBorder;
            this.scene.add.tween({
              targets: this.domElement,
              duration: 300,
              x: newX,
            });
          }

          if (this.speechBubbleClick) {
            this.speechBubbleClick();
          }

          speechBubble.onclick = () => {};
        };
      }
    } else if (template == "scenario") {
      if (speechObj.Interactive != "true") {
        const speechBubble = document.createElement("div");
        speechBubble.className = "speech-bubble-container scenario";
        speechBubble.innerHTML = `<div class="speech-bubble scenario"><p>${speechObj.SpeechText}</p></div>`;

        this.domElement = scene.add.dom(
          window.innerWidth / 2,
          parseInt(this.scene.game.canvas.style.height),
          speechBubble
        );

        this.domElement.setDepth(350);
        this.domElement.setOrigin(0.5, 1);
        this.domElement.alpha = 0;
      } else {
        const speechBubble = document.createElement("div");
        speechBubble.className = "speech-bubble-container interactive";
        speechBubble.innerHTML = `<div class="speech-bubble interactive"><p>${speechObj.SpeechText}</p></div>`;

        const hasPos = bubblePosition || bubblePosition.x;
        if (hasPos) {
          speechBubble.classList.add("has-x-pos");
          speechBubble.style.setProperty(
            "--button-pos-x",
            `${bubblePosition || bubblePosition?.x}px`
          );
        }

        this.domElement = scene.add.dom(
          bubblePosition.x,
          parseInt(this.scene.game.canvas.style.height) - 64,
          speechBubble
        );

        this.domElement.setDepth(350);
        this.domElement.setOrigin(0.5, 1);
        this.domElement.setAlpha(0);

        this.scene.add.tween({
          targets: this.domElement,
          duration: 300,
          alpha: 1,
          ease: "Power1",
        });

        speechBubble.onclick = () => {
          if (this.speechBubbleClick) {
            this.speechBubbleClick();
          }

          speechBubble.onclick = () => {};
        };
      }
    }

    return this;
  }

  getBounds(): { x: number; y: number; width: number; height: number } {
    return {
      x: this.domElement.x,
      y: this.domElement.y,
      width: this.domElement.width,
      height: this.domElement.height,
    };
  }
}

export default SpeechBubble;
