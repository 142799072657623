import EndOfEpisodeScene from "../scenes/EndOfEpisodeScene";
import ControlPanelScene from "../scenes/ControlPanelScene";
import ControlPanelVisualiserScene from "../scenes/ControlPanelVisualiserScene";
import ConsoleEndScene from "../scenes/ConsoleEndScene";
import RileyIntroCinematicScene from "../scenes/RileyIntroCinematic";
import HomeSceneNew from "../scenes/HomeSceneNew";
import EndOfExperienceScene from "~/scenes/EndOfExperienceScene";
import MTPScene from "../scenes/MTPScene";
import HomeScene from "../scenes/HomeScene";
import TextEngineScene from "../scenes/TextEngineScene";
import PreloadScene from "../scenes/common/PreloadScene";
import CinematicScene from "../scenes/CinematicScene";
import TestScene from "../scenes/TestScene";
import AssetLoaderScene from "~/scenes/common/AssetLoaderScene";

export default [
  PreloadScene,
  //HomeScene,
  CinematicScene,
  TextEngineScene,
  EndOfEpisodeScene,
  ControlPanelScene,
  ControlPanelVisualiserScene,
  ConsoleEndScene,
  RileyIntroCinematicScene,
  HomeSceneNew,
  //MTPScene,
  EndOfExperienceScene,
  //TestScene,
  AssetLoaderScene,
];
