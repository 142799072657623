import Phaser from "phaser";
import { Speech } from "../../models/speech";
import SpeechBubble from "./SpeechBubble";
import { sceneFadeInFadeOut } from "../../lib/util";
import BaseScene from "~/scenes/common/BaseScene";

type TemplateType = "mindworker" | "scenario";

// TODO: There is a bug in the text engine where if the mindworker needs to speak multiple lines with audio.
//     : It needs to be 3 speech bubbles or the text engine breaks.
class TextEngine {
  speechJSON: Array<Speech>;
  scene: BaseScene;
  template: TemplateType;
  fontConfig: any;
  styleConfig: any;
  count!: number;
  speechBubbleArrayContainer!: Array<SpeechBubble>;
  conversationContainer!: Array<SpeechBubble>;
  bubblePosition!: { x: number; y: number };
  childCount: number;
  childCountVal!: number;
  childrenPositionArr: any;
  emitter: Phaser.Events.EventEmitter;
  siblingsArr: Array<SpeechBubble>;
  siblingIds: Array<number>;
  audioSequence!: Array<Phaser.Sound.BaseSound>;
  scenarioPositioning!: boolean;
  responseContainer!: Phaser.GameObjects.Container;
  startingTextId?: number;

  constructor(
    scene: BaseScene,
    sceneSpeech: Array<Speech>,
    template: TemplateType,
    startingId?: number
  ) {
    this.speechJSON = sceneSpeech;
    let sceneClone = { ...scene };
    this.scene = scene;
    //mindworker or scenario
    this.template = template;

    if (startingId) {
      this.startingTextId = startingId;
    }

    this.scene.time.addEvent({
      delay: 800,
      callback: () => {
        this.initBubble();
      },
      callbackScope: this,
      loop: false,
    });

    this.childCount = 0;
    this.childrenPositionArr = [];
    this.emitter = new Phaser.Events.EventEmitter();
    this.siblingsArr = [];
    this.siblingIds = [];

    this.scene.resizeFunction = () => {
      this.repositionScenarioBubble();
      sceneClone?.resizeFunction && sceneClone?.resizeFunction();
    };

    this.scene.sceneExitFunction = () => {
      this.scene.resizeFunction = () => {};
      sceneClone?.sceneExitFunction && sceneClone?.sceneExitFunction();
      this.cancelAudio();
    };
  }

  initBubble() {
    this.speechBubbleArrayContainer = new Array();
    this.conversationContainer = new Array();
    this.audioSequence = new Array();
    this.siblingsArr = new Array();
    this.count = 0;

    if (this.startingTextId) {
      this.generateSpeechBubble(this.startingTextId);
    } else {
      this.generateSpeechBubble(0);
    }
  }

  generateSpeechBubble(speechId: number, isChainMessage = false) {
    let parentSpeech;
    if (speechId == 0) {
      parentSpeech = this.speechJSON[speechId];
    } else {
      parentSpeech = this.speechJSON.find((obj) =>
        obj.ParentId.includes(speechId)
      );
    }

    let isInteractive = parentSpeech.Interactive;
    let speechBubble = new SpeechBubble(this.scene);
    let defaultBubblePos = { x: 0, y: 0 };

    let bubble = speechBubble.generateBubble(
      this.scene,
      parentSpeech,
      defaultBubblePos,
      this.template
    );

    if (isInteractive == "false") {
      this.addBubbleToConversation(
        bubble,
        () => {
          this.checkChildrenAndRenderNextSpeech(parentSpeech);
        },
        !isChainMessage,
        isChainMessage
      );
    }

    if (this.template == "scenario") {
      if (parentSpeech.Expression == "change") {
        this.scene.events.emit("changeBackground", parentSpeech.Frame);
      }
    } else {
      if (parentSpeech.Expression == "change") {
        if (parentSpeech.GenerateNext != "true") {
          this.scene.events.emit("changeMindworkerPose", parentSpeech.Frame);
        }
      }
    }

    this.speechBubbleArrayContainer.push(bubble);
    this.count++;
  }

  checkChildrenAndRenderNextSpeech(parentSpeech) {
    this.childCount = this.speechJSON.filter((x) => {
      return x.ParentId.includes(parentSpeech.Id);
    }).length;

    this.childCountVal = this.childCount;
    this.siblingsArr = [];

    this.scenarioPositioning = this.childCount >= 1 && this.childCountVal > 1;
    this.speechJSON
      .filter((x) => {
        return x.ParentId.includes(parentSpeech.Id);
      })
      .forEach((x) => {
        // speech bubble callback on response click
        let speechBubble = new SpeechBubble(this.scene, () => {
          this.cancelAudio();

          if (x.Action === "EndScene") {
            if (this.template == "scenario") {
              this.destroyContainers(() => {
                sceneFadeInFadeOut(
                  this.scene,
                  x.NextScene,
                  {
                    video: x.VideoKey,
                    nextVideo: x.NextVideoKey,
                    nextScene: x.NextCinematicScene,
                    json: x?.Json,
                    fadeType: x.FadeType,
                    skipEnable: x.SkipEnable,
                    endOfExperience: x.EndOfExperience ?? false,
                  },
                  x.FadeType
                );
              });
            } else {
              if (this.hasSiblings(x.ParentId)) {
                this.destroyUnselectedResponse(x.Id);
              }

              this.addBubbleToConversation(speechBubble, () => {
                this.conversationContainer.forEach(
                  (speechBubble: SpeechBubble) => {
                    this.scene.add.tween({
                      targets: speechBubble.domElement,
                      duration: 200,
                      alpha: 0,
                      ease: "Power1",
                    });
                  }
                );

                sceneFadeInFadeOut(
                  this.scene,
                  x.NextScene,
                  {
                    video: x.VideoKey,
                    nextVideo: x.NextVideoKey,
                    nextScene: x.NextCinematicScene,
                    json: x.Json,
                    fadeType: x.FadeType,
                    skipEnable: x.SkipEnable,
                    endOfExperience: x.EndOfExperience ?? false,
                  },
                  x.FadeType
                );
              });
            }
          } else if (x.Action === "NextSpeech") {
            if (this.template == "scenario") {
              this.resumeText(x.Id);
            } else {
              this.addBubbleToConversation(speechBubble, () => {
                this.generateSpeechBubble(x.Id);
              });
            }

            // check if the speech bubble selected has sibling responses
            if (this.template == "mindworker") {
              this.count++;
              if (this.hasSiblings(x.ParentId)) {
                this.destroyUnselectedResponse(x.Id);
              }
            }
          } else {
            this.scene.events.emit(x.Action);
          }
        });

        this.siblingsArr.push(speechBubble);

        // next bubble
        this.nextBubble(speechBubble, x);
      });

    this.repositionScenarioBubble();

    //clear arrays
    this.reset();
  }

  // dismiss the current text selection for activity
  dismissText() {
    this.destroyContainers(() => {
      this.reset();
    });
  }

  // ability to resume from id, for console
  resumeText(id: number) {
    this.destroyContainers(() => {
      this.reset();
      this.generateSpeechBubble(id);
    });
  }

  // generate next speech bubble
  nextBubble(speechBubble, x) {
    let bubble: SpeechBubble = speechBubble.generateBubble(
      this.scene,
      x,
      50,
      this.template
    );
    if (x.Interactive == "false") {
      let hasNext = x.GenerateNext == "true";
      this.addBubbleToConversation(
        bubble,
        () => {
          if (x.GenerateNext == "true") {
            this.generateSpeechBubble(x.Id, true);
          }
        },
        !hasNext,
        hasNext
      );
      if (
        bubble.speechObj.Expression == "change" &&
        bubble.speechObj.GenerateNext
      ) {
      }
    } else {
      this.scene.add.tween({
        targets: bubble.bubbleContainer,
        duration: 400,
        alpha: 1,
      });
    }

    this.speechBubbleArrayContainer.push(bubble);
  }

  addBubbleToConversation = (
    bubble: SpeechBubble,
    onTransitionComplete?: () => void,
    fadeExisting = false,
    addDelay = false
  ) => {
    let heightToAdd = bubble.getBounds().height;
    const containerY =
      this.template == "mindworker"
        ? parseInt(this.scene.game.canvas.style.height) - 240
        : parseInt(this.scene.game.canvas.style.height) - 144;

    let delay = 0;

    const audioKey = bubble.speechObj["AudioKey"];
    const audioMarker = bubble.speechObj["AudioMarkers"];
    const action = bubble.speechObj["Action"];

    // add a delay for message chains
    if (addDelay) {
      if (this.audioSequence.length > 0 && this.audioSequence[0].isPlaying) {
        this.audioSequence[0].on("complete", () => {
          if (!!audioKey && !!audioMarker) {
            this.addAudioFileToQue(audioKey, audioMarker);
          } else if (!!audioKey) {
            this.addAudioFileToQue(audioKey);
          }

          this.scene.events.emit(
            "changeMindworkerPose",
            bubble.speechObj["Frame"]
          );
          this.AddBubble(
            bubble,
            containerY,
            action,
            onTransitionComplete,
            0,
            heightToAdd,
            fadeExisting
          );
        });
      } else {
        delay = 1200;
      }
    } else {
      if (!!audioKey && !!audioMarker) {
        this.addAudioFileToQue(audioKey, audioMarker);
      } else if (!!audioKey) {
        this.addAudioFileToQue(audioKey);
      }

      this.AddBubble(
        bubble,
        containerY,
        action,
        onTransitionComplete,
        delay,
        heightToAdd,
        fadeExisting
      );
    }
  };

  repositionScenarioBubble = () => {
    if (this.template == "scenario") {
      const gap = 8;
      const rootEl = document.querySelector(":root");
      if (!rootEl) return;
      const computedStyles = getComputedStyle(rootEl);
      const safeAreaGutterStyle =
        computedStyles.getPropertyValue("--safe-area-border");
      const canvasWidthStyle =
        computedStyles.getPropertyValue("--canvas-width");
      const safeAreaGutter = parseInt(safeAreaGutterStyle);
      const canvasWidth = parseInt(canvasWidthStyle);
      const startPos = canvasWidth / 2 - safeAreaGutter;
      const totalWidth =
        this.siblingsArr.reduce((acc, speechBubble) => {
          return acc + speechBubble.domElement.width + gap;
        }, 0) - gap;

      let widthCounter = 0;
      this.siblingsArr.forEach((speechBubble: SpeechBubble) => {
        const { domElement } = speechBubble;
        const { width } = domElement;
        const xPosition = startPos - totalWidth / 2 + widthCounter;
        domElement.x = xPosition;
        const element = domElement.node as HTMLElement;
        element &&
          element.style.setProperty("--button-pos-x", xPosition + "px");
        widthCounter += width + gap;

        // alternative fix for :has() in firefox
        let prevBubble = element?.previousElementSibling as HTMLElement;
        if (!prevBubble?.classList.contains("interactive")) {
          prevBubble?.style.setProperty("bottom", "64px");
        }
      });
    } else {
      const gap = 12;
      let xPosition = window.innerWidth - window.__CUIO__.safeAreaBorder;
      let rightCounter = 0;

      this.siblingsArr.reverse().forEach((speechBubble: SpeechBubble) => {
        if (speechBubble.isInteractive) {
          const { domElement } = speechBubble;
          const { width } = domElement;
          xPosition -= width;
          domElement.x = xPosition;
          const element = domElement.node as HTMLElement;
          element &&
            element.style.setProperty("--speech-right", rightCounter + "px");

          xPosition -= gap;
          rightCounter += width + gap;
        }
      });
    }
  };

  private AddBubble(
    bubble: SpeechBubble,
    containerY: number,
    action: any,
    onTransitionComplete: (() => void) | undefined,
    delay: number,
    heightToAdd: number,
    fadeExisting: boolean
  ) {
    this.scene.add.tween({
      targets: bubble.domElement,
      duration: 400,
      y: containerY,
      alpha: 1,
      loop: false,
      ease: "Power1",
      onComplete: () => {
        if (!!action && action != "Response") {
          this.scene.events.emit(action);
        }
        if (onTransitionComplete) {
          onTransitionComplete();
        }
      },
      delay: delay,
      callbackScope: this,
    });

    this.conversationContainer.forEach((speechBubble: SpeechBubble) => {
      const newY = speechBubble.getBounds().y - (heightToAdd + 12);
      const element = speechBubble.domElement.node as HTMLElement;
      const lastCssYStyle = element
        ? getComputedStyle(element).getPropertyValue("--speech-pos-y")
        : "0px";
      const lastCssY = parseInt(lastCssYStyle) || 0;
      const cssY = lastCssY + heightToAdd + 30; //original value is +12

      element && element.style.setProperty("--speech-pos-y", `${cssY}px`);
      element && element.classList.add("has-y-pos");

      this.scene.add.tween({
        targets: speechBubble.domElement,
        duration: 400,
        y: newY,
        loop: false,
        ease: "Power1",
        onComplete: () => {},
        delay: delay,
        callbackScope: this,
      });

      if (fadeExisting)
        this.scene.add.tween({
          targets: speechBubble.domElement,
          duration: 400,
          alpha: 0.5,
          loop: false,
          ease: "Power1",
          onComplete: () => {},
          delay: delay,
          callbackScope: this,
        });
    });

    this.conversationContainer.push(bubble);
  }

  addAudioFileToQue(audioKey: string, markers?: any) {
    const audioVl = this.scene.sound.add(audioKey, { volume: 2.5 });

    if (markers) {
      for (let i = 0; i <= markers.length; i++) {
        audioVl.addMarker(markers[i]);
      }
    }

    audioVl.stop();
    // check if we have anything currently playing, if not play
    if (this.audioSequence.length == 0) {
      if (markers) {
        audioVl.play(markers[0]["name"]);
      } else {
        audioVl.play();
      }

      audioVl.on(
        Phaser.Sound.Events.COMPLETE,
        () => {
          this.playNextAudioFile();
        },
        this
      );
    }

    this.audioSequence.push(audioVl);
  }

  playNextAudioFile() {
    this.audioSequence = this.audioSequence.slice(1);
    if (this.audioSequence.length > 0) {
      const nextAudio = this.audioSequence[0];
      nextAudio.play();
      nextAudio.on(
        Phaser.Sound.Events.COMPLETE,
        () => {
          this.playNextAudioFile();
        },
        this
      );
    }
  }

  cancelAudio() {
    this.audioSequence.forEach((audio) => {
      audio.stop();
      audio.destroy();
    });

    this.audioSequence = [];
  }

  // check if the selected option has siblings
  hasSiblings = (parentId) => {
    let ids = this.speechJSON
      .filter((x) => x.ParentId.includes(parentId[0]))
      .map((x) => x.Id);

    ids.forEach((a) => {
      if (!(this.siblingIds.indexOf(a) > -1)) {
        this.siblingIds.push(a);
      }
    });

    return this.siblingIds.length > 1 ? true : false;
  };

  destroyContainers = (onCompleteFunction: () => void) => {
    if (this.speechBubbleArrayContainer.length > 0) {
      this.speechBubbleArrayContainer.forEach((bubble) => {
        if (bubble.isInteractive) {
          this.scene.add.tween({
            targets: bubble.domElement,
            duration: 800,
            alpha: 0,
            ease: "Power1",
            onCompleteFunction: () => {
              bubble.domElement.destroy();
            },
            callbackScope: this,
          });
        } else {
          const newY = bubble.domElement.y - 80;

          this.scene.add.tween({
            targets: bubble.domElement,
            duration: 400,
            y: newY,
            alpha: 0,
            onComplete: () => {
              this.speechBubbleArrayContainer = [];
              this.conversationContainer = [];
              onCompleteFunction();
            },
          });
        }
      });
    } else {
      onCompleteFunction();
    }
  };

  // destroy the unselected responses
  destroyUnselectedResponse = (selectedId) => {
    this.siblingsArr
      .filter((x) => x.id != selectedId)
      .forEach((bubble) => {
        bubble.domElement.destroy();
      });
  };

  reset() {
    this.childCount = 0;
    this.childrenPositionArr = [];
    this.siblingIds = [];
  }
}

export default TextEngine;
