import Phaser, { DOM } from "phaser";
import BaseScene from "./common/BaseScene";
import { createCopyright, resizeImage, sceneFadeInFadeOut } from "../lib/util";
import { EpisodeConfig, TEpisodeConfig } from "~/config/episodes";
import { AvailableEpisodes } from "~/config/enums";

type TEndOfScene = {
  currentEpisode: string;
  nextEpisode?: string;
};

export default class EndOfEpisodeScene extends BaseScene {
  public nextEpisode!: TEpisodeConfig | null;
  public sceneData!: TEndOfScene | null;
  private domElement!: Phaser.GameObjects.DOMElement;

  constructor() {
    super("EndOfEpisodeScene");
  }

  preload() {
    super.preload();
    if (this?.sceneData?.nextEpisode) {
      this.nextEpisode = EpisodeConfig.getEpisode(
        AvailableEpisodes[this.sceneData.nextEpisode.toUpperCase()]
      );
      this.load.image(
        `EndOfEpisodeSceneBg-${this.sceneData.nextEpisode}`,
        this.nextEpisode?.endSceneImage
      );
    }
  }

  init(data: any) {
    this.sceneData = data.data.json;
  }

  create() {
    super.create();

    this.time.addEvent({
      delay: 800,
      loop: false,
      callbackScope: this,
      callback: () => {
        if (this?.sceneData?.nextEpisode) {
          this.background = this.add.image(
            this.cameras.main.centerX,
            this.cameras.main.centerY,
            `EndOfEpisodeSceneBg-${this.sceneData.nextEpisode}`
          );
          this.background.setScale(window.innerHeight / this.backgroundHeight);

          this.scale.on("resize", (gameSize) => {
            this.cameras.resize(gameSize.width, gameSize.height);
            resizeImage(
              this,
              this.background,
              this.backgroundWidth,
              this.backgroundHeight
            );
          });

          window.addEventListener("resize", () =>
            resizeImage(
              this,
              this.background,
              this.backgroundWidth,
              this.backgroundHeight
            )
          );

          resizeImage(
            this,
            this.background,
            this.backgroundWidth,
            this.backgroundHeight
          );

          createCopyright(this);

          this.createContent();
        }
      },
    });
  }

  createContent() {
    const container = document.createElement("div");
    container.classList.add("end-content", "end-of-episode");

    // Title
    const nextEpText = document.createElement("h1");
    nextEpText.innerHTML = this.nextEpisode?.title ?? "";

    // Next episode name
    const nextEpName = document.createElement("h2");
    nextEpName.innerHTML = this.nextEpisode?.desc ?? "";

    // Buttons
    const buttonsContainer = document.createElement("div");
    buttonsContainer.classList.add("end-content__button-container");

    const startNextEpisodeButton = document.createElement("a");
    startNextEpisodeButton.className = "play-btn";
    startNextEpisodeButton.onclick = () =>
      this.nextEpisode?.start && this.nextEpisode?.start(this);
    startNextEpisodeButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M27.1877 15.1788L11.9156 5.58957C11.6584 5.42795 11.3639 5.33967 11.0624 5.33381C10.7609 5.32795 10.4634 5.40471 10.2004 5.55621C9.93736 5.70771 9.71839 5.92848 9.56599 6.19578C9.41358 6.46309 9.33325 6.76729 9.33325 7.07708V26.2572C9.3335 26.5669 9.41399 26.8709 9.56645 27.1381C9.71891 27.4053 9.93785 27.6259 10.2008 27.7773C10.4637 27.9288 10.7611 28.0056 11.0625 27.9999C11.3639 27.9941 11.6583 27.9061 11.9156 27.7447L27.1877 18.1555C27.4358 17.9996 27.6408 17.7809 27.783 17.5203C27.9252 17.2597 27.9999 16.9659 27.9999 16.6671C27.9999 16.3684 27.9252 16.0746 27.783 15.814C27.6408 15.5534 27.4358 15.3347 27.1877 15.1788Z" fill="#AF97FF"/>
      </svg>
      <span>Start Episode</span>
    `;

    const takeABreakButton = document.createElement("a");
    takeABreakButton.className = "take-a-break";
    takeABreakButton.onclick = () => sceneFadeInFadeOut(this, "HomeSceneNew");
    takeABreakButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M10 24C9.44772 24 9 23.5523 9 23V13.8286C9 13.5165 9.14571 13.2223 9.39396 13.0332L15.394 8.46175C15.752 8.18899 16.248 8.18899 16.606 8.46175L22.606 13.0332C22.8543 13.2223 23 13.5165 23 13.8286V23C23 23.5523 22.5523 24 22 24H18.875C18.3227 24 17.875 23.5523 17.875 23V19.6667C17.875 19.1144 17.4273 18.6667 16.875 18.6667H15.125C14.5727 18.6667 14.125 19.1144 14.125 19.6667V23C14.125 23.5523 13.6773 24 13.125 24H10Z" fill="#AF97FF"/>
      </svg>
      <span>Take a break</span>
    `;

    buttonsContainer.appendChild(startNextEpisodeButton);
    buttonsContainer.appendChild(takeABreakButton);

    container.appendChild(nextEpText);
    container.appendChild(nextEpName);
    container.appendChild(buttonsContainer);

    this.domElement = this.add.dom(0, 0, container);
    this.domElement.setDepth(2);
    this.domElement.setOrigin(0, 0.5);
  }
}
