import { sceneFadeInFadeOut } from "~/lib/util";
import { AvailableEpisodes } from "~/config/enums";

export type TEpisodeConfig = {
  title: string;
  desc: string;
  descColor: "default" | "red" | "blue" | "yellow" | "fear" | "green";
  image: string;
  active: boolean;
  endSceneImage?: string;
  start?: (scene: Phaser.Scene) => void;
};

type TEpisodeConfigList = {
  [key: string]: TEpisodeConfig;
};

export class EpisodeConfig {
  private constructor() {}

  private static episodes: TEpisodeConfigList = {
    ep1: {
      title: "Episode 1",
      desc: "Meet the team",
      descColor: "default",
      image: "assets/card-1.png",
      active: true,
      start: (scene) => {
        sceneFadeInFadeOut(scene, "AssetLoaderScene", {
          chapter: "ep1-ch1",
          sceneToLoad: {
            sceneName: "CinematicScene",
            data: {
              video: "riley-intro",
              nextScene: "TextEngineScene",
              json: "mindworkerIntro",
              skipEnable: true,
              fadeType: "HorizontalWipe",
            },
            fadeType: "HorizontalWipe",
          },
        });
      },
    },
    ep2: {
      title: "Episode 2",
      desc: "Anger in action",
      descColor: "red",
      image: "assets/card-2.jpg",
      endSceneImage: "/assets/end-of-episode-2-min.jpg",
      active: true,
      start: (scene) => {
        sceneFadeInFadeOut(scene, "AssetLoaderScene", {
          chapter: "ep2-ch1",
          sceneToLoad: {
            sceneName: "CinematicScene",
            data: {
              video: "riley-hockey",
              nextScene: "TextEngineScene",
              json: "ep2sc1",
              skipEnable: true,
            },
            fadeType: "HorizontalWipe",
          },
        });
      },
    },
    ep3: {
      title: "Episode 3",
      desc: "Emotions and the body",
      descColor: "blue",
      image: "assets/card-4.jpg",
      endSceneImage: "/assets/end-of-episode-3-min.jpg",
      active: true,
      start: (scene) => {
        sceneFadeInFadeOut(scene, "AssetLoaderScene", {
          chapter: "ep3-ch1",
          sceneToLoad: {
            sceneName: "CinematicScene",
            data: {
              video: "3_1",
              nextScene: "TextEngineScene",
              json: "ep3Mindworker1",
              skipEnable: true,
              fadeType: "HorizontalWipe",
            },
            fadeType: "HorizontalWipe",
          },
        });
      },
    },
    ep4: {
      title: "Episode 4",
      desc: "A powerful pause",
      descColor: "yellow",
      image: "assets/card-7.jpg",
      endSceneImage: "/assets/end-of-episode-4-min.jpg",
      active: true,
      start: (scene) => {
        sceneFadeInFadeOut(scene, "AssetLoaderScene", {
          chapter: "ep4-ch1",
          sceneToLoad: {
            sceneName: "CinematicScene",
            data: {
              video: "4_1",
              nextScene: "TextEngineScene",
              json: "ep4Mindworker1",
              skipEnable: true,
              fadeType: "HorizontalWipe",
            },
            fadeType: "HorizontalWipe",
          },
        });
      },
    },
  };

  public static getEpisode(episode: AvailableEpisodes): TEpisodeConfig | null {
    return this.episodes[episode];
  }

  public static getNextEpisode(
    currentEpisode: AvailableEpisodes
  ): TEpisodeConfig | null {
    const keys = Object.keys(this.episodes);
    const keyIndex = keys.findIndex((value) => {
      return value === currentEpisode;
    });
    const nextKeyIndex = keyIndex + 1;
    const isNextKeyIndexValid = nextKeyIndex > 0 && nextKeyIndex < keys.length;

    if (!isNextKeyIndexValid) return null;

    const nextKey = keys[nextKeyIndex];
    return this.getEpisode(AvailableEpisodes[nextKey]);
  }
}
