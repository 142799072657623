import Phaser from "phaser";
import sceneFlow from "../../json/sceneFlow.json";
import videoAssets from "../../json/asset-manifests/videos.json";
import audioAssets from "../../json/asset-manifests/audio.json";
import spriteAssets from "../../json/asset-manifests/sprites.json";
import imageAssets from "../../json/asset-manifests/images.json";
import frameAssets from "../../json/asset-manifests/frames.json";
import jsonData from "../../json/speech.json";
import jsonScenario from "../../json/scenarioSpeech.json";
import jsonConsole from "../../json/consoleSpeech.json";
import jsonVisualiser from "../../json/visualiserSpeech.json";
import {
  sceneFadeInFadeOut,
  loadAssets,
  loadSprite,
  loadImageOrFrame,
} from "~/lib/util";
import { displayDeviceWarnings } from "~/lib/device";
import { AppStates, SessionNames } from "~/config/enums";
import { TScene } from "~/models/scene";
import { TNameValue } from "~/models/shared-models";
import {
  TAssetGeneric,
  TAudioAsset,
  TFrameAssets,
  TSharedAsset,
  TSpriteAsset,
} from "~/models/asset-models";

type TAssetLoaderSceneData = {
  data: {
    chapter: string;
    sceneToLoad: TScene<any>;
  };
};

export default class AssetLoaderScene extends Phaser.Scene {
  private logRefName = "Asset Loader";
  private chapter!: string;
  private sceneToLoad!: TScene<any>;

  constructor() {
    super("AssetLoaderScene");
  }

  preload() {
    if (this.chapter) {
      this.load.path = "assets/";
      this.load.maxParallelDownloads = 3;

      this.createLoadingProgress();

      const [ep, ch] = this.chapter.split("-");

      this.loadSprites(ep, ch);
      this.loadImages(ep, ch);
      this.loadFrames(ep, ch);
      this.loadVideos(ep, ch);
      this.loadAudio(ep, ch);
      this.loadOtherAssets();
    }
  }

  init(sceneData: TAssetLoaderSceneData) {
    this.chapter =
      sceneData.data.chapter ??
      new URL(window.location.href).searchParams.get("ch");
    this.sceneToLoad = sceneData.data.sceneToLoad;
  }

  create() {
    if (sessionStorage.getItem(SessionNames.APP_STATE) === null) {
      sessionStorage.setItem(SessionNames.APP_STATE, AppStates.INITIALIZED);
    }

    const hasInvalidDeviceOrientation = displayDeviceWarnings(
      this.game,
      !!this.chapter
    );

    if (hasInvalidDeviceOrientation) {
      sessionStorage.setItem(
        SessionNames.EVENT_QUEUE,
        JSON.stringify({
          eventData: {
            sceneName: this.sceneToLoad.sceneName,
            data: this.sceneToLoad.data,
            fadeType: this.sceneToLoad.fadeType,
            skipEnable: this.sceneToLoad.skipEnable,
          },
        })
      );

      return;
    }

    if (!this.sceneToLoad) {
      console.error(`${this.logRefName} Error: No scene to load`);
      return;
    }

    sceneFadeInFadeOut(
      this.sceneToLoad.scene ?? this,
      this.sceneToLoad.sceneName,
      this.sceneToLoad.data,
      this.sceneToLoad.fadeType
    );
  }

  private loadSprites(ep: string, ch: string) {
    loadSprite(this, "mindworker-sprite", "animation.json");

    // get shared assets
    const sharedSpriteAssets = spriteAssets[
      "shared"
    ] as TSharedAsset<TSpriteAsset>[];

    const episodeSharedSpriteAssets = sharedSpriteAssets.filter(
      (sharedAsset) => {
        return sharedAsset.episodes.includes(ep);
      }
    );

    if (episodeSharedSpriteAssets.length)
      episodeSharedSpriteAssets.forEach((sharedAsset) => {
        sharedAsset.assets.forEach((asset) => {
          loadSprite(this, asset.spriteKey, asset.spriteMap);
        });
      });

    // get episode specific assets
    const episodeSpriteAssets = spriteAssets[ep] as TSpriteAsset[];

    if (!episodeSpriteAssets) return;

    episodeSpriteAssets.forEach((spriteAsset: TSpriteAsset) => {
      loadSprite(this, spriteAsset.spriteKey, spriteAsset.spriteMap);
    });
  }

  private loadImages(ep: string, ch: string) {
    // get shared assets
    const sharedImgAssets = imageAssets[
      "shared"
    ] as TSharedAsset<TAssetGeneric>[];
    const episodeSharedImgAssets = sharedImgAssets.filter((sharedAsset) => {
      return sharedAsset.episodes.includes(ep);
    });

    if (episodeSharedImgAssets.length)
      episodeSharedImgAssets.forEach((sharedAsset) => {
        sharedAsset.assets.forEach((asset) => {
          loadImageOrFrame(this, asset.name, asset.path);
        });
      });

    // get episode specific assets
    const episodeImgAssets = imageAssets[ep] as TAssetGeneric[];

    if (!episodeImgAssets) return;

    episodeImgAssets.forEach((imgAsset) => {
      loadImageOrFrame(this, imgAsset.name, imgAsset.path);
    });
  }

  private loadFrames(ep: string, ch: string) {
    const frameEpisodeAssets = frameAssets[ep] as TFrameAssets[];

    if (!frameEpisodeAssets) return;

    frameEpisodeAssets.forEach((asset) => {
      loadImageOrFrame(this, asset.Frame, asset.FramePath);
    });
  }

  private loadVideos(ep: string, ch: string) {
    const videoEpisodeAssets = videoAssets[ep] as TNameValue<TAssetGeneric[]>;

    if (!videoEpisodeAssets[ch]) return;

    for (const chapter in videoEpisodeAssets) {
      const currentVideoChapter = videoEpisodeAssets[chapter];

      currentVideoChapter.forEach((video: TAssetGeneric) => {
        this.load.video(video.name, video.path, "canplay", true, false);
      });
    }
  }

  private loadAudio(ep: string, ch: string) {
    const audioEpisodeAssets = audioAssets[ep] as TAudioAsset[];

    if (!audioEpisodeAssets) return;

    audioEpisodeAssets.forEach((audioAsset: TAudioAsset) => {
      if (!this.cache.audio.exists(audioAsset.AudioKey)) {
        this.load.audio(audioAsset.AudioKey, audioAsset.AudioPath);
      }
    });
  }

  private createLoadingProgress() {
    let loadingTimer, progressDiv, progress;

    const resize = () => {
      if (progressDiv) progressDiv.style.height = this.game.canvas.style.height;
    };

    this.scale.on("resize", (gameSize: any) => {
      this.cameras.resize(gameSize.width, gameSize.height);
      resize();
    });

    resize();

    window.addEventListener("resize", () => resize());

    // wait 400ms before rendering the progress bar to prevent it from flashing on to the screen
    // when the assets are already cached.
    this.load.on("start", () => {
      loadingTimer = setTimeout(() => {
        const body = document.querySelector("body");
        body?.classList.add("loading");

        const progressBackground = document.createElement("div");
        progressBackground.className = "progress-background";

        progress = document.createElement("div");
        progress.className = "progress";

        const progressText = document.createElement("h2");
        progressText.innerText = "Loading";
        progressText.className = "progress-text";

        progressDiv = document.createElement("div");
        progressDiv.className = "progress-holder";
        progressDiv.style.height = this.game.canvas.style.height;
        progressDiv.appendChild(progressBackground);
        progressDiv.appendChild(progress);
        progressDiv.appendChild(progressText);

        const progressContainer = document.createElement("div");
        progressContainer.className = "progress-holder-container";
        progressContainer.appendChild(progressDiv);

        const progressElement = this.add.dom(0, 0, progressContainer);
        progressElement.setDepth(150);
        progressElement.setOrigin(0);
      }, 400);
    });

    this.load.on("progress", (value: number) => {
      if (value > 0.1) {
        progress?.setAttribute("style", `width: ${320 * value}px`);
      }
    });

    this.load.on("complete", () => {
      clearTimeout(loadingTimer);

      //removes the progress container on complete
      const progress = document.querySelector(".progress-holder-container");
      progress?.remove();
    });
  }
  private loadOtherAssets() {
    let sf = sceneFlow[this.chapter];
    let json = sf[0]?.Data.Json ?? sf[0]?.Json;

    if (json) {
      loadAssets(this, json, [
        jsonData,
        jsonScenario,
        jsonConsole,
        jsonVisualiser,
      ]);
    }
  }
}
