import Phaser from "phaser";
import jsonConsole from "../json/consoleSpeech.json";
import { colors } from "~/lib/colors";
import UIBlock from "~/lib/UIBlock";
import BaseScene from "./common/BaseScene";
import TextEngine from "../components/TextEngine/TextEngine";
import { sceneFadeInFadeOut, loadAssets } from "~/lib/util";
import sceneFlow from "../json/sceneFlow.json";
import consoleData from "../json/console-data.json";
import { TAssetGeneric, TAudioAsset } from "~/models/asset-models";
import { SessionNames } from "~/config/enums";

const MinDragPosition = 430;
const MaxDragPosition = 640;
let updateHandleYvalue = 430;
let zoomBulbToggle = false;

const TextConfig = {
  fontFamily: "PoppinsBold",
  fontSize: "24px",
  color: Phaser.Display.Color.ValueToColor(colors.purple60).rgba,
  boundsAlignH: "center",
  boundsAlignV: "middle",
  wordWrap: { width: 180, useAdvancedWrap: true },
};

const ResponseTextConfig = {
  fontFamily: "Poppins",
  fontSize: "24px",
  color: Phaser.Display.Color.ValueToColor(colors.purple60).rgba,
  boundsAlignH: "center",
  boundsAlignV: "middle",
  wordWrap: { width: 180, useAdvancedWrap: true },
};

const SubheadingTextConfig = {
  fontFamily: "PoppinsBlack",
  fontSize: "48px",
  color: Phaser.Display.Color.ValueToColor(colors.purple60).rgba,
  boundsAlignH: "center",
  boundsAlignV: "middle",
  wordWrap: { width: 180, useAdvancedWrap: true },
};

const HeadingTextConfig = {
  fontFamily: "PoppinsBlack",
  fontSize: "106px",
  color: Phaser.Display.Color.ValueToColor(colors.purple60).rgba,
  boundsAlignH: "center",
  boundsAlignV: "middle",
  wordWrap: { width: 1200, useAdvancedWrap: true },
};

interface Postion {
  x: number;
  y: number;
}

interface LightBulb {
  LightBulb: Phaser.GameObjects.Image;
  LightBulbActive: Phaser.GameObjects.Image;
  Text: Phaser.GameObjects.Text;
  ResponseContainer: Phaser.GameObjects.Container;
  StartingPosition: Postion;
  StartingScale: number;
  StartingDepth: number;
  BulbContainer: Phaser.GameObjects.Container;
  SketchImageKey: string;
  AudioKey: string;
}

type TConsoleData = {
  choices: string;
  headings: string;
  subHeadings?: string[];
  helpfulResponses: string[];
  unhelpfulResponses: string[];
  assets: {
    images: string[];
    audio: string[];
  };
};

const Lightbulb1Position: Postion = { x: 520, y: 240 };
const Lightbulb2Position: Postion = { x: 290, y: 215 };
const Lightbulb3Position: Postion = { x: 0, y: 260 };
const Lightbulb4Position: Postion = { x: -230, y: 225 };
const Lightbulb5Position: Postion = { x: -490, y: 180 };

export default class ControlPanelScene extends BaseScene {
  consoleHandle!: Phaser.GameObjects.Image;
  consoleHandlePulse!: Phaser.GameObjects.Image;
  consoleHandleInactive!: Phaser.GameObjects.Image;
  consoleGroove!: Phaser.GameObjects.Image;
  consoleGrooveInactive!: Phaser.GameObjects.Image;
  consoleGoButton!: Phaser.GameObjects.Image;
  consoleGoButtonPulse!: Phaser.GameObjects.Image;
  consoleGoButtonInactive!: Phaser.GameObjects.Image;
  consoleLightBulbs!: Array<LightBulb>;
  optionsContainer!: Phaser.GameObjects.Container;
  selectedLightBulb!: LightBulb | undefined;
  lightsTimer!: Phaser.Time.TimerEvent;
  sceneContainer!: Phaser.GameObjects.Container;
  textEngine!: TextEngine;
  currentStep!: number;
  selectedChoices!: Array<LightBulb>;
  CloseButton!: Phaser.GameObjects.DOMElement;
  audioSequence!: Array<Phaser.Sound.BaseSound>;
  finishButton!: Phaser.GameObjects.DOMElement;
  private episode!: string;
  private consoleData!: TConsoleData;
  private consoleImage!: Phaser.GameObjects.Image;
  private lightsB!: Phaser.GameObjects.Image;
  private lightsC!: Phaser.GameObjects.Image;
  private lightsD!: Phaser.GameObjects.Image;
  private bulb1!: LightBulb;
  private bulb2!: LightBulb;
  private bulb3!: LightBulb;
  private bulb4!: LightBulb;
  private bulb5!: LightBulb;

  constructor(scene) {
    super("ControlPanelScene", "/assets/console-station.jpg");
  }

  preload() {
    super.preload();

    this.load.path = "assets/";

    // this.load.multiatlas("closeBtn", "close-btn.json");

    this.load.json("consoleJSON", jsonConsole);

    loadAssets(this, `${this.episode}console`, [jsonConsole]);
    loadAssets(this, `${this.episode}consoleEnd`, [jsonConsole]);

    //update url at the start of a cinematic scene
    let deepLink = sceneFlow["deep-links"][0][this.episode];
    if (deepLink) {
      const url = new URL(window.location.href);
      url.searchParams.set("ch", deepLink);
      localStorage.setItem("currentParam", deepLink);
      window.history.pushState(null, "", url.toString());
    }
  }

  init(data) {
    this.episode = data.data?.json ?? data.data ?? "ep2";
    this.consoleData = consoleData[this.episode];
    this.selectedLightBulb = undefined;
    updateHandleYvalue = 430; // reinitialise the value when restarting the scene
    zoomBulbToggle = false; // reinitialise the value when restarting the scene
  }

  create() {
    super.create();

    this.consoleLightBulbs = new Array<LightBulb>();
    this.selectedChoices = new Array<LightBulb>();
    this.currentStep = 1;
    this.sceneContainer = new Phaser.GameObjects.Container(this);
    this.audioSequence = new Array();

    this.consoleImage = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX,
      310,
      "console"
    )
      .setOrigin(0.5, 0)
      .setScale(this.GetScale(1));
    this.lightsB = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX - 187,
      740,
      "lights-b",
      "2"
    )
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.lightsC = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 10,
      682,
      "lights-c",
      "2"
    )
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.lightsD = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 355.5,
      604,
      "lights-d",
      "2"
    )
      .setOrigin(0.5)
      .setScale(this.GetScale(0.75));

    this.consoleGoButtonPulse = this.add
      .image(this.cameras.main.centerX + 10, 570, "button-pulse")
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);

    this.consoleGoButtonInactive = this.add
      .image(this.cameras.main.centerX + 10, 570, "go-button", "inactive")
      .setScale(this.GetScale(1))
      .setOrigin(0.5);
    this.consoleGoButton = this.add
      .image(this.cameras.main.centerX + 10, 570, "go-button", "active")
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);

    this.consoleGrooveInactive = this.add
      .sprite(this.cameras.main.centerX - 135, 570, "groove", "inactive")
      .setScale(this.GetScale(1));
    this.consoleGroove = this.add
      .sprite(this.cameras.main.centerX - 135, 570, "groove", "active")
      .setScale(this.GetScale(1))
      .setAlpha(0);

    this.consoleHandlePulse = this.add
      .sprite(this.cameras.main.centerX - 170, 430, "handle-pulse")
      .setScale(this.GetScale(1.04))
      .setAlpha(0);

    this.consoleHandleInactive = this.add
      .sprite(this.cameras.main.centerX - 170, 430, "handle", "inactive")
      .setScale(this.GetScale(1));

    this.consoleHandle = this.add
      .sprite(this.cameras.main.centerX - 170, 430, "handle", "active")
      .setScale(this.GetScale(1))
      .setAlpha(0);
    this.consoleHandle.name = "handle_activity1";

    super.resizeFunction = () => this.Resize();

    // animate lights
    this.lightsTimer = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.AlternateLights(this.lightsB, 5);
        this.AlternateLights(this.lightsC, 4);
        this.AlternateLights(this.lightsD, 3);
      },
      loop: true,
      callbackScope: this,
    });

    // handle button functionality
    this.consoleGoButton?.on(Phaser.Input.Events.POINTER_OVER, () => {
      this.consoleGoButton?.setFrame("hover");
    });
    this.consoleGoButton?.on(Phaser.Input.Events.POINTER_OUT, () => {
      this.consoleGoButton?.setFrame("active");
    });
    this.consoleGoButton?.on(Phaser.Input.Events.POINTER_DOWN, () => {
      this.consoleGoButton?.setFrame("pressed");
    });
    this.consoleGoButton?.on(Phaser.Input.Events.POINTER_UP, () => {
      this.textEngine.dismissText();
      this.textEngine.cancelAudio();
      if (this.consoleGoButton) {
        this.consoleGoButton?.setFrame("hover");
        this.consoleGoButton.input.enabled = false;
        this.consoleGoButtonPulse.setAlpha(0);
      }
      this.consoleHandle.input.enabled = false;

      this.SelectLightBulb(() => {});
    });

    this.bulb1 = {
      LightBulb: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb1Position.x,
          Lightbulb1Position.y,
          "lightbulb-off"
        )
        .setOrigin(0.5)
        .setScale(0.45),
      LightBulbActive: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb1Position.x,
          Lightbulb1Position.y,
          "lightbulb"
        )
        .setOrigin(0.5)
        .setScale(0.45)
        .setAlpha(0),
      Text: new Phaser.GameObjects.Text(
        this,
        this.cameras.main.centerX - Lightbulb1Position.x,
        Lightbulb1Position.y,
        this.consoleData.choices[0],
        TextConfig
      )
        .setOrigin(0.5)
        .setAlign("center"),
      StartingPosition: Lightbulb1Position,
      StartingScale: 0.45,
      BulbContainer: new Phaser.GameObjects.Container(this),
      ResponseContainer: new Phaser.GameObjects.Container(this),
      StartingDepth: 1,
      SketchImageKey: this.consoleData.assets.images[0],
      AudioKey: this.consoleData.assets.audio[0],
    };

    this.bulb2 = {
      LightBulb: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb2Position.x,
          Lightbulb2Position.y,
          "lightbulb-off"
        )
        .setOrigin(0.5)
        .setScale(0.5),
      LightBulbActive: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb2Position.x,
          Lightbulb2Position.y,
          "lightbulb"
        )
        .setOrigin(0.5)
        .setScale(0.5)
        .setAlpha(0),
      Text: new Phaser.GameObjects.Text(
        this,
        this.cameras.main.centerX - Lightbulb2Position.x,
        Lightbulb2Position.y,
        this.consoleData.choices[1],
        TextConfig
      )
        .setOrigin(0.5)
        .setAlign("center"),
      StartingPosition: Lightbulb2Position,
      StartingScale: 0.5,
      BulbContainer: new Phaser.GameObjects.Container(this),
      ResponseContainer: new Phaser.GameObjects.Container(this),
      StartingDepth: 2,
      SketchImageKey: this.consoleData.assets.images[1],
      AudioKey: this.consoleData.assets.audio[1],
    };

    this.bulb3 = {
      LightBulb: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb3Position.x,
          Lightbulb3Position.y,
          "lightbulb-off"
        )
        .setOrigin(0.5)
        .setScale(0.5),
      LightBulbActive: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb3Position.x,
          Lightbulb3Position.y,
          "lightbulb"
        )
        .setOrigin(0.5)
        .setScale(0.5)
        .setAlpha(0),
      Text: new Phaser.GameObjects.Text(
        this,
        this.cameras.main.centerX - Lightbulb3Position.x,
        Lightbulb3Position.y,
        this.consoleData.choices[2],
        TextConfig
      )
        .setOrigin(0.5)
        .setAlign("center"),
      StartingPosition: Lightbulb3Position,
      StartingScale: 0.5,
      BulbContainer: new Phaser.GameObjects.Container(this),
      ResponseContainer: new Phaser.GameObjects.Container(this),
      StartingDepth: 3,
      SketchImageKey: this.consoleData.assets.images[2],
      AudioKey: this.consoleData.assets.audio[2],
    };

    this.bulb4 = {
      LightBulb: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb4Position.x,
          Lightbulb4Position.y,
          "lightbulb-off"
        )
        .setOrigin(0.5)
        .setScale(0.5),
      LightBulbActive: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb4Position.x,
          Lightbulb4Position.y,
          "lightbulb"
        )
        .setOrigin(0.5)
        .setScale(0.5)
        .setAlpha(0),
      Text: new Phaser.GameObjects.Text(
        this,
        this.cameras.main.centerX - Lightbulb4Position.x,
        Lightbulb4Position.y,
        this.consoleData.choices[3],
        TextConfig
      )
        .setOrigin(0.5)
        .setAlign("center"),
      StartingPosition: Lightbulb4Position,
      StartingScale: 0.5,
      BulbContainer: new Phaser.GameObjects.Container(this),
      ResponseContainer: new Phaser.GameObjects.Container(this),
      StartingDepth: 4,
      SketchImageKey: this.consoleData.assets.images[3],
      AudioKey: this.consoleData.assets.audio[3],
    };

    this.bulb5 = {
      LightBulb: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb5Position.x,
          Lightbulb5Position.y,
          "lightbulb-off"
        )
        .setAlpha(0)
        .setOrigin(0.5)
        .setScale(0.55),
      LightBulbActive: this.add
        .sprite(
          this.cameras.main.centerX - Lightbulb5Position.x,
          Lightbulb5Position.y,
          "lightbulb"
        )
        .setOrigin(0.5)
        .setScale(0.55),
      Text: new Phaser.GameObjects.Text(
        this,
        this.cameras.main.centerX - Lightbulb5Position.x,
        Lightbulb5Position.y,
        this.consoleData.choices[4],
        TextConfig
      )
        .setOrigin(0.5)
        .setAlign("center"),
      StartingPosition: Lightbulb5Position,
      StartingScale: 0.55,
      BulbContainer: new Phaser.GameObjects.Container(this),
      ResponseContainer: new Phaser.GameObjects.Container(this),
      StartingDepth: 5,
      SketchImageKey: this.consoleData.assets.images[4],
      AudioKey: this.consoleData.assets.audio[4],
    };

    this.consoleLightBulbs.push(
      ...[this.bulb1, this.bulb2, this.bulb3, this.bulb4, this.bulb5]
    );

    this.optionsContainer = new Phaser.GameObjects.Container(this);
    this.optionsContainer.setDepth(400);
    this.consoleLightBulbs.forEach((bulb, index: number) => {
      let helpfulText = this.add
        .text(
          -430,
          300,
          this.consoleData.helpfulResponses[index],
          ResponseTextConfig
        )
        .setOrigin(0)
        .setWordWrapWidth(400)
        .setScale(1);

      let unhelpfulText = this.add
        .text(
          100,
          300,
          this.consoleData.unhelpfulResponses[index],
          ResponseTextConfig
        )
        .setOrigin(0)
        .setWordWrapWidth(400);

      let bulbSketch = this.add
        .image(0, 150, bulb.SketchImageKey)
        .setOrigin(0.5, 1);
      bulbSketch.setAlpha(0.3);
      bulbSketch.setScale(0.8);
      let bulbHeadingText = this.add
        .text(
          -430,
          -50,
          this.consoleData.headings[index].toUpperCase(),
          HeadingTextConfig
        )
        .setOrigin(0)
        .setLineSpacing(-25)
        .setWordWrapWidth(1200);

      let helpfulHeadingText = this.add
        .text(-430, 200, "HELPFUL", SubheadingTextConfig)
        .setOrigin(0)
        .setWordWrapWidth(400);

      let unhelpfulHeadingText = this.add
        .text(100, 200, "UNHELPFUL", SubheadingTextConfig)
        .setOrigin(0)
        .setWordWrapWidth(400);

      const containerElements = [
        helpfulText,
        unhelpfulText,
        bulbSketch,
        bulbHeadingText,
        helpfulHeadingText,
        unhelpfulHeadingText,
      ];

      if (
        this.episode === "ep4" &&
        this.consoleData.subHeadings &&
        this.consoleData.subHeadings[index]
      ) {
        const subTitle = this.add
          .text(
            -250,
            385,
            this.consoleData.subHeadings[index],
            ResponseTextConfig
          )
          .setOrigin(0)
          .setWordWrapWidth(1200);

        containerElements.push(subTitle);
      }

      bulb.ResponseContainer.add(containerElements);
      bulb.ResponseContainer.x =
        this.cameras.main.centerX - bulb.StartingPosition.x;
      bulb.ResponseContainer.y = bulb.StartingPosition.y;
      bulb.ResponseContainer.scale = 0;

      bulb.BulbContainer.add([
        bulb.LightBulb,
        bulb.LightBulbActive,
        bulb.Text,
        bulb.ResponseContainer,
      ]);

      bulb.BulbContainer.setDepth(bulb.StartingDepth);
    });

    this.sceneContainer.add([
      this.consoleImage,
      this.lightsB,
      this.lightsC,
      this.lightsD,
      this.consoleGoButtonPulse,
      this.consoleGoButtonInactive,
      this.consoleGoButton,
      this.consoleGrooveInactive,
      this.consoleGroove,
      this.consoleHandlePulse,
      this.consoleHandleInactive,
      this.consoleHandle,
      this.consoleLightBulbs[0].BulbContainer,
      this.consoleLightBulbs[1].BulbContainer,
      this.consoleLightBulbs[2].BulbContainer,
      this.consoleLightBulbs[3].BulbContainer,
      this.consoleLightBulbs[4].BulbContainer,
    ]);

    this.add.existing(this.sceneContainer);

    const aspectRatio = this.scale.baseSize.aspectRatio;

    if (aspectRatio > 1920 / 1080) {
      const newX = (this.scale.baseSize.width - 1920) / 2;
      this.sceneContainer.x = newX;
      this.sceneContainer.y = 0;
    } else {
      const newY = (this.scale.baseSize.height - 1080) / 2;
      this.sceneContainer.y = newY;
      this.sceneContainer.x = 0;
    }

    // add close button dom element
    let closeButtonDiv = document.createElement("div");
    closeButtonDiv.className = "console-close-button";
    let closeButton = document.createElement("a");
    closeButton.onclick = () => {
      this.consoleHandle.input.enabled = true; // enable handle after closing light bulb
      this.CloseLightBulb();
      this.CloseButton.visible = false;
      this.cancelAudio();
    };

    closeButtonDiv.appendChild(closeButton);

    this.CloseButton = this.add.dom(window.innerWidth - 40, 40, closeButtonDiv);
    this.CloseButton.setOrigin(1, 0);
    this.CloseButton.visible = false;

    // add delay and allow scene transition
    this.time.addEvent({
      delay: 1000,
      callback: () => {
        const uiBlock = new UIBlock();
        let speechJSON =
          this.cache.json.get("consoleJSON")[`${this.episode}console`];
        this.textEngine = new TextEngine(this, speechJSON, "scenario");
        uiBlock.add(this.textEngine);
      },
      callbackScope: this,
      loop: false,
    });

    this.events.on("Activity1", () => {
      this.Activity1();
    });

    this.events.on("Activity2", () => {
      this.Activity2();
    });

    this.events.on("Activity3", () => {
      this.Activity3();
    });

    this.events.on("EndActivityCurrentSelection", () => {
      this.textEngine.dismissText();
      sceneFadeInFadeOut(
        this,
        "ConsoleEndScene",
        {
          selectedResponse:
            this.selectedChoices[this.selectedChoices.length - 1].StartingDepth,
          episode: this.episode,
        },
        "Dissolve"
      );
    });

    this.events.on("EndActivityLastSelection", () => {
      this.textEngine.dismissText();
      sceneFadeInFadeOut(
        this,
        "ConsoleEndScene",
        {
          selectedResponse:
            this.selectedChoices[this.selectedChoices.length - 2].StartingDepth,
          episode: this.episode,
        },
        "Dissolve"
      );
    });

    this.input.on(Phaser.Input.Events.DRAG, this.HandleInputDrag, this);
    this.input.on(Phaser.Input.Events.DRAG_END, this.HandleInputDragEnd, this);

    this.sceneExitFunction = () => {
      try {
        this.textEngine.dismissText();
      } catch {}
      this.sceneExitFunction = () => {};
    };
  }

  AlternateLights(sprite: Phaser.GameObjects.Image, noOfFrames: number) {
    let lightsDframe = Math.floor(Math.random() * noOfFrames) + 1;
    sprite.setFrame(lightsDframe.toString());
  }

  ResetScale() {
    // This section here is to fix a bug with the console
    // where the interactable elements are frozen the first time it is loaded
    const isInitialised =
      sessionStorage.getItem(SessionNames.CONSOLE_STATE_INITIALISED) !==
      "false";
    if (!isInitialised) {
      sessionStorage.setItem(SessionNames.CONSOLE_STATE_INITIALISED, "true");
      this.scale.refresh(
        this.game.scale.gameSize.width,
        this.game.scale.gameSize.height
      );
    }
  }

  Activity1() {
    this.time.addEvent({
      delay: 400,
      callback: () => {
        this.EnableConsole(false, () => {}, true);
        this.ResetScale();
      },
      loop: false,
      callbackScope: this,
    });
  }

  Activity2() {
    this.tweens.add({
      targets: this.consoleGoButtonInactive,
      delay: 200,
      alpha: { value: 0, duration: 100, ease: "Power1" },
    });
    this.tweens.add({
      targets: this.consoleGoButton,
      alpha: { value: 1, duration: 400, ease: "Power1" },
      onComplete: () => {
        this.consoleGoButton?.setInteractive({ useHandCursor: true });
        this.consoleGoButtonPulse.setAlpha(1);

        let tweenChain = this.tweens.createTimeline();
        tweenChain.loop = -1;

        let originalScale = this.consoleGoButtonPulse.scale;

        this.ResetScale();

        tweenChain.add({
          targets: this.consoleGoButtonPulse,
          scale: originalScale,
          ease: "Power1",
          duration: 300,
        });

        tweenChain.add({
          targets: this.consoleGoButtonPulse,
          scale: originalScale * 1.1,
          ease: "Power1",
          duration: 300,
        });

        tweenChain.add({
          targets: this.consoleGoButtonPulse,
          scale: originalScale,
          ease: "Power1",
          duration: 300,
        });

        tweenChain.play();
      },
      callbackScope: this,
      delay: 0,
    });
  }

  Activity3() {
    this.textEngine.dismissText();
    this.EnableConsole(true);
    this.consoleHandle.name = "handle_activity4";

    if (this.episode === "ep4" && !this.selectedLightBulb)
      this.selectedLightBulb = this.bulb5;

    this.ResetScale();
  }

  HandleInputDrag(pointer, gameObject, dragX, dragY) {
    if (
      gameObject.name === "handle_activity1" ||
      gameObject.name === "handle_activity4"
    ) {
      if (dragY > MinDragPosition && dragY < MaxDragPosition) {
        updateHandleYvalue = Math.round(dragY);
        gameObject.y = dragY;
        gameObject.x =
          this.cameras.main.centerX - 170 - (dragY - MinDragPosition) / 8;

        // set alpha on bulbs
        let percentage =
          (dragY - MinDragPosition) / (MaxDragPosition - MinDragPosition);

        if (gameObject.name === "handle_activity1") {
          this.consoleLightBulbs[4].LightBulbActive.setAlpha(1 - percentage);
          this.consoleLightBulbs[0].LightBulbActive.setAlpha(percentage);

          if (percentage > 0.1) {
            this.consoleLightBulbs[4].LightBulb.setAlpha(1);
          } else {
            this.consoleLightBulbs[4].LightBulb.setAlpha(0);
          }

          if (percentage > 0.95) {
            this.consoleLightBulbs[0].LightBulb.setAlpha(0);
          } else {
            this.consoleLightBulbs[0].LightBulb.setAlpha(1);
          }
        } else if (gameObject.name === "handle_activity4") {
          var amp = Math.ceil(5 * percentage);
          var selectedIndex = 5 - amp;

          if (this.lightsTimer) {
            this.lightsTimer.timeScale = (selectedIndex + 1) / 2;
          }

          this.consoleLightBulbs.forEach((bulb: LightBulb, index: number) => {
            if (index === selectedIndex) {
              this.selectedLightBulb = bulb;
              bulb.LightBulbActive.setAlpha(1);
              bulb.LightBulb.setAlpha(0);
            } else {
              bulb.LightBulbActive.setAlpha(0);
              bulb.LightBulb.setAlpha(1);
            }
          });
        }
      } else if (dragY < MinDragPosition) {
        updateHandleYvalue = MinDragPosition;
        gameObject.y = MinDragPosition;
        gameObject.x = this.cameras.main.centerX - 170;
        this.selectedLightBulb = this.consoleLightBulbs[4];
        this.consoleLightBulbs[4].LightBulbActive.setAlpha(1);
        this.consoleLightBulbs[4].LightBulb.setAlpha(0);
        this.consoleLightBulbs[3].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[2].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[1].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[0].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[0].LightBulb.setAlpha(1);

        if (this.lightsTimer && gameObject.name === "handle_activity4") {
          this.lightsTimer.timeScale = 3;
        }
      } else if (dragY > MaxDragPosition) {
        updateHandleYvalue = MaxDragPosition;
        gameObject.y = MaxDragPosition;
        gameObject.x =
          this.cameras.main.centerX -
          170 -
          (MaxDragPosition - MinDragPosition) / 8;
        this.selectedLightBulb = this.consoleLightBulbs[0];
        this.consoleLightBulbs[0].LightBulbActive.setAlpha(1);
        this.consoleLightBulbs[0].LightBulb.setAlpha(0);
        this.consoleLightBulbs[1].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[2].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[3].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[4].LightBulbActive.setAlpha(0);
        this.consoleLightBulbs[4].LightBulb.setAlpha(1);

        if (this.lightsTimer && gameObject.name === "handle_activity4") {
          this.lightsTimer.timeScale = 1;
        }
      }

      this.consoleHandlePulse.setPosition(gameObject.x, gameObject.y);
    }
  }

  GetScale(scale: number): number {
    return scale * (1600 / 1920);
  }

  HandleInputDragEnd(pointer, gameObject) {
    if (gameObject.name == "handle_activity1") {
      if (this.consoleHandle && this.consoleHandle.y > MaxDragPosition - 10) {
        if (this.consoleHandle) {
          this.consoleHandle.input.enabled = false;
          this.consoleHandlePulse.setAlpha(0);
          this.textEngine.resumeText(4);
          this.textEngine.cancelAudio();
        }
      }
    }
  }

  EnableConsole(
    enableButton: boolean = false,
    onCompleteFunction?: () => void,
    addButtonPulse: boolean = false
  ) {
    // for some weird reason the context
    const _this = this;
    const tweenDuration = 400;

    if (enableButton) {
      _this.tweens.add({
        targets: _this.consoleGoButtonInactive,
        delay: 200,
        alpha: { value: 0, duration: 100, ease: "Power1" },
      });

      _this.tweens.add({
        targets: _this.consoleGoButton,
        alpha: { value: 1, duration: 400, ease: "Power1" },
        onComplete: () => {
          if (_this.consoleGoButton.input)
            _this.consoleGoButton.input.enabled = true;
          else _this.consoleGoButton?.setInteractive({ useHandCursor: true });
        },
      });
    }

    _this.tweens.add({
      targets: _this.consoleHandleInactive,
      delay: 100,
      alpha: { value: 0, duration: 300, ease: "Power1" },
    });
    _this.tweens.add({
      targets: _this.consoleGroove,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
    });
    _this.tweens.add({
      targets: _this.optionsContainer,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
    });
    _this.tweens.add({
      targets: _this.consoleHandle,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (_this.consoleHandle) {
          _this.consoleHandle.setInteractive({ useHandCursor: true });
          _this.input.setDraggable(_this.consoleHandle);
        }

        if (addButtonPulse) {
          _this.consoleHandlePulse.setAlpha(1);

          let timeline = _this.tweens.createTimeline();
          timeline.loop = -1;
          let originalScale = _this.consoleHandlePulse.scale;

          timeline.add({
            targets: _this.consoleHandlePulse,
            scale: originalScale,
            ease: "Power1",
            duration: 300,
          });

          timeline.add({
            targets: _this.consoleHandlePulse,
            scale: originalScale * 1.05,
            ease: "Power1",
            duration: 300,
          });

          timeline.add({
            targets: _this.consoleHandlePulse,
            scale: originalScale,
            ease: "Power1",
            duration: 300,
          });

          timeline.play();
        }

        _this.Resize();
      },
    });
  }

  DisableConsole(onCompleteFunction: () => void) {
    const tweenDuration = 400;
    this.consoleHandle.input.enabled = false;
    this.consoleGoButton.input.enabled = false;
    this.consoleHandleInactive.setPosition(
      this.consoleHandle.x,
      this.consoleHandle.y
    );
    this.consoleGoButtonInactive.alpha = 1;

    this.tweens.add({
      targets: this.consoleGoButton,
      alpha: { value: 0, duration: 400, ease: "Power1" },
    });
    this.tweens.add({
      targets: this.consoleHandleInactive,
      delay: 0,
      alpha: { value: 1, duration: 100, ease: "Power1" },
    });
    this.tweens.add({
      targets: this.consoleGroove,
      alpha: { value: 0, duration: tweenDuration, ease: "Power1" },
    });
    this.tweens.add({
      targets: this.consoleHandle,
      alpha: { value: 0, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }
      },
    });
  }

  CloseLightBulb() {
    const closeLightBulbSelection = {
      ep2: () => {
        switch (this.currentStep) {
          case 1:
            this.DisableConsole(() => {
              this.UnSelectLightBulb(() => {
                this.textEngine.resumeText(6);
              });
            });

            this.currentStep++;
            break;
          case 2:
            if (this.selectedLightBulb) {
              this.selectedChoices.push(this.selectedLightBulb);
            }

            this.DisableConsole(() => {
              this.UnSelectLightBulb(() => {
                this.textEngine.resumeText(15);
              });
            });

            this.currentStep++;
            break;
          case 3:
            if (this.selectedLightBulb) {
              this.selectedChoices.push(this.selectedLightBulb);
            }

            this.DisableConsole(() => {
              this.UnSelectLightBulb(() => {
                this.textEngine.resumeText(20);
              });
            });
            break;
        }
      },
      ep4: () => {
        const emotionTextSelection = {
          "sit-alone": 100,
          "call-her-parents": 200,
          "watch-from-a-distance": 300,
          "tell-them-off": 400,
          "ask-to-sit": 500,
        };

        const emotionSelection =
          emotionTextSelection[this.selectedLightBulb.SketchImageKey];

        if (emotionSelection)
          this.DisableConsole(() => {
            this.UnSelectLightBulb(() => {
              this.currentStep = 1;

              if (emotionSelection === 500) {
                this.currentStep++;
              }
              this.textEngine.resumeText(emotionSelection);
            });
          });
      },
    };

    const closeFn = closeLightBulbSelection[this.episode];
    closeFn && closeFn();
  }
  addAudioFileToQue(audioKey: string) {
    const audioVl = this.sound.add(audioKey, { volume: 2.5 });
    audioVl.stop();
    // check if we have anything currently playing, if not play
    if (this.audioSequence.length == 0) {
      audioVl.play();
    }

    this.audioSequence.push(audioVl);
  }

  cancelAudio() {
    this.audioSequence.forEach((audio) => {
      audio.stop();
      audio.destroy();
    });

    this.audioSequence = [];
  }

  SelectLightBulb(callbackFunction: () => void) {
    if (
      this.currentStep === 2 &&
      this.episode == "ep4" &&
      this.selectedLightBulb === this.bulb1
    ) {
      sceneFadeInFadeOut(
        this,
        "CinematicScene",
        {
          video: "4_6.1",
          nextScene: "TextEngineScene",
          json: "ep4sc3-1",
          skipEnable: true,
        },
        "FadeToBlack"
      );
      return;
    }

    if (this.selectedLightBulb) {
      zoomBulbToggle = true;
      this.sceneContainer.bringToTop(this.selectedLightBulb.BulbContainer);
      this.selectedLightBulb?.BulbContainer;
      this.selectedLightBulb?.LightBulb.setAlpha(0);
      this.tweens.add({
        targets: this.selectedLightBulb.LightBulbActive,
        x: this.cameras.main.centerX,
        y: this.cameras.main.centerY,
        scale: 3,
        depth: 400,
        loop: false,
        duration: 800,
        ease: Phaser.Math.Easing.Expo.InOut,
        onComplete: () => {
          // call the audio here
          this.addAudioFileToQue(this.selectedLightBulb.AudioKey);
          this.CloseButton.visible = true;
          callbackFunction();
        },
        callbackScope: this,
      });

      this.tweens.add({
        targets: this.selectedLightBulb.Text,
        x: this.cameras.main.centerX,
        y: this.cameras.main.centerY,
        depth: 300,
        loop: false,
        duration: 800,
        ease: Phaser.Math.Easing.Expo.InOut,
      });

      this.tweens.add({
        targets: this.selectedLightBulb.Text,
        alpha: 0,
        loop: false,
        duration: 400,
        ease: Phaser.Math.Easing.Expo.InOut,
      });

      this.tweens.add({
        targets: this.selectedLightBulb.ResponseContainer,
        x: this.cameras.main.centerX,
        y: this.cameras.main.centerY,
        scale: 1,
        loop: false,
        duration: 800,
        ease: Phaser.Math.Easing.Expo.InOut,
      });
    }
  }

  UnSelectLightBulb(callbackFunction: () => void) {
    zoomBulbToggle = false;
    this.tweens.add({
      targets: this.selectedLightBulb?.Text,
      x: this.cameras.main.centerX - this.selectedLightBulb?.StartingPosition.x,
      y: this.selectedLightBulb?.StartingPosition.y,
      alpha: 1,
      loop: false,
      duration: 800,
      ease: Phaser.Math.Easing.Expo.InOut,
      onComplete: () => {
        //this.selectedLightBulb?.LightBulb.setAlpha(1);
        // stop the audio here
        callbackFunction();
      },
      callbackScope: this,
    });

    this.tweens.add({
      targets: this.selectedLightBulb?.LightBulbActive,
      x: this.cameras.main.centerX - this.selectedLightBulb?.StartingPosition.x,
      y: this.selectedLightBulb?.StartingPosition.y,
      scale: this.selectedLightBulb?.StartingScale,
      depth: 300,
      loop: false,
      duration: 800,
      ease: Phaser.Math.Easing.Expo.InOut,
      onComplete: () => {},
      callbackScope: this,
    });

    this.tweens.add({
      targets: this.selectedLightBulb?.ResponseContainer,
      scale: 0,
      x: this.cameras.main.centerX - this.selectedLightBulb?.StartingPosition.x,
      y: this.selectedLightBulb?.StartingPosition.y,
      loop: false,
      duration: 800,
      ease: Phaser.Math.Easing.Expo.InOut,
    });
  }

  Resize() {
    this.consoleImage.x = this.cameras.main.centerX;
    this.lightsB.x = this.cameras.main.centerX - 187;
    this.lightsC.x = this.cameras.main.centerX + 10;
    this.lightsD.x = this.cameras.main.centerX + 355.5;
    this.consoleGoButtonPulse.x = this.cameras.main.centerX + 10;
    this.consoleGoButtonInactive.x = this.cameras.main.centerX + 10;
    this.consoleGoButton.x = this.cameras.main.centerX + 10;
    this.consoleGrooveInactive.x = this.cameras.main.centerX - 135;
    this.consoleGroove.x = this.cameras.main.centerX - 135;
    this.consoleHandlePulse.x =
      this.cameras.main.centerX -
      170 -
      (updateHandleYvalue - MinDragPosition) / 8;
    this.consoleHandleInactive.x =
      this.cameras.main.centerX -
      170 -
      (updateHandleYvalue - MinDragPosition) / 8;
    this.consoleHandle.x =
      this.cameras.main.centerX -
      170 -
      (updateHandleYvalue - MinDragPosition) / 8;
    this.bulb1.LightBulb.x = this.cameras.main.centerX - Lightbulb1Position.x;
    this.bulb1.LightBulbActive.x =
      this.cameras.main.centerX - Lightbulb1Position.x;
    this.bulb1.Text.x = this.cameras.main.centerX - Lightbulb1Position.x;
    this.bulb2.LightBulb.x = this.cameras.main.centerX - Lightbulb2Position.x;
    this.bulb2.LightBulbActive.x =
      this.cameras.main.centerX - Lightbulb2Position.x;
    this.bulb2.Text.x = this.cameras.main.centerX - Lightbulb2Position.x;
    this.bulb3.LightBulb.x = this.cameras.main.centerX - Lightbulb3Position.x;
    this.bulb3.LightBulbActive.x =
      this.cameras.main.centerX - Lightbulb3Position.x;
    this.bulb3.Text.x = this.cameras.main.centerX - Lightbulb3Position.x;
    this.bulb4.LightBulb.x = this.cameras.main.centerX - Lightbulb4Position.x;
    this.bulb4.LightBulbActive.x =
      this.cameras.main.centerX - Lightbulb4Position.x;
    this.bulb4.Text.x = this.cameras.main.centerX - Lightbulb4Position.x;
    this.bulb5.LightBulb.x = this.cameras.main.centerX - Lightbulb5Position.x;
    this.bulb5.LightBulbActive.x =
      this.cameras.main.centerX - Lightbulb5Position.x;
    this.bulb5.Text.x = this.cameras.main.centerX - Lightbulb5Position.x;

    if (zoomBulbToggle) {
      this.selectedLightBulb.LightBulbActive.x = this.cameras.main.centerX;
      this.selectedLightBulb.Text.x = this.cameras.main.centerX;
      this.selectedLightBulb.ResponseContainer.x = this.cameras.main.centerX;
    }
  }
}
