export enum AvailableEpisodes {
  EP1 = "ep1",
  EP2 = "ep2",
  EP3 = "ep3",
  EP4 = "ep4",
}

export enum SessionNames {
  APP_STATE = "app-state",
  EVENT_QUEUE = "event-queue",
  CONSOLE_STATE_INITIALISED = "console-state-initialised",
  VISUALISER_STATE_INITIALISED = "visualiser-state-initialised",
}

export enum AppStates {
  INITIALIZED = "initialised",
}
