import Phaser from "phaser";
import { ConfirmationModal } from "../../components/Navigation/confirmationModal";
import { MenuModal } from "../../components/Navigation/menuModal";
import * as Tone from "tone";
import { resizeImage } from "~/lib/util";

let menuBtn: HTMLButtonElement;
let muteButton: HTMLButtonElement;

export default class BaseScene extends Phaser.Scene {
  private backgroundUrl?: string;
  public backgroundUrlKey?: string;
  public background!: Phaser.GameObjects.Image;
  private key: string;
  public resizeFunction?: () => void;
  public sceneExitFunction?: () => void;
  private updateBg?: boolean;
  private updateBgKey?: any;
  public toolBarLeftElement!: Phaser.GameObjects.DOMElement;
  public isAppMuted = false;
  public video?: any;
  public tone1?: Tone.Player;
  public tone2?: Tone.Player;
  public tone3?: Tone.Player;
  public tone4?: Tone.Player;
  public toneFlag1?: boolean;
  public toneFlag2?: boolean;
  public toneFlag3?: boolean;
  public toneFlag4?: boolean;
  public backgroundWidth = 1920;
  public backgroundHeight = 1080;
  public handleMuteStateFunction!: () => void;

  constructor(config: string, backgroundUrl?: string) {
    super({ key: config });
    this.key = config;
    this.backgroundUrl = backgroundUrl;
  }

  preload() {
    if (!!this.backgroundUrl)
      this.load.image("background" + this.key, this.backgroundUrl);
  }

  renderMuteButton() {
    this.handleMuteState();
    muteButton = document.createElement("button");
    muteButton.id = "muteBtn";
    muteButton.className = this.isAppMuted
      ? "btn-v2 mute-btn muted"
      : "btn-v2 mute-btn";
    muteButton.innerHTML = `
                    <svg id="mute-icon" class="btn-icon" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.667 6.6665L8.00033 11.9998H2.66699V19.9998H8.00033L14.667 25.3332V6.6665Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M25.4264 6.57324C27.926 9.07361 29.3302 12.4644 29.3302 15.9999C29.3302 19.5354 27.926 22.9262 25.4264 25.4266M20.7197 11.2799C21.9695 12.5301 22.6716 14.2255 22.6716 15.9932C22.6716 17.761 21.9695 19.4564 20.7197 20.7066" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> 
                    <svg id="muted-icon" class="btn-icon" viewBox="0 0 24 24"  fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_174_308)">
                      <path d="M11 5L6 9H2V15H6L11 19V5Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.7071 9.70711C24.0976 9.31658 24.0976 8.68342 23.7071 8.29289C23.3166 7.90237 22.6834 7.90237 22.2929 8.29289L20 10.5858L17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289C15.9024 8.68342 15.9024 9.31658 16.2929 9.70711L18.5858 12L16.2929 14.2929C15.9024 14.6834 15.9024 15.3166 16.2929 15.7071C16.6834 16.0976 17.3166 16.0976 17.7071 15.7071L20 13.4142L22.2929 15.7071C22.6834 16.0976 23.3166 16.0976 23.7071 15.7071C24.0976 15.3166 24.0976 14.6834 23.7071 14.2929L21.4142 12L23.7071 9.70711Z" fill="currentColor"/>
                      </g>
                      <defs><clipPath id="clip0_174_308"><rect width="24" height="24" fill="currentColor"/></clipPath></defs>
                    </svg> 
                    `;

    const muteButtonContainer = document.createElement("div");
    muteButtonContainer.className = "mute-btn-div";
    muteButtonContainer.appendChild(muteButton);

    this.toolBarLeftElement = this.add.dom(
      window.innerWidth - 40,
      parseInt(this.game.canvas.style.height) - 40,
      muteButtonContainer
    );

    this.toolBarLeftElement.setDepth(250);
    this.toolBarLeftElement.setOrigin(0);

    muteButton.onclick = () => {
      if (this.isAppMuted) {
        this.toggleMute();
        muteButton.className = "btn-v2 mute-btn";
        this.handleMuteState();
      } else {
        this.toggleMute();
        muteButton.className = "btn-v2 mute-btn muted";
        this.handleMuteState();
      }
    };
  }

  toggleMute() {
    this.isAppMuted = !this.isAppMuted;
    this.updateMuteState(this.isAppMuted);
  }

  updateMuteState(isMuted: boolean) {
    const muteStateString = isMuted ? "true" : "false";
    localStorage.setItem("muteState", muteStateString);
  }

  getMuteState() {
    this.isAppMuted = localStorage.getItem("muteState") === "true";
  }

  handleMuteState() {
    const storedMuteStateString = localStorage.getItem("muteState");
    this.isAppMuted = storedMuteStateString === "true";
    this.sound.mute = this.isAppMuted; // mute app globally

    if (this.tone1) {
      this.tone1.mute = this.isAppMuted ? true : !this.toneFlag1;
    }
    if (this.tone2) {
      this.tone2.mute = this.isAppMuted ? true : !this.toneFlag2;
    }
    if (this.tone3) {
      this.tone3.mute = this.isAppMuted ? true : !this.toneFlag3;
    }
    if (this.tone4) {
      this.tone4.mute = this.isAppMuted ? true : !this.toneFlag4;
    }

    if (this.video) {
      this.video.setMute(this.isAppMuted);
    }
  }

  create(showHome = true) {
    this.getMuteState();
    this.handleMuteStateFunction = this.handleMuteState;

    // create background and resize function
    if (!!this.backgroundUrl) {
      this.background = this.add.image(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        "background" + this.key
      );
      this.background.setScale(window.innerHeight / this.backgroundHeight);
    } else if (!!this.backgroundUrlKey) {
      this.background = this.add.image(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        this.backgroundUrlKey
      );
      this.background.setScale(window.innerHeight / this.backgroundHeight);
    }

    const resize = () => {
      this.resizeFunction && this.resizeFunction();
      resizeImage(
        this,
        this.background,
        this.backgroundWidth,
        this.backgroundHeight
      );
    };

    this.scale.on("resize", (gameSize) => {
      this.cameras.resize(gameSize.width, gameSize.height);
      resize();
    });
    resize();

    window.addEventListener("resize", () => resize());

    // add global menu screen button
    if (showHome) {
      const menuDiv = document.createElement("div");
      menuBtn = document.createElement("button");
      menuBtn.className = "btn-v2 btn-menu";
      menuBtn.innerHTML = `
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 11H22" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M10 16H22" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
        <path d="M10 21H22" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
      </svg>
      <span>Menu</span>`;

      menuBtn.onclick = () => {
        const body = document.querySelector("body");
        body?.classList.remove("enable-scroll");

        menuBtn?.classList.add("selected");
        this.showMenu();
      };

      menuDiv.appendChild(menuBtn);

      const menuButton = this.add.dom(0, 0, menuDiv);
      menuButton.setDepth(250);
      menuButton.setOrigin(0);
    }

    if (!["EndOfEpisodeScene", "EndOfExperienceScene"].includes(this.scene.key))
      this.renderMuteButton();
  }

  showMenu() {
    MenuModal(this, menuBtn, this.sceneExitFunction);
  }

  exitScene() {
    this.resizeFunction = () => {};

    if (this.sceneExitFunction) {
      this.sceneExitFunction();
    }
  }

  onFocus() {
    //console.log("focus");
  }

  changeBackground(imgId) {
    this.updateBg = true;
    if (!!imgId) {
      this.updateBgKey = imgId;
    }
  }

  update() {
    if (this.updateBg) {
      this.background = this.add.image(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        this.updateBgKey
      );
    }
    this.updateBg = false;
  }
}
