import { debug } from "console";

type TModalParams = {
  id: string;
  content?: string;
  className?: string | string[];
  title?: string;
  fadeType?: "fade" | "slide-left" | "slide-right";
};

type TModalAnimationMap = {
  [key: string]: TModalAnimation;
};

type TModalAnimation = {
  in: string;
  out: string;
};

export default class Modal {
  private componentName: string = "modal";
  private bodyElement: HTMLBodyElement | null = document.querySelector("body");
  private closeButton: HTMLButtonElement | null = null;
  private animationSpeed: number = 400;
  private animationMap: TModalAnimationMap = {
    fade: {
      in: "modalFadeIn",
      out: "modalFadeout",
    },
    "slide-right": {
      in: "slide-right-in",
      out: "slide-right-out",
    },
  };
  overlay!: HTMLElement;
  modal!: HTMLElement;
  modalHeader: HTMLElement | null = null;
  modalBody: HTMLElement | null = null;
  data: any | null = null;
  state: "open" | "closed" = "closed";
  params: TModalParams = {
    id: "",
    fadeType: "fade",
  };

  constructor(params: TModalParams) {
    this.params = {
      ...this.params,
      ...params,
    };
    this.createModal();
    this.bindEvents();
  }

  show() {
    // ensure there is an overlay
    if (this.overlay === null) {
      this.overlay = this.createOverlay();
    }
    this.overlay.classList.add("show");
    this.modal?.classList.add("show");
    this.bodyElement?.classList.remove("enable-scroll");
    this.state = "open";
  }

  hide() {
    this.overlay?.classList.remove("show");
    this.modal?.classList.remove("show");
    this.bodyElement?.classList.add("enable-scroll");
    this.state = "closed";
  }

  destroy() {
    this.closeButton?.removeEventListener("click", this.hideFn);
    this.modal?.remove();
    this.overlay?.remove();
    this.bodyElement?.classList.add("enable-scroll");
  }

  setTitle(title: string) {
    this.params.title = title;
    let titleElement = this.modalHeader?.querySelector(
      `.${this.componentName}__title`
    ) as HTMLElement;

    if (titleElement) titleElement.innerHTML = title;
    else {
      titleElement = document.createElement("div");
      titleElement.classList.add(`${this.componentName}__title`);
      if (titleElement) titleElement.innerHTML = title;
      this.modalHeader?.appendChild(titleElement);
    }
  }

  setContent(content: string) {
    if (this.modalBody) this.modalBody.innerHTML = content;
  }

  private hideFn = () => {
    const _this = this;

    if (_this.modal && _this.overlay) {
      const modalOutAnimation = `${_this.getAnimationMap().out} ${
        _this.animationSpeed
      }ms forwards`;
      _this.modal.style.animation = modalOutAnimation;
      _this.overlay.style.animation = `${_this.animationMap["fade"].out} ${_this.animationSpeed}ms forwards`;
      _this.state = "closed";
    }

    setTimeout(() => {
      if (_this.modal && _this.overlay) {
        _this.hide();
        const modalInAnimation = `${_this.getAnimationMap().in} ${
          _this.animationSpeed
        }ms forwards`;
        _this.modal.style.animation = modalInAnimation;
        _this.overlay.style.animation = `${_this.animationMap["fade"].in} ${_this.animationSpeed}ms forwards`;
        _this.state = "open";
      }
    }, _this.animationSpeed);
  };

  private resizeFn = () => {
    const _this = this;
    if (_this.params.fadeType !== "fade") {
      if (_this.state === "open")
        _this.modal.style.animation = `${_this.getAnimationMap().in} ${
          _this.animationSpeed
        }ms forwards`;
      else
        _this.modal.style.animation = `${_this.getAnimationMap().out} ${
          _this.animationSpeed
        }ms forwards`;
    }
  };

  private createOverlay(): HTMLElement {
    let overlay = document.querySelector(
      `.${this.componentName}__overlay`
    ) as HTMLElement;

    if (overlay === null) {
      overlay = document.createElement("div");
      overlay.classList.add(`${this.componentName}__overlay`);
      this.bodyElement?.appendChild(overlay);
    }

    return overlay;
  }

  private createModal() {
    this.overlay = this.createOverlay();

    this.modal = document.createElement("div");
    this.modal.classList.add(
      this.componentName,
      ...[this.params.fadeType ?? "fade"],
      ...(typeof this.params.className === "string"
        ? [this.params.className]
        : this.params.className ?? [])
    );
    this.modal.id = this.params.id;

    this.modalHeader = this.createModalHeader();
    this.modalBody = this.createModalBody();

    this.modal.appendChild(this.modalHeader);
    this.modal.appendChild(this.modalBody);

    this.bodyElement?.appendChild(this.modal);
  }

  private createModalHeader(): HTMLElement {
    const modalHeader = document.createElement("div");
    modalHeader.classList.add(`${this.componentName}__header`);

    if (this.params.title) {
      const headerTitle = document.createElement("div");
      headerTitle.classList.add(`${this.componentName}__title`);
      headerTitle.innerHTML = this.params.title;
      modalHeader.appendChild(headerTitle);
    }

    this.closeButton = document.createElement("button");
    this.closeButton.classList.add(`${this.componentName}__close`);
    this.closeButton.innerHTML = `
            <svg id="modal__close-icon" class="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M25.7678 9.7678C26.7441 8.79149 26.7441 7.20857 25.7678 6.23226C24.7915 5.25595 23.2085 5.25595 22.2322 
                  6.23226L16 12.4645L9.76777 6.23226C8.79146 5.25595 7.20854 5.25595 6.23223 6.23226C5.25592 7.20857 5.25592 
                  8.79149 6.23223 9.7678L12.4645 16L6.23223 22.2323C5.25592 23.2086 5.25592 24.7915 6.23223 25.7678C7.20854 
                  26.7441 8.79146 26.7441 9.76777 25.7678L16 19.5356L22.2322 25.7678C23.2085 26.7441 24.7915 26.7441 25.7678 
                  25.7678C26.7441 24.7915 26.7441 23.2086 25.7678 22.2323L19.5355 16L25.7678 9.7678Z" fill="currentColor"></path>
            </svg>
        `;

    modalHeader.appendChild(this.closeButton);

    return modalHeader;
  }

  private createModalBody(): HTMLElement {
    const modalBody = document.createElement("div");
    modalBody.classList.add(`${this.componentName}__body`);

    if (this.params.content) modalBody.innerHTML = this.params.content;

    return modalBody;
  }

  private getAnimationMap(): TModalAnimation {
    let animationName = this.params.fadeType ?? "fade";

    if (window.innerWidth <= 1050) animationName = "fade";

    return this.animationMap[animationName];
  }

  bindEvents() {
    this.closeButton?.addEventListener("click", this.hideFn);
    window.addEventListener("resize", this.resizeFn);
  }
}
