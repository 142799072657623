import Phaser from "phaser";
import { scaleGame } from "./lib/game";
import { displayDeviceWarnings } from "./lib/device";
import { exposeSafeAreaBorder } from "./lib/resize";
import config from "./config";
import { SessionNames } from "~/config/enums";

const Game = new Phaser.Game(config);
const scaleGameFunc = scaleGame(Game);

// Attach event listeners
window.addEventListener("load", scaleGameFunc);
window.addEventListener("load", exposeSafeAreaBorder);
window.addEventListener("resize", scaleGameFunc);
window.addEventListener("resize", () => {
  displayDeviceWarnings(Game);
});
window.addEventListener("resize", exposeSafeAreaBorder);

displayDeviceWarnings(Game);

sessionStorage.setItem(SessionNames.CONSOLE_STATE_INITIALISED, "false");
sessionStorage.setItem(SessionNames.VISUALISER_STATE_INITIALISED, "false");

export default Game;
