import { Speech } from "../models/speech";
import TextEngine from "../components/TextEngine/TextEngine";
import UIBlock from "../lib/UIBlock";
import BaseScene from "./common/BaseScene";
import jsonData from "../json/speech.json";
import jsonScenario from "../json/scenarioSpeech.json";
import sceneFlow from "../json/sceneFlow.json";
import { loadAssets } from "../lib/util";

export default class TextEngineScene extends BaseScene {
  speech!: Array<Speech>;
  speechArray!: Array<Phaser.GameObjects.Container>;
  count!: number;
  countVal!: number;
  responseY!: number;
  jsonName!: string;

  constructor() {
    super("TextEngineScene", "/assets/mindworker-lounge.jpg");
  }

  preload(): void {
    super.preload();
    this.load.json("speech", jsonData);
    this.load.json("scenarioJSON", jsonScenario);
    this.load.path = "assets/";
    this.load.audio("dialogueAudio", "/audio/Medium.mp3");
    this.load.image("riley-hockey-1", "hockey_1.jpg");
    this.load.audio("E1_05", "/audio/E1_05_V1.mp3");
    loadAssets(this, this.jsonName, [jsonData, jsonScenario]);

    //update url at the start of the textengine scene
    let deepLink = sceneFlow["deep-links"][0][this.jsonName];
    if (deepLink) {
      const url = new URL(window.location.href);
      url.searchParams.set("ch", deepLink);
      localStorage.setItem("currentParam", deepLink);
      window.history.pushState(null, "", url.toString());
    }
  }
  init(data) {
    this.jsonName = data.data?.json ? data.data.json : data.data;
  }

  create(): void {
    super.create();
    let speechJSON, template;
    const uiBlock = new UIBlock();

    if (this.jsonName.toLowerCase().includes("mindworker")) {
      let mwPose = "MW01A.png";

      if (this.jsonName === "ep3Mindworker1") {
        mwPose = "MW07B.png";
      }

      var sprite = this.add.image(
        this.cameras.main.centerX - 200,
        this.cameras.main.height / 2 + 50,
        "mindworker-sprite",
        mwPose
      );
      sprite.setScale(0.68);
      sprite.setOrigin(0.5);

      sprite.setFrame(mwPose);

      this.events.on("changeMindworkerPose", (pose) => {
        sprite.setFrame(pose);
      });
    } else {
      var img = this.add.image(
        this.cameras.main.centerX,
        this.cameras.main.centerY,
        "riley-hockey-1"
      );
      img.setOrigin(0.5);

      this.events.on("changeBackground", (imgId) => {
        this.changeBackground(imgId);
      });
    }

    if (this.jsonName.toLowerCase().includes("mindworker")) {
      speechJSON = this.cache.json.get("speech")[this.jsonName];
      template = "mindworker";
    } else {
      speechJSON = this.cache.json.get("scenarioJSON")[this.jsonName];
      template = "scenario";
    }

    let textEngine = new TextEngine(this, speechJSON, template);

    uiBlock.add(textEngine);

    this.sceneExitFunction = () => {
      textEngine.dismissText();
      this.sceneExitFunction = () => {};
    };
  }
}
