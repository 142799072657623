// Displays warnings for portrait mobile devices
import { sceneFadeInFadeOut } from "~/lib/util";
import { SessionNames } from "~/config/enums";

export function checkIfHasInvalidDeviceOrientation(
  hasChapterParam: boolean = false
) {
  const orientationTest = isPortrait() && window.innerWidth < 600;
  return (
    (orientationTest && !!window.location.search) ||
    (orientationTest && hasChapterParam)
  );
}

export function displayDeviceWarnings(
  Game: Phaser.Game,
  hasChapterParam: boolean = false
) {
  const hasInvalidDeviceOrientation =
    checkIfHasInvalidDeviceOrientation(hasChapterParam);
  if (hasInvalidDeviceOrientation) {
    showElement("rotate-device");
    return true;
  } else {
    showElement("phaser");

    const eventQueue = sessionStorage.getItem(SessionNames.EVENT_QUEUE);
    if (!eventQueue) return false;

    const sessionData = JSON.parse(eventQueue);
    const scene = Game.scene.getScene("AssetLoaderScene");

    sceneFadeInFadeOut(
      scene,
      sessionData.eventData.sceneName,
      sessionData.eventData.data,
      sessionData.eventData.fadeType
    );

    sessionStorage.removeItem(SessionNames.EVENT_QUEUE);

    return false;
  }
}

// Returns true if device is in portrait mode
function isPortrait() {
  const isPortraitLikeAspectRatio =
    window.innerHeight / window.innerWidth >= 0.8;
  const mediaQuery = window.matchMedia("(orientation: portrait)")?.matches;
  return mediaQuery || isPortraitLikeAspectRatio;
}

// Enables the given element and disables all others
const allElements = ["phaser", "device-warning", "rotate-device"];
function showElement(id: string) {
  allElements.forEach((_id) => {
    if (_id === id) toggleElement({ id: _id, visible: true });
    else toggleElement({ id: _id, visible: false });
  });
}

// Toggles the visibility of the given element
function toggleElement({ id, visible }: { id: string; visible: boolean }) {
  const element: HTMLElement | null = document.querySelector(`#${id}`);
  if (!element)
    return console.warn("Could not find element with selector: ", id);
  element.style.display = visible ? "block" : "none";
}
