export const colors = {
  purple90: 0x110a29,
  purple80: 0x221452,
  purple60: 0x4427a4,
  purple40: 0x6b43ef,
  purple20: 0xaf97ff,
  purple5: 0xebe5ff,
  neutral: 0xffffff,
  black: 0x000000,
};
