export function exposeSafeAreaBorder() {
  const rootEl = document.documentElement;

  if (!rootEl) return;
  const computedStyles = getComputedStyle(rootEl);
  const safeAreaGutterStyle =
    computedStyles.getPropertyValue("--safe-area-border");
  const safeAreaGutter = parseInt(safeAreaGutterStyle);

  window.__CUIO__ = window.__CUIO__ || {};
  window.__CUIO__.safeAreaBorder = safeAreaGutter;
}
