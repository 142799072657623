import BaseScene from "./common/BaseScene";
import { sceneFadeInFadeOut } from "../lib/util";
import TextEngine from "../components/TextEngine/TextEngine";
import jsonScenario from "../json/scenarioSpeech.json";
import sceneFlow from "../json/sceneFlow.json";
import { checkIfHasInvalidDeviceOrientation } from "~/lib/device";
import { SessionNames } from "~/config/enums";
import canAutoPlay from "can-autoplay";

export default class CinematicScene extends BaseScene {
  videoUrl!: string;
  nextScene!: string;
  nextVideo!: string;
  endOfExperience!: boolean;
  json!: string | object;
  fadeType!: string;
  skip: boolean = false;
  playButtonDiv!: HTMLElement;
  playPauseButton!: HTMLButtonElement;
  skipButton!: HTMLButtonElement;
  buttonElement!: Phaser.GameObjects.DOMElement;
  pauseVideo!: Function;
  deepLink!: string;

  constructor(key?: string) {
    super(!!key ? key : "CinematicScene");
  }

  init(url) {
    this.videoUrl = url.data.video;
    this.nextVideo = url.data.nextVideo;
    this.nextScene = url.data.nextScene;
    this.json = url.data.json;
    this.fadeType = url.data.fadeType;
    this.skip = url.data.skipEnable;
    this.endOfExperience = url.data.endOfExperience;

    //update url at the start of a cinematic scene
    this.deepLink = sceneFlow["deep-links"][0][this.videoUrl];
    if (this.deepLink) {
      const url = new URL(window.location.href);
      url.searchParams.set("ch", this.deepLink);
      localStorage.setItem("currentParam", this.deepLink);
      window.history.pushState(null, "", url.toString());

      this.skip =
        sceneFlow[this.deepLink][0]?.Data?.SkipEnable ??
        sceneFlow[this.deepLink][0]?.SkipEnable ??
        false;
    }
  }

  preload() {
    super.preload();
    let hasKey = this.cache.video.has(this.videoUrl);
    if (!hasKey) {
      let url = `assets/videos/${this.videoUrl}.mp4`;
      this.load.video(this.videoUrl, url, "loadeddata", false, false);
    }

    if (this.nextVideo) {
      let hasKey = this.cache.video.has(this.nextVideo);
      if (!hasKey) {
        let url = `assets/videos/${this.nextVideo}.mp4`;
        this.load.video(this.nextVideo, url, "loadeddata", false, false);
      }
    }
    this.load.spritesheet("homeBtn", "/assets/home-sprite.png", {
      frameWidth: 100,
      frameHeight: 100,
    });

    this.load.json("scenarioJSON", jsonScenario);
  }

  create() {
    super.create();
    //fade in the scene
    this.video = this.add.video(
      this.cameras.main.centerX,
      this.cameras.main.centerY,
      this.videoUrl
    );

    this.handleMuteStateFunction();
    this.video.setLoop(false);

    this.ResizeVideo();

    this.playButtonDiv = document.createElement("div");
    this.playButtonDiv.className = "play-btn-div";

    this.playPauseButton = document.createElement("button");
    this.playPauseButton.id = "playBtn";
    this.playPauseButton.className = "btn-v2 play-pause-btn pause";
    this.playPauseButton.innerHTML = `
                        <svg id="play-icon" class="btn-icon"  xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                          <path d="M40.7817 22.7682L17.8736 8.38435C17.4877 8.14193 17.0459 8.00951 16.5937 8.00071C16.1415 7.99192 15.6952 8.10707 15.3007 8.33432C14.9062 8.56157 14.5777 8.89271 14.3491 9.29367C14.1205 9.69463 14 10.1509 14 10.6156L14 39.3858C14.0004 39.8503 14.1211 40.3064 14.3498 40.7071C14.5785 41.1079 14.9069 41.4388 15.3013 41.666C15.6957 41.8932 16.1418 42.0084 16.5939 41.9998C17.0459 41.9912 17.4876 41.8591 17.8736 41.6171L40.7817 27.2333C41.1539 26.9995 41.4613 26.6714 41.6747 26.2805C41.888 25.8895 42 25.4489 42 25.0007C42 24.5526 41.888 24.1119 41.6747 23.721C41.4613 23.3301 41.1539 23.002 40.7817 22.7682Z" fill="#EBE5FF"/>
                        </svg>
                        <svg id="pause-icon" class="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
                          <path d="M27.9997 6V26C27.999 26.5302 27.7817 27.0386 27.3954 27.4135C27.0091 27.7885 26.4854 27.9994 25.9391 
                                28H21.3027C20.7564 27.9994 20.2326 27.7885 19.8463 27.4135C19.46 27.0386 19.2427 26.5302 19.2421 26V6C19.2427 
                                5.46975 19.46 4.9614 19.8463 4.58646C20.2326 4.21151 20.7564 4.00061 21.3027 4H25.9391C26.4854 4.00061 27.0091 
                                4.21151 27.3954 4.58646C27.7817 4.9614 27.999 5.46975 27.9997 6ZM12.03 4H7.39361C6.8473 4.00061 6.32354 4.21151 
                                5.93724 4.58646C5.55093 4.9614 5.33363 5.46975 5.33301 6V26C5.33363 26.5302 5.55093 27.0386 5.93724 27.4135C6.32354 
                                27.7885 6.8473 27.9994 7.39361 28H12.03C12.5763 27.9994 13.1001 27.7885 13.4864 27.4135C13.8727 27.0386 14.09 26.5302 
                                14.0906 26V6C14.09 5.46975 13.8727 4.9614 13.4864 4.58646C13.1001 4.21151 12.5763 4.00061 12.03 4Z" fill="currentColor"/>
                        </svg>
                        `;
    this.playButtonDiv.appendChild(this.playPauseButton);

    if (this.skip) {
      //temporary skip button
      this.skipButton = document.createElement("button");
      this.skipButton.id = "skipBtn";
      this.skipButton.className = "btn-v2 btn-skip";
      this.skipButton.innerHTML = `<svg id="skip-icon" class="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
                          <path d="M29.333 7.11103V24.8888C29.3324 25.3601 29.1339 25.812 28.781 26.1453C28.4282 26.4786 27.9497 26.666 
                                27.4507 26.6666H23.2154C22.7163 26.666 22.2379 26.4786 21.885 26.1453C21.5321 25.812 21.3336 25.3601 
                                21.333 24.8888V7.11103C21.3336 6.6397 21.5321 6.18783 21.885 5.85455C22.2379 5.52127 22.7163 5.33379 
                                23.2154 5.33325H27.4507C27.9497 5.33379 28.4282 5.52127 28.781 5.85455C29.1339 6.18783 29.3324 6.6397 
                                29.333 7.11103Z" fill="currentColor"/>
                          <path d="M17.9708 14.7746L4.88045 6.87763C4.65996 6.74453 4.40753 6.67183 4.14912 6.66701C3.89072 6.66218 
                                3.63568 6.7254 3.41024 6.85016C3.1848 6.97493 2.99711 7.15673 2.86648 7.37687C2.73585 7.597 2.66699 7.84752 
                                2.66699 8.10264V23.898C2.6672 24.1531 2.73619 24.4035 2.86687 24.6235C2.99756 24.8435 3.18522 25.0252 3.41058 
                                25.1499C3.63595 25.2746 3.89088 25.3379 4.14921 25.3332C4.40753 25.3284 4.65992 25.2559 4.88045 25.1231L17.9708 
                                17.2261C18.1835 17.0977 18.3592 16.9176 18.4811 16.7029C18.603 16.4883 18.667 16.2464 18.667 16.0003C18.667 
                                15.7543 18.603 15.5124 18.4811 15.2977C18.3592 15.0831 18.1835 14.903 17.9708 14.7746Z" fill="currentColor"/>
                          </svg>
                          <span>Skip</span>`;
      this.playButtonDiv.appendChild(this.skipButton);
      this.skipButton.onclick = () => {
        onVideoComplete(true);
      };
    }

    const isChrome =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const isEdge = /Edg/.test(navigator.userAgent);

    if (isChrome && !isMobile && !isEdge) {
      this.video.play();
    } else {
      canAutoPlay.video().then(({ result }) => {
        if (result) {
          this.video.play();
          return;
        }

        this.playButtonDiv.classList.add("video-paused");
        this.playPauseButton.className = "btn-v2 play-pause-btn";
      });
    }

    this.buttonElement = this.add.dom(
      window.innerWidth - 40,
      parseInt(this.game.canvas.style.height) - 40,
      this.playButtonDiv
    );
    this.buttonElement.setDepth(150);
    this.buttonElement.setOrigin(1);

    window.addEventListener("visibilitychange", () => {
      if (
        this.video.isPaused() &&
        !this.playButtonDiv.classList.contains("video-paused")
      ) {
        this.playButtonDiv.classList.add("video-paused");
        this.playPauseButton.className = "btn-v2 play-pause-btn";
      }
    });

    this.playPauseButton.onclick = () => {
      if (this.video.isPlaying()) {
        this.playButtonDiv.classList.add("video-paused");
        this.video.setPaused(true);
        this.playPauseButton.className = "btn-v2 play-pause-btn";
      } else {
        this.playButtonDiv.classList.remove("video-paused");
        this.video.play();
        this.playPauseButton.className = "btn-v2 play-pause-btn pause";
      }
    };

    let videoResume = this.resumeVideo.bind(this);

    //window.addEventListener("focus", videoResume);

    this.sceneExitFunction = () => {
      this.resizeFunction = () => {};
      //window.removeEventListener("focus", videoResume);
    };

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.repeat) return;
      if (event.key === "Enter" && process.env.ALLOW_CINEMATIC_SKIP === "true")
        onVideoComplete(true);
    };

    const onVideoComplete = (skip: boolean = false) => {
      // remove resize and focus function for scene
      this.resizeFunction = () => {};
      window.removeEventListener("focus", videoResume);
      window.removeEventListener("keydown", onKeyDown);

      if (!this.nextScene && this.endOfExperience) {
        sceneFadeInFadeOut(this, "EndOfExperienceScene", {}, "HorizontalWipe");
        return;
      }

      if (this.nextScene.toLowerCase().includes("cinematic")) {
        sceneFadeInFadeOut(
          this,
          this.nextScene,
          {
            video: this.nextVideo,
            skipEnable: this.skip,
            endOfExperience: this.endOfExperience,
          },
          this.fadeType ?? "Dissolve"
        );
        return;
      }

      if (
        typeof this.json !== "string" ||
        this.json?.toLowerCase().includes("mindworker")
      ) {
        sceneFadeInFadeOut(this, this.nextScene, this.json, this.fadeType);
        return;
      }

      if (this.json?.toLowerCase() === "ep2sc1") {
        sceneFadeInFadeOut(this, this.nextScene, this.json, "Dissolve");
        return;
      }

      if (!this.json) return;
      if (skip) {
        const currentVideoTime = this.video.getCurrentTime();
        const videoDuration = this.video.getDuration();

        if (currentVideoTime !== videoDuration)
          this.video.setCurrentTime(videoDuration);
        return;
      }

      let speechJSON = this.cache.json.get("scenarioJSON")[this.json];
      let textEngine = new TextEngine(this, speechJSON, "scenario");
      this.buttonElement.destroy();
    };

    window.addEventListener("keydown", onKeyDown);

    this.video.on("complete", () => {
      onVideoComplete();
    });

    this.resizeFunction = () => {
      this.video.setPosition(
        this.cameras.main.centerX,
        this.cameras.main.centerY
      );

      this.ResizeVideo();

      this.buttonElement.x = window.innerWidth - 35;
      this.buttonElement.y = parseInt(this.game.canvas.style.height) - 40;

      const hasInvalidDeviceOrientation = checkIfHasInvalidDeviceOrientation(
        !!this.deepLink
      );
      if (hasInvalidDeviceOrientation) {
        this.pauseVideo();
      }
    };

    this.pauseVideo = () => {
      this.playButtonDiv.classList.add("video-paused");
      this.video.setPaused(true);
      this.playPauseButton.className = "btn-v2 play-pause-btn";
    };
  }

  private ResizeVideo() {
    if (this.video) {
      let wallpaperAspect = 1920 / 1080;
      let windowAspect = window.innerWidth / window.innerHeight;

      if (windowAspect > wallpaperAspect) {
        this.video.displayWidth = this.scale.baseSize.width;
        this.video.displayHeight = this.scale.baseSize.width * (1080 / 1920);
      } else {
        this.video.displayHeight = this.scale.baseSize.height;
        this.video.displayWidth = this.scale.baseSize.height * (1920 / 1080);
      }
    }
  }

  resumeVideo() {
    if (this.video.isPaused()) {
      this.video.play();
      this.playPauseButton.className = "btn-v2 play-pause-btn pause";
    }
  }
}
