import Modal from "~/components/Modal/Modal";
import { colors } from "~/lib/colors";
import { sceneFadeInFadeOut } from "~/lib/util";
import sceneFlow from "../../json/sceneFlow.json";
import * as url from "url";
import { SessionNames } from "~/config/enums";
import { debug } from "console";

type MenuOption = {
  id: string;
  image?: string;
  color?: "default" | "red" | "blue" | "yellow";
  display: string;
  subtext?: string;
  thumbnail?: string;
  active?: boolean;
  subItems?: MenuOption[];
};

export const MenuModal = (
  scene: Phaser.Scene,
  menuButton: HTMLButtonElement,
  sceneExitFunction?: () => void
) => {
  //Pause if a video is playing
  if (scene["video"]?.isPlaying()) {
    scene["video"].setPaused(true);
    scene["playButtonDiv"].classList.add("video-paused");
    scene["playPauseButton"].className = "btn-v2 play-pause-btn";
  }

  scene.sound.pauseAll();

  let menuContainer = new Phaser.GameObjects.Container(scene);

  const menuBtnFunction = menuButton.onclick;

  // const backgroundFade = new Phaser.GameObjects.Rectangle(
  //   scene,
  //   scene.game.canvas.width / 2,
  //   scene.game.canvas.height / 2,
  //   scene.game.canvas.width,
  //   scene.game.canvas.height,
  //   colors.black,
  //   0.4
  // );
  //
  // backgroundFade.setInteractive();

  const getChapterListMaxHeight = () => {
    const gap = 128;
    const multiplier = 0.71428571;
    const leftPane = document.querySelector(".left-pane") as HTMLElement;
    return gap + (leftPane?.offsetHeight ?? 0) * multiplier;
  };

  const resize = () => {
    if (window.innerWidth >= 600) return;
    const maxHeight = getChapterListMaxHeight();
    divText.style.setProperty("--menu-height", `${maxHeight}px`);
  };

  const MENU_OPTIONS: MenuOption[] = [
    {
      id: "ep1",
      image: "../assets/card-1.png",
      display: "Episode 1",
      color: "default",
      subtext: "Meet the team",
      subItems: [
        {
          id: "ch1",
          display: "CHAPTER 1",
          subtext: "Welcome to Headquarters",
          thumbnail: "../assets/thumbnails/e1-c1.jpg",
        },
        {
          id: "ch2",
          display: "CHAPTER 2",
          subtext: "Meet the Mind Worker",
          thumbnail: "../assets/thumbnails/e1-c2.jpg",
        },
        {
          id: "ch3",
          display: "CHAPTER 3",
          subtext: "Meet the emotions",
          thumbnail: "../assets/thumbnails/e1-c3.jpg",
        },
        {
          id: "ch4",
          display: "CHAPTER 4",
          subtext: "Anger's house of cards",
          thumbnail: "../assets/thumbnails/e1-c4.jpg",
        },
      ],
    },
    {
      id: "ep2",
      image: "../assets/card-2.jpg",
      display: "Episode 2",
      color: "red",
      subtext: "Anger in action",
      subItems: [
        {
          id: "ch1",
          display: "CHAPTER 1",
          subtext: "Riley loves hockey",
          thumbnail: "../assets/thumbnails/e2-c1.jpg",
        },
        {
          id: "ch2",
          display: "CHAPTER 2",
          subtext: "Riley's new team",
          thumbnail: "../assets/thumbnails/e2-c2.jpg",
        },
        {
          id: "ch3",
          display: "CHAPTER 3",
          subtext: "Anger's time to shine",
          thumbnail: "../assets/thumbnails/t-1.jpg",
        },
        {
          id: "ch4",
          display: "CHAPTER 4",
          subtext: "A moment of choice",
          thumbnail: "../assets/thumbnails/e2-c3.jpg",
        },
      ],
    },
    {
      id: "ep3",
      image: "../assets/card-4.jpg",
      display: "Episode 3",
      color: "blue",
      subtext: "Emotions and the body",
      subItems: [
        {
          id: "ch1",
          display: "CHAPTER 1",
          subtext: "Your body's language",
          thumbnail: "../assets/thumbnails/e3-c1.jpg",
        },
        {
          id: "ch2",
          display: "CHAPTER 2",
          subtext: "Disgust's time to shine",
          thumbnail: "../assets/thumbnails/e3-c2.jpg",
        },
        {
          id: "ch3",
          display: "CHAPTER 3",
          subtext: "Signals in the body",
          thumbnail: "../assets/thumbnails/e3-c3.jpg",
        },
        {
          id: "ch4",
          display: "CHAPTER 4",
          subtext: "Sadness's time to shine",
          thumbnail: "../assets/thumbnails/e3-c4.jpg",
        },
        {
          id: "ch5",
          display: "CHAPTER 5",
          subtext: "Spotting the change",
          thumbnail: "../assets/thumbnails/e3-c5.jpg",
        },
      ],
    },
    {
      id: "ep4",
      image: "../assets/card-7.jpg",
      display: "Episode 4",
      color: "yellow",
      subtext: "A powerful pause",
      subItems: [
        {
          id: "ch1",
          display: "CHAPTER 1",
          subtext: "Finding the moment",
          thumbnail: "../assets/thumbnails/e4-c1.jpg",
        },
        {
          id: "ch2",
          display: "CHAPTER 2",
          subtext: "Riley's goal",
          thumbnail: "../assets/thumbnails/e4-c2.jpg",
        },
        {
          id: "ch3",
          display: "CHAPTER 3",
          subtext: "Your unique emotions",
          thumbnail: "../assets/thumbnails/e4-c3.jpg",
        },
        {
          id: "ch4",
          display: "CHAPTER 4",
          subtext: "Fear's time to shine",
          thumbnail: "../assets/thumbnails/e4-c4.jpg",
        },
        {
          id: "ch5",
          display: "CHAPTER 5",
          subtext: "Riley takes charge",
          thumbnail: "../assets/thumbnails/e4-c5.jpg",
        },
        {
          id: "ch6",
          display: "CHAPTER 6",
          subtext: "The power of pausing",
          thumbnail: "../assets/thumbnails/t-2.jpg",
        },
        {
          id: "ch7",
          display: "CHAPTER 7",
          subtext: "Putting it all together",
          thumbnail: "../assets/thumbnails/t-3.jpg",
        },
      ],
    },
  ];

  const logo = document.querySelector(".splash-screen__logo");
  const copyright = document.querySelector(".copyright");
  const currentParam = localStorage.getItem("currentParam");
  const epParam = currentParam?.split("-")[0];
  const chParam = currentParam?.split("-")[1];

  logo?.classList.add("hide");
  copyright?.classList.add("hide");

  const shareModal = new Modal({
    id: "share-modal",
    className: ["share-modal"],
    title: "Share Episode",
  });

  const divText = document.createElement("div");
  divText.className = "menu-modal";

  const homeBtnContainer = document.createElement("div");
  homeBtnContainer.className = "home-btn-container";
  divText.appendChild(homeBtnContainer);

  //left pane
  const leftPane = document.createElement("div");
  leftPane.className = "left-pane";
  divText.appendChild(leftPane);

  const chapterList = document.createElement("div");
  chapterList.className = "chapter-list";
  divText.appendChild(chapterList);

  //share and close
  const closeBtn = document.createElement("button");
  closeBtn.className = "btn-v2 btn-close ghost";
  closeBtn.innerHTML = `<svg id="close-icon" class="btn-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
                          <path fill-rule="evenodd" clip-rule="evenodd" 
                              d="M25.7678 9.7678C26.7441 8.79149 26.7441 7.20857 25.7678 6.23226C24.7915 5.25595 23.2085 5.25595 22.2322 
                              6.23226L16 12.4645L9.76777 6.23226C8.79146 5.25595 7.20854 5.25595 6.23223 6.23226C5.25592 7.20857 5.25592 
                              8.79149 6.23223 9.7678L12.4645 16L6.23223 22.2323C5.25592 23.2086 5.25592 24.7915 6.23223 25.7678C7.20854 
                              26.7441 8.79146 26.7441 9.76777 25.7678L16 19.5356L22.2322 25.7678C23.2085 26.7441 24.7915 26.7441 25.7678 
                              25.7678C26.7441 24.7915 26.7441 23.2086 25.7678 22.2323L19.5355 16L25.7678 9.7678Z" fill="currentColor"/>
                        </svg>`;
  closeBtn.onclick = () => {
    overlay.className = "menu-overlay";

    const body = document.querySelector("body");
    body?.classList.add("enable-scroll");

    setTimeout(() => {
      logo?.classList.remove("hide");
      copyright?.classList.remove("hide");

      menuContainer.destroy();
      overlayElement.destroy();
      shareModal.destroy();
      menuButton.onclick = menuBtnFunction;
      scene.sound.resumeAll();
    }, 400);
  };

  homeBtnContainer.appendChild(closeBtn);

  //home button
  const homeBtn = document.createElement("button");
  homeBtn.id = "homeBtn";
  homeBtn.className = "btn-v2 ghost btn-home";
  homeBtn.innerHTML = `
    <svg id="home-icon" class="btn-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path d="M10 24C9.44772 24 9 23.5523 9 23V13.8286C9 13.5165 9.14571 13.2223 9.39396 13.0332L15.394 8.46175C15.752 8.18899 16.248 8.18899 16.606 8.46175L22.606 13.0332C22.8543 13.2223 23 13.5165 23 13.8286V23C23 23.5523 22.5523 24 22 24H18.875C18.3227 24 17.875 23.5523 17.875 23V19.6667C17.875 19.1144 17.4273 18.6667 16.875 18.6667H15.125C14.5727 18.6667 14.125 19.1144 14.125 19.6667V23C14.125 23.5523 13.6773 24 13.125 24H10Z" fill="white"/>
    </svg>`;
  homeBtn.onclick = () => {
    menuContainer.destroy();
    //element.destroy();
    overlayElement.destroy();
    shareModal.destroy();
    logo?.classList.remove("hide");
    copyright?.classList.remove("hide");

    const body = document.querySelector("body");
    body?.classList.add("enable-scroll");

    const svgShapeVar = document.getElementById("svgShape");
    svgShapeVar?.remove();

    const safeArea = document.getElementById("safe-area");
    safeArea?.style.removeProperty("background-color");

    sessionStorage.removeItem(SessionNames.EVENT_QUEUE);

    if (sceneExitFunction) {
      sceneExitFunction();
    }

    menuButton.onclick = menuBtnFunction;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const tabTitles = document.querySelectorAll(".tabs .tab-title");
    if (tabTitles.length) {
      const episodesTab = tabTitles[0] as HTMLElement;
      episodesTab?.click();

      const toggled = document.querySelectorAll(".toggled");
      toggled.length &&
        toggled.forEach((value) => {
          value.classList.remove("toggled");
        });
    }

    sceneFadeInFadeOut(scene, "HomeSceneNew");
  };
  homeBtnContainer.appendChild(homeBtn);

  //episode menu
  const divTab = document.createElement("div");
  divTab.className = "menu-tab";
  leftPane.appendChild(divTab);

  MENU_OPTIONS.forEach((option, index) => {
    const episodeBtn = document.createElement("button");
    episodeBtn.id = option.id + "Btn";
    episodeBtn.className = "tablinks";
    episodeBtn.innerHTML = `
      <div class="link-md">
        <span class="title">${option.display}</span>
        <span class="sub-title">${option.subtext}</span>
      </div>
      <div class="link-xs">
        <span class="title">EP${index + 1}</span>
      </div>
    `;

    if (epParam == option.id) {
      episodeBtn.className += " active";
    }

    episodeBtn.onclick = () => {
      var i, tabcontent, tablinks;

      // Get all elements with class="tabcontent" and hide them
      tabcontent = document.getElementsByClassName("menu-tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      // Get all elements with class="tablinks" and remove the class "active"
      tablinks = document.getElementsByClassName("tablinks");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      // Show the current tab, and add an "active" class to the link that opened the tab
      var episodeLink = document.getElementById(option.id + "Btn");
      if (episodeLink) {
        episodeLink.className += " active";
        episodeLink.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }

      const episodeTab = document.getElementById(option.id);
      if (episodeTab) {
        episodeTab.style.display = "flex";
      }
    };

    const episodeContent = document.createElement("div");
    episodeContent.id = option.id;
    episodeContent.className = "menu-tabcontent";
    episodeContent.style.display = epParam == option.id ? "flex" : "none";

    const epTopContent = document.createElement("div");
    epTopContent.className = "top-content";
    epTopContent.innerHTML = `<div class="title">` + option.display + `</div>`;

    //share
    const shareBtn = document.createElement("button");
    shareBtn.className = "btn-v2 btn-share";
    shareBtn.innerHTML = "Share";
    shareBtn.onclick = (e: MouseEvent) => {
      const ep = epParam ? epParam : "ep1";

      const currentEpisode = MENU_OPTIONS.find((menu) => {
        return menu.id === ep;
      });

      shareModal.setContent(`
      <div class="share-content">
          <div class="share-content__episode">
              <div class="share-content__img">
                  <img src="${currentEpisode?.image}" />
              </div>
              <div class="share-content__episode-details-container ${currentEpisode?.color}">
                  <div class="share-content__episode-details">
                    <div class="share-content__episode-number">${currentEpisode?.display}</div>
                    <div class="share-content__episode-title">${currentEpisode?.subtext}</div>
                  </div>
              </div>
          </div>
          <div class="share-content__cta">
            <div class="share-content__url" data-url="${window.location.href}">${window.location.href}</div>
            <button class="share-content__button">Copy</button>
          </div>
      </div>
    `);

      shareModal.show();
    };

    document.addEventListener("click", (event) => {
      const target = event.target as HTMLElement;

      if (target?.classList.contains("share-content__button")) {
        const urlElem = target.previousElementSibling as HTMLElement;
        const url = urlElem.getAttribute("data-url") ?? "";
        navigator.clipboard.writeText(url).then(() => {
          urlElem.innerHTML = "Link copied to clipboard!";
        });
      }
    });

    epTopContent.appendChild(shareBtn);

    episodeContent.appendChild(epTopContent);

    const epBottomContent = document.createElement("div");
    epBottomContent.className = "bottom-content";
    episodeContent.appendChild(epBottomContent);

    //sub items
    option.subItems?.forEach((items) => {
      const chapter = document.createElement("button");
      chapter.className =
        epParam == option.id && chParam == items.id
          ? "ch-container active"
          : "ch-container";

      chapter.onclick = () => {
        // clear url params before going to the chapter.
        const url = new URL(window.location.href);
        window.history.pushState(null, "", url.origin.toString());

        let currentScene = localStorage.getItem("currentParam");
        let chapter = option.id + "-" + items.id;
        let sceneToLoad = sceneFlow[chapter];

        if (sceneToLoad && currentScene != chapter) {
          scene.sound.stopAll();
          menuContainer.destroy();
          //setelement.destroy();
          overlayElement.destroy();
          shareModal.destroy();
          logo?.classList.remove("hide");
          copyright?.classList.remove("hide");

          if (sceneExitFunction) {
            sceneExitFunction();
          }

          if (sceneToLoad[0].SceneType === "AssetLoaderScene") {
            sceneFadeInFadeOut(scene, sceneToLoad[0].SceneType, {
              chapter: chapter,
              sceneToLoad: {
                sceneName: sceneToLoad[0].Data.SceneType,
                data: {
                  video: sceneToLoad[0].Data.Video,
                  nextScene: sceneToLoad[0].Data.NextScene,
                  nextVideo: sceneToLoad[0].Data.NextVideo,
                  json: sceneToLoad[0].Data.Json,
                  skipEnable: sceneToLoad[0].Data.SkipEnable ?? false,
                  fadeType: sceneToLoad[0].Data.FadeType,
                  endOfExperience: sceneToLoad[0].Data.EndOfExperience ?? false,
                },
              },
            });
          } else {
            sceneFadeInFadeOut(
              scene,
              sceneToLoad[0].SceneType,
              {
                video: sceneToLoad[0].Video,
                nextVideo: sceneToLoad[0].Data.NextVideo,
                nextScene: sceneToLoad[0].NextScene,
                json: sceneToLoad[0].Json,
                skipEnable: sceneToLoad[0].SkipEnable ?? false,
                endOfExperience: sceneToLoad[0].Data.EndOfExperience ?? false,
              },
              "FadeToBlack"
            );
          }
        }
      };

      const thumb = document.createElement("div");
      thumb.className = "thumb";
      const chapterThumb = document.createElement("img");
      chapterThumb.src = items.thumbnail != undefined ? items.thumbnail : "";
      chapterThumb.setAttribute("draggable", "false");
      chapterThumb.setAttribute("loading", "lazy");

      thumb.appendChild(chapterThumb);
      chapter.appendChild(thumb);

      const chDetails = document.createElement("div");
      chDetails.className = "ch-details";
      chDetails.innerHTML =
        `<span class="title">` +
        items.display +
        `</span>
                              <span class="sub-title">` +
        items.subtext +
        `</span>
                              <span class="status">NOW PLAYING</span>`;
      chapter.appendChild(chDetails);

      epBottomContent.appendChild(chapter);
    });
    chapterList.appendChild(episodeContent);

    divTab.appendChild(episodeBtn);
  });

  /*  const element = scene.add.dom(0, 0, divText);
  element.setDepth(500);
  element.setOrigin(0);*/

  const menuTabOverlay = document.createElement("div");
  menuTabOverlay.classList.add("menu-tab__overlay");

  leftPane.appendChild(menuTabOverlay);

  const overlay = document.createElement("div");
  overlay.className = "menu-overlay";
  overlay.appendChild(divText);

  const overlayElement = scene.add.dom(0, 0, overlay);
  overlayElement.setDepth(400);
  overlayElement.setOrigin(0);

  // menuContainer.add([backgroundFade]);

  scene.add.existing(menuContainer);

  resize();
  window.addEventListener("resize", () => resize());

  setTimeout(() => {
    // If no episode is selected
    // select episode 1 by default
    if (!document.querySelectorAll(".tablinks.active").length) {
      (document.querySelectorAll(".tablinks")[0] as HTMLElement).click();
    }

    overlay.className = "menu-overlay show";
  }, 100);

  menuButton.onclick = () => {};
};
