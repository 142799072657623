import CinematicScene from "./CinematicScene";

export default class RileyIntroCinematicScene extends CinematicScene {
  constructor() {
    super("RileyIntroCinematicScene");
  }

  init(url) {
    super.init(url);
  }

  preload() {
    super.preload();
  }

  create() {
    super.create();
  }
}
