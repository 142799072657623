import BaseScene from "./common/BaseScene";
import UIBlock from "~/lib/UIBlock";
import TextEngine from "../components/TextEngine/TextEngine";

export default class ConsoleEndScene extends BaseScene {
  textEngine!: TextEngine;
  json!: any;
  selectedResponse!: number;
  episode!: string;

  constructor() {
    super("ConsoleEndScene");
  }

  preload() {
    super.preload();
  }

  init(json) {
    this.json = json.data;
    this.selectedResponse = json.data.selectedResponse;
    this.episode = json.data.episode;
  }

  create() {
    this.backgroundUrlKey = `scenario_ending_${this.selectedResponse}`;
    super.create();

    const uiBlock = new UIBlock();

    if (this.selectedResponse) {
      let speechJSON =
        this.cache.json.get("consoleJSON")[`${this.episode}consoleEnd`];
      this.textEngine = new TextEngine(
        this,
        speechJSON,
        "scenario",
        this.selectedResponse
      );
    } else {
      let speechJSON = this.cache.json.get("scenarioJSON")[this.json];
      this.textEngine = new TextEngine(this, speechJSON, "scenario");
    }

    uiBlock.add(this.textEngine);
  }
}
