import scenes from "./scenes";

// https://photonstorm.github.io/phaser3-docs/Phaser.Types.Core.html#.GameConfig
const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.AUTO,
  scale: {
    parent: "phaser",
    mode: Phaser.Scale.NONE,
    width: window.innerWidth,
    height: window.innerHeight,
  },
  dom: { createContainer: true },
  scene: scenes,
  transparent: true,
  canvasStyle: "position: relative; z-index:1;",
};

export default config;
