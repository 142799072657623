import Phaser from "phaser";

const destroyHomepageContent = (domDiv, safeArea) => {
  let homeBg = document.querySelector(".home-background");
  let logo = document.querySelector(".splash-screen__logo");
  let contentContainer = document.querySelector(".content-container");
  let copyright = document.querySelector(".copyright");
  let crownLogo = document.querySelector(".crown-logo");
  let modal = document.getElementById("for-grown-ups");

  domDiv?.classList.remove("home-container");
  safeArea?.classList.remove("home-area");

  homeBg?.remove();
  logo?.remove();
  contentContainer?.remove();
  copyright?.remove();
  crownLogo?.remove();
  modal?.remove();
};

const disableScroll = () => {
  const body = document.querySelector("body");
  body?.classList.remove("enable-scroll", "home");
};

const bypassCinematicSceneKey = (sceneName: string, sceneKey: string) => {
  if (sceneName == sceneKey && sceneName === "CinematicScene")
    sceneName = "RileyIntroCinematicScene";
  else if (sceneName == sceneKey && sceneName === "RileyIntroCinematicScene")
    sceneName = "CinematicScene";

  return sceneName;
};

const screenTransitions = {
  FadeToBlack: (
    scene: Phaser.Scene,
    sceneName: string,
    data: string | object | null | undefined,
    phaserEl: HTMLElement,
    safeAreaEl: HTMLElement
  ) => {
    destroyHomepageContent(phaserEl, safeAreaEl);

    // fade to black
    scene.cameras.main.fadeOut(400, 0, 0, 0);

    // listener for the camera fade out complete
    scene.cameras.main.once(
      Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE,
      () => {
        disableScroll();
        scene.scene.stop();
        scene.cameras.main.fadeIn(500, 0, 0, 0);
        scene.scene.start(sceneName, { fadeIn: true, data: data });
      }
    );
  },
  HorizontalWipe: (
    scene: Phaser.Scene,
    sceneName: string,
    data: string | object | null | undefined,
    phaserEl: HTMLElement,
    safeAreaEl: HTMLElement
  ) => {
    sceneName = bypassCinematicSceneKey(sceneName, scene.scene.key);

    if (sceneName != scene.scene.key && phaserEl) {
      let newScene = scene.scene.launch(sceneName, { data: data });
      scene.scene.bringToTop();

      destroyHomepageContent(phaserEl, safeAreaEl);

      let domDivClone = phaserEl.cloneNode(true) as HTMLDivElement;

      phaserEl.innerHTML = "";

      newScene.scene.tweens.add({
        targets: scene.cameras.main,
        width: 0,
        duration: 800,
        ease: Phaser.Math.Easing.Expo.In,
        onUpdate: () => {
          domDivClone.style.clipPath = `inset(0px ${
            window.innerWidth -
            scene.cameras.main.width /
              scene.cameras.main.scaleManager.displayScale.x
          }px 0px 0px)`;
          phaserEl.style.clipPath = `inset(0px 0px 0px ${scene.cameras.main.width}px)`;
        },
        onComplete: () => {
          disableScroll();
          scene.scene.stop();
          scene.cameras.main.alpha = 0;
          domDivClone.remove();
        },
        callbackScope: this,
      });
    }
  },
  Dissolve: (
    scene: Phaser.Scene,
    sceneName: string,
    data: string | object | null | undefined,
    phaserEl: HTMLElement,
    safeAreaEl: HTMLElement
  ) => {
    sceneName = bypassCinematicSceneKey(sceneName, scene.scene.key);

    if (sceneName != scene.scene.key) {
      let newScene = scene.scene.launch(sceneName, { data: data });
      scene.scene.bringToTop();

      newScene.scene.tweens.add({
        targets: scene.cameras.main,
        alpha: 0,
        duration: 700,
        ease: Phaser.Math.Easing.Expo.InOut,
        onComplete: () => {
          disableScroll();
          scene.scene.stop();
          scene.scene.sendToBack();
        },
        callbackScope: this,
      });
    }
  },
  QuickDissolve: (
    scene: Phaser.Scene,
    sceneName: string,
    data: string | object | null | undefined,
    phaserEl: HTMLElement,
    safeAreaEl: HTMLElement
  ) => {
    sceneName = bypassCinematicSceneKey(sceneName, scene.scene.key);

    if (sceneName != scene.scene.key) {
      let newScene = scene.scene.launch(sceneName, { data: data });
      scene.scene.bringToTop();

      newScene.scene.tweens.add({
        targets: scene.cameras.main,
        alpha: 0,
        duration: 400,
        ease: Phaser.Math.Easing.Expo.InOut,
        onComplete: () => {
          disableScroll();
          scene.scene.stop();
          scene.scene.sendToBack();
        },
        callbackScope: this,
      });
    }
  },
  None: (
    scene: Phaser.Scene,
    sceneName: string,
    data: string | object | null | undefined,
    phaserEl: HTMLElement,
    safeAreaEl: HTMLElement
  ) => {
    sceneName = bypassCinematicSceneKey(sceneName, scene.scene.key);

    if (sceneName != scene.scene.key && phaserEl) {
      let newScene = scene.scene.launch(sceneName, { data: data });
      scene.scene.bringToTop();

      destroyHomepageContent(phaserEl, safeAreaEl);

      disableScroll();
      scene.scene.stop();
      scene.scene.sendToBack();
    }
  },
};

export const sceneFadeInFadeOut = (
  scene: Phaser.Scene,
  sceneName: string,
  data?: string | object | null | undefined,
  fadeType: string = "None"
) => {
  //fade out elements
  let domDiv = document.querySelector("#phaser > div") as HTMLDivElement;
  let safeArea = document.getElementById("safe-area");

  const body = document.querySelector("body");
  body?.classList.remove("loading");

  const transition = screenTransitions[fadeType];
  transition && transition(scene, sceneName, data, domDiv, safeArea);
};

export function loadAssets(
  scene: Phaser.Scene,
  sceneName: string,
  jsonLib: object[]
) {
  //retrive the json array for the scene
  let assetsToLoad;

  for (let i = 0; i < jsonLib.length; i++) {
    if (jsonLib[i][sceneName] != undefined) {
      assetsToLoad = jsonLib[i][sceneName];
      break;
    }
  }

  if (assetsToLoad != undefined) {
    assetsToLoad.forEach((asset) => {
      //load audio
      if (asset.AudioKey != undefined && asset.AudioPath != undefined) {
        if (!scene.cache.audio.exists(asset.AudioKey)) {
          scene.load.audio(asset.AudioKey, asset.AudioPath);
        }
      }

      //load frame
      loadImageOrFrame(scene, asset.Frame, asset.FramePath);

      //TODO load next video (add code if cinema scene preload is not enough)

      //reccurssive call
      let childAssets = asset.Json;
      if (childAssets != undefined) {
        loadAssets(scene, childAssets, jsonLib);
      }
    });
  }
  return !!assetsToLoad?.length;
}

export const resizeImage = (
  scene: Phaser.Scene,
  image: Phaser.GameObjects.Image,
  imageWidth: number,
  imageHeight: number,
  positionFn?: (image: Phaser.GameObjects.Image) => void
) => {
  if (image && scene) {
    const wallpaperAspect = imageWidth / imageHeight;
    const windowAspect = window.innerWidth / window.innerHeight;

    if (windowAspect > wallpaperAspect) {
      image.displayWidth = scene.scale.baseSize.width;
      image.displayHeight =
        scene.scale.baseSize.width * (imageHeight / imageWidth);
    } else {
      image.displayHeight = scene.scale.baseSize.height;
      image.displayWidth =
        scene.scale.baseSize.height * (imageWidth / imageHeight);
    }

    const fn =
      positionFn ??
      ((image: Phaser.GameObjects.Image) => {
        image.x = 0;
        image.y = 0;
        image.setOrigin(0);
      });

    fn(image);
  }
};

export const createCopyright = (scene: Phaser.Scene) => {
  const copyright = document.createElement("div");
  copyright.className = "copyright";
  copyright.innerHTML = `&copy;Disney/Pixar`;
  scene.add.dom(0, 0, copyright).setOrigin(1, 1);
};

export const loadSprite = (
  scene: Phaser.Scene,
  spriteKey: string,
  spriteMap: string
) => {
  if (!scene.textures.list[spriteKey]) {
    scene.load.multiatlas(spriteKey, spriteMap);
  }
};

export const loadImageOrFrame = (
  scene: Phaser.Scene,
  key: string,
  path: string
) => {
  if (key != undefined && path != undefined) {
    if (!scene.textures.exists(key)) {
      scene.load.image(key, path);
    }
  }
};

export const getSiblings = (n: HTMLElement) => {
  if (!n) return new NodeList();

  const parentNode = n?.parentNode;
  const childNodes = Array.from(parentNode?.childNodes ?? []);

  return childNodes.filter((node) => {
    return node !== n;
  });
};
