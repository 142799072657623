import Phaser from "phaser";
import jsonVisualiser from "../json/visualiserSpeech.json";
import UIBlock from "~/lib/UIBlock";
import BaseScene from "./common/BaseScene";
import TextEngine from "../components/TextEngine/TextEngine";
import { sceneFadeInFadeOut, loadAssets } from "~/lib/util";
import * as Tone from "tone";
import anime from "animejs/lib/anime.es.js";
import sceneFlow from "../json/sceneFlow.json";
import { SessionNames } from "~/config/enums";

const MinDragPosition = 610; //430 original
const MaxDragPosition = 760; //640 original
var seekValue;
var tempoDuration = 1000;
var handlerValue = 0;
let storedMuteStateString = localStorage.getItem("muteState");
let sliderPulse = "slider_activity1";

//topMostPosition: 430 bottomMostPosition: 700
let backgroundParam: number,
  shapeGroup1Param: number,
  shapeGroup2Param: number,
  colorGroup1Param: number,
  colorGroup2Param: number,
  tempoParam: number,
  lumiParam: number,
  backgroundLumiParam: number,
  bgc,
  sg1,
  sg2,
  cg1,
  cg2,
  tmpo,
  lum,
  blum,
  tn1,
  tn2,
  tn3,
  tn4;

// let toneFlag = new Tone.Player(
//   "../assets/console-audio/Kick.mp3"
// ).toDestination();
// toneFlag.loop = true;

//console.log("toneFlag", toneFlag);
let osc = new Tone.Oscillator({
  type: "square",
  frequency: 440,
  volume: -25,
}).toDestination();

interface Postion {
  x: number;
  y: number;
}

type TControlPanelVisualiserSceneData = {
  data: {
    json: {
      episode: string;
      jsonName: string;
      counter: number;
    };
  };
};

type TVisualiserQueryParams = {
  sg1: number;
  sg2: number;
  cg1: number;
  cg2: number;
  lum: number;
  bgc: number;
  blum: number;
  tmpo: number;
};

const defaultEpisode = "ep3";
const svgDefaults: { [key: string]: TVisualiserQueryParams } = {
  ep3: {
    sg1: 610,
    sg2: 610,
    cg1: 633,
    cg2: 687,
    lum: 713,
    bgc: 667,
    blum: 657,
    tmpo: 700,
  },
  ep4: {
    sg1: 698,
    sg2: 658,
    cg1: 664,
    cg2: 721,
    lum: 760,
    bgc: 657,
    blum: 760,
    tmpo: 654,
  },
};

export default class ControlPanelVisualiserScene extends BaseScene {
  consoleImage!: Phaser.GameObjects.Image;
  consoleSlider1!: Phaser.GameObjects.Image;
  consoleSlider2!: Phaser.GameObjects.Image;
  consoleSlider3!: Phaser.GameObjects.Image;
  consoleSlider4!: Phaser.GameObjects.Image;
  consoleSlider5!: Phaser.GameObjects.Image;
  consoleSlider6!: Phaser.GameObjects.Image;
  consoleSlider7!: Phaser.GameObjects.Image;
  consoleSlider8!: Phaser.GameObjects.Image;
  consoleButton1!: Phaser.GameObjects.Image;
  consoleButton2!: Phaser.GameObjects.Image;
  consoleButton3!: Phaser.GameObjects.Image;
  consoleButton4!: Phaser.GameObjects.Image;
  leftSliderPulse!: Phaser.GameObjects.Image;
  rightSliderPulse!: Phaser.GameObjects.Image;
  optionsContainer!: Phaser.GameObjects.Container;
  lightsTimer!: Phaser.Time.TimerEvent;
  sceneContainer!: Phaser.GameObjects.Container;
  textEngine!: TextEngine;
  currentStep!: number;
  finishButton!: Phaser.GameObjects.DOMElement;
  audioSequence!: Array<Phaser.Sound.BaseSound>;
  ovalBackground!: Phaser.GameObjects.Image;
  projector!: Phaser.GameObjects.Image;
  shape!: string;
  bluredShape1!: string;
  bluredShape2!: string;
  backgroundColorElement!: any;
  animateShapeLayerOne!: any;
  animateShapeLayerTwo!: any;
  animateShapeLayerThree!: any;
  animateBackgroundColor!: any;
  animateColorPaletteA!: any;
  animateColorPaletteB!: any;
  animateShape1Tempo!: any;
  animateShape2Tempo!: any;
  animateShape3Tempo!: any;
  animateShape4Tempo!: any;
  animateOpacity!: any;
  animateLuminosity!: any;
  animateBackgroundLuminosity!: any;
  private jsonName!: string;
  private episode!: string;
  private counter!: number;
  tone1!: Tone.Player;
  tone2!: Tone.Player;
  tone3!: Tone.Player;
  tone4!: Tone.Player;
  toneFlag1!: boolean;
  toneFlag2!: boolean;
  toneFlag3!: boolean;
  toneFlag4!: boolean;

  constructor(scene) {
    super("ControlPanelVisualiserScene");
  }

  init(data: TControlPanelVisualiserSceneData) {
    const d = data.data?.json ?? data.data;

    this.jsonName = d.jsonName;
    this.episode = d.episode ?? defaultEpisode;
    this.counter = d.counter ?? 1;

    const isEp4 = this.episode === "ep4";

    sg1 = new URL(window.location.href).searchParams.get("sg1");
    sg2 = new URL(window.location.href).searchParams.get("sg2");
    cg1 = new URL(window.location.href).searchParams.get("cg1");
    cg2 = new URL(window.location.href).searchParams.get("cg2");
    lum = new URL(window.location.href).searchParams.get("lum");
    bgc = new URL(window.location.href).searchParams.get("bgc");
    blum = new URL(window.location.href).searchParams.get("blum");
    tmpo = new URL(window.location.href).searchParams.get("tmpo");
    tn1 = new URL(window.location.href).searchParams.get("tn1");
    tn2 = new URL(window.location.href).searchParams.get("tn2");
    tn3 = new URL(window.location.href).searchParams.get("tn3");
    tn4 = new URL(window.location.href).searchParams.get("tn4");

    shapeGroup1Param = isEp4
      ? svgDefaults[this.episode].sg1
      : (sg1 && parseInt(sg1)) ?? svgDefaults[this.episode].sg1;
    shapeGroup2Param = isEp4
      ? svgDefaults[this.episode].sg2
      : (sg2 && parseInt(sg2)) ?? svgDefaults[this.episode].sg2;
    colorGroup1Param = isEp4
      ? svgDefaults[this.episode].cg1
      : (cg1 && parseInt(cg1)) ?? svgDefaults[this.episode].cg1;
    colorGroup2Param = isEp4
      ? svgDefaults[this.episode].cg2
      : (cg2 && parseInt(cg2)) ?? svgDefaults[this.episode].cg2;
    lumiParam = isEp4
      ? svgDefaults[this.episode].lum
      : (sg1 && parseInt(lum)) ?? svgDefaults[this.episode].lum;
    backgroundParam = isEp4
      ? svgDefaults[this.episode].bgc
      : (bgc && parseInt(bgc)) ?? svgDefaults[this.episode].bgc;
    backgroundLumiParam = isEp4
      ? svgDefaults[this.episode].blum
      : (blum && parseInt(blum)) ?? svgDefaults[this.episode].blum;
    tempoParam = isEp4
      ? svgDefaults[this.episode].tmpo
      : (tmpo && parseInt(tmpo)) ?? svgDefaults[this.episode].tmpo;

    this.toneFlag1 = tn1 ? tn1 == "1" : true; //default value is ON
    this.toneFlag2 = tn2 ? tn2 == "1" : false; //default value is OFF
    this.toneFlag3 = tn3 ? tn3 == "1" : false; //default value is OFF
    this.toneFlag4 = tn4 ? tn4 == "1" : false; //default value is OFF

    this.tone1 = new Tone.Player();
    this.tone2 = new Tone.Player();
    this.tone3 = new Tone.Player();
    this.tone4 = new Tone.Player();

    storedMuteStateString = localStorage.getItem("muteState"); //important to set this to check the state when the scene is restart
  }

  preload() {
    super.preload();

    this.load.path = "assets/";

    this.bluredShape1 = `<svg id="blured-shape1" class="emotion-blured-shape1" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 1700 1200" fill="none" >   
                        <g class = "pathval-B-1" filter="url(#filter0_f_1858_36)">
                          <path class = "pathval-B-L-1" style="transform: translate(-100px, -10px); transform-origin: 50% 50%; transform-box: fill-box;"
                            d="M885.58 586.523C885.58 752.209 936.378 900 813.654 900C690.93 900 547 752.209 547 586.523C547 420.838 690.93 300 813.654 300C936.378 300 885.58 420.838 885.58 586.523Z" fill="#F2F2F2"
                          />
                          <path class = "pathval-B-L-1" style="transform: translate(-330px, 10px); transform-origin: 50% 50%; transform-box: fill-box;"
                            d="M462 661C462 704.63 426.63 740 383 740C339.37 740 304 704.63 304 661C304 617.37 339.37 582 383 582C426.63 582 462 617.37 462 661Z" fill="#F2F2F2"
                          />
                        </g>  
                        <defs>
                          <filter id="filter0_f_1858_36" x="-300" y="0" width="1700" height="1200" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feGaussianBlur stdDeviation="80" result="effect1_foregroundBlur_1858_36"/>
                          </filter>
                        </defs>
                      </svg>`;

    this.bluredShape2 = `<svg id="blured-shape2" class="emotion-blured-shape2" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 1700 1200" fill="none" >                       
                      <g class = "pathval-B-2"  opacity="0.55" filter="url(#filter0_f_1858_23)">
                        <path class = "pathval-B-L-2" style="transform: translate(70px, -10px); transform-origin: 50% 50%; transform-box: fill-box;"
                          d="M1050 647.5C1050 720.678 990.678 780 917.5 780C844.322 780 785 720.678 785 647.5C785 574.322 844.322 515 917.5 515C990.678 515 1050 574.322 1050 647.5Z" fill="#E6E6E6"
                        />
                        <path class = "pathval-B-L-2" style="transform: translate(-190px, -10px); transform-origin: 50% 50%; transform-box: fill-box;"
                          d="M630 577.5C630 650.678 570.678 710 497.5 710C424.322 710 365 650.678 365 577.5C365 504.322 424.322 445 497.5 445C570.678 445 630 504.322 630 577.5Z" fill="#E6E6E6"
                        />
                      </g>
                      <defs>
                        <filter id="filter0_f_1858_23" x="-300" y="0" width="1700" height="1200" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                          <feGaussianBlur stdDeviation="75" result="effect1_foregroundBlur_1858_23"/>
                        </filter>
                      </defs>
   
                    </svg>`;

    this.shape = `<svg id="shape" class="emotion-shape" xmlns="http://www.w3.org/2000/svg" viewBox="-300 0 1700 1200" fill="none">   
                    
                    <path class = "pathval-L-1" opacity="0.8" style="transform: translate(200px, 200px); transform-origin: 50% 50%; transform-box: fill-box;"
                      d="M554.31 111.539C618.431 79.4792 659.248 102.625 655.5 171.152C653.789 202.44 638.719 235.04 622.047 271.105C604.552 308.951 585.293 350.612 577.855 398.578C575.502 413.745 590.075 437.729 607.307 466.088C644.143 526.71 693.129 607.328 614.924 664.577C546.894 714.379 496.419 647.194 458.139 596.241C437.507 568.78 420.418 546.033 406.033 548.859C391.246 551.764 367.12 574.268 339.335 600.186C295.076 641.47 241.532 691.415 201.65 684.614C137.028 673.593 93.9469 640.531 122 551.365C131.253 521.953 175.29 490.02 214.339 461.704C254.434 432.629 289.27 407.368 275.788 392.567C183.615 291.377 125.506 211.728 125.506 171.152C125.506 113.891 165.081 77.4352 218.681 90C246.288 96.4716 286.786 146.908 324.842 194.303C360.673 238.928 394.34 280.857 413.046 280.857C438.122 280.857 461.006 241.338 484.944 199.999C506.433 162.887 528.773 124.308 554.31 111.539Z" fill="#E6E6E6"
                    />                    
                    <path class = "pathval-L-2" opacity="0.6" style="transform: translate(200px, 200px); transform-origin: 50% 50%; transform-box: fill-box;"
                      d="M422.126 655.565C422.126 628.529 410.697 566.192 454.869 530.862C488.589 503.891 568.735 592.913 608.319 606.593C647.385 620.095 698.417 627.743 734.891 620.274C775.452 611.968 795 583.763 795 537.213C795 484.934 761.987 461.97 699.216 461.97C646.735 461.97 579.714 478.582 572.155 436.075C568.246 414.088 601.966 388.782 608.319 386.727C641.55 375.978 679.185 356.085 699.216 339.822C764.212 287.054 777.595 253.83 777.595 225.98C777.595 182.687 751.506 148.294 710.456 138.615C649.363 124.21 614.672 167.624 595.124 186.893C572.711 208.986 551.756 267.09 523.286 312.95C496.897 355.457 485.534 378.35 446.879 361.32C408.224 344.291 433.761 240.911 446.879 203.994C460.074 166.861 463.169 150.249 457.142 114.093C449.986 71.1693 424.08 38.308 389.213 22.1026C350.607 4.15886 308.09 24.1918 277.791 65.2339C252.946 98.8877 247.175 136.079 254.017 159.043C267.013 202.663 272.266 202.732 286.271 249.921C299.465 294.383 342.302 370.147 305.818 386.727C295.067 391.613 259.249 388.327 239.356 368.649C193.419 323.21 173.871 304.644 134.287 304.644C88.1835 304.644 41.2746 324.415 26.7735 356.434C18.3651 375.001 -4.28701 411.173 9.18 445.847C28.7259 496.172 88.2027 541.079 142.593 534.282C173.868 530.373 264.278 457.084 292.134 493.729C310.505 517.897 287.735 552.848 277.473 575.812C260.326 614.18 208.207 655.565 225.671 721.901C241.27 781.152 323.85 797.258 362.994 778.089C386.94 766.363 422.126 715.061 422.126 655.565Z" fill="#F2F2F2"
                    />
                    
                    <path class = "pathval-L-3" opacity="0.6" style="transform: translate(0px, 30px); transform-origin: 50% 50%; transform-box: fill-box;"
                      d="M504.435 587.076C492.822 588.891 475.039 577.278 479.031 555.503C480.73 546.235 486.652 538.445 499.717 536.268C506.613 535.119 525.893 536.51 538.55 541.349C550.889 546.067 565.031 561.571 575.204 566.39C588.995 572.923 597.705 556.262 597.705 525.38C597.705 505.42 610.176 491.532 618.754 488C624.924 485.46 637.989 484.008 651.417 492.355C662.304 499.123 664.588 516.67 659.764 527.195C655.772 535.905 649.602 544.252 644.884 551.147C641.58 555.977 636.9 567.842 636.9 574.374C636.9 577.64 641.864 591.136 675.732 571.471C686.982 564.938 704.04 563.124 722.185 583.447C734.525 597.267 731.621 605.948 724 622.642C718.514 634.659 704.266 637.88 694.604 640.062C683.353 642.602 673.192 636.433 667.748 631.352C662.278 626.247 645.55 606.555 632.182 615.021C621.295 621.916 639.078 652.038 640.166 658.934C641.026 664.377 655.046 686.153 648.15 702.847C643.953 713.008 639.073 728.252 617.302 731.88C606.415 733.695 593.55 720.495 590.81 716.638C581.011 702.847 582.825 682.16 588.632 669.821C592.389 661.837 600.81 648.287 593.35 625.908C589.721 615.021 575.325 617.844 568.309 629.537C565.042 634.981 551.977 658.208 547.622 665.829C541.816 675.991 521.855 701.395 496.451 688.33C488.83 684.411 483.629 677.449 482.66 668.732C481.209 655.667 487.741 645.506 498.629 634.618C504.072 629.175 522.944 615.747 539.275 611.029C545.445 609.246 554.881 597.601 544.719 589.617L537.098 587.076H504.435Z" fill="#E6E6E6"
                    />
                    <g class = "pathval-L-4" opacity="0.6" style="transform: translate(0px, 0px); transform-origin: 50% 50%; transform-box: fill-box;">
                      <path class = "pathval-L-4-d" d="M1058.22 294.69C1058.22 312.483 1043.79 326.907 1026 326.907C1008.21 326.907 993.783 312.483 993.783 294.69C993.783 276.897 1008.21 262.473 1026 262.473C1043.79 262.473 1058.22 276.897 1058.22 294.69Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M951.5 840.066C951.5 849.951 943.487 857.964 933.602 857.964C923.717 857.964 915.703 849.951 915.703 840.066C915.703 830.181 923.717 822.168 933.602 822.168C943.487 822.168 951.5 830.181 951.5 840.066Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M437.797 727.106C437.797 736.991 429.783 745.004 419.898 745.004C410.013 745.004 402 736.991 402 727.106C402 717.221 410.013 709.207 419.898 709.207C429.783 709.207 437.797 717.221 437.797 727.106Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M187.717 317.014C187.717 334.807 173.293 349.231 155.5 349.231C137.707 349.231 123.283 334.807 123.283 317.014C123.283 299.221 137.707 284.797 155.5 284.797C173.293 284.797 187.717 299.221 187.717 317.014Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M508.717 872.283C508.717 890.076 494.293 904.5 476.5 904.5C458.707 904.5 444.283 890.076 444.283 872.283C444.283 854.49 458.707 840.066 476.5 840.066C494.293 840.066 508.717 854.49 508.717 872.283Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M831.898 380.602C831.898 390.487 823.885 398.5 814 398.5C804.115 398.5 796.102 390.487 796.102 380.602C796.102 370.717 804.115 362.703 814 362.703C823.885 362.703 831.898 370.717 831.898 380.602Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M1073.66 588C1073.66 605.793 1059.23 620.217 1041.44 620.217C1023.65 620.217 1009.22 605.793 1009.22 588C1009.22 570.207 1023.65 555.783 1041.44 555.783C1059.23 555.783 1073.66 570.207 1073.66 588Z" fill="#E6E6E6"/>
                      <path class = "pathval-L-4-d" d="M368.898 344.805C368.898 354.69 360.885 362.703 351 362.703C341.115 362.703 333.102 354.69 333.102 344.805C333.102 334.92 341.115 326.907 351 326.907C360.885 326.907 368.898 334.92 368.898 344.805Z" fill="#E6E6E6"/>                      
                    </g>
                    <path class = "pathval-L-4 pathval-L-5" opacity="0.4" style="transform: translate(0px, 30px); transform-origin: 50% 50%; transform-box: fill-box;" 
                      d="M632 600.217C632 618.01 617.576 632.434 599.783 632.434C581.99 632.434 567.566 618.01 567.566 600.217C567.566 582.424 581.99 568 599.783 568C617.576 568 632 582.424 632 600.217Z" fill="#F2F2F2"
                    />
                  </svg>`;

    // this.load.multiatlas("handle", "console-handle.json");
    // this.load.multiatlas("groove", "console-groove.json");
    // this.load.multiatlas("go-button", "console-button.json");
    // this.load.multiatlas("backToConsole", "backToConsole.json");

    this.load.json("jsonVisualiser", jsonVisualiser);
    loadAssets(this, this.jsonName, [jsonVisualiser]);
    loadAssets(this, `${this.episode}end${this.counter === 2 ? 2 : ""}`, [
      jsonVisualiser,
    ]);

    //update url at the start of a cinematic scene
    let deepLink = sceneFlow["deep-links"][0][this.jsonName];
    if (deepLink) {
      const url = new URL(window.location.href);
      url.searchParams.set("ch", deepLink);
      localStorage.setItem("currentParam", deepLink);
      window.history.pushState(null, "", url.toString());
    }
  }

  create() {
    super.create();
    this.currentStep = 1;
    this.sceneContainer = new Phaser.GameObjects.Container(this);
    this.audioSequence = new Array();

    this.consoleImage = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX,
      370,
      "console-2"
    )
      .setOrigin(0.5, 0)
      .setScale(this.GetScale(1));

    this.ovalBackground = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX,
      0,
      "oval-background"
    )
      .setOrigin(0.5, 0)
      .setScale(this.GetScale(1));

    this.projector = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 10,
      420,
      "projector"
    )
      .setOrigin(0.5, 0)
      .setScale(this.GetScale(1.5));

    //check if svgShape already exist and remove
    if (document.getElementById("svgShape")) {
      document.getElementById("svgShape")?.remove();
      document.getElementById("backgroundColorDisplay")?.remove();
    }

    this.backgroundColorElement = document.createElement("div");
    this.backgroundColorElement.setAttribute("id", "backgroundColorDisplay");
    this.backgroundColorElement.setAttribute("class", "bgcDisplay");
    this.backgroundColorElement.style.backgroundColor = "#F2F2F2";
    this.backgroundColorElement.style.filter = "brightness(0.3)";
    document.getElementById("phaser")?.appendChild(this.backgroundColorElement);

    let shapeDisplay = document.createElement("div");
    shapeDisplay.setAttribute("id", "svgShape");
    shapeDisplay.setAttribute("class", "svgContainer");
    shapeDisplay.style.filter = "brightness(0.3)";
    shapeDisplay.innerHTML = this.bluredShape1 + this.bluredShape2 + this.shape;
    document.getElementById("phaser")?.appendChild(shapeDisplay);

    this.animateShape1Tempo = anime({
      targets: [".emotion-shape .pathval-L-1"],
      scale: [0.9, 1.1],
      easing: "easeInOutSine",
      direction: "alternate",
      duration: tempoDuration,
      loop: true,
    });

    this.animateShape2Tempo = anime({
      targets: [".emotion-shape .pathval-L-2"],
      scale: [0.9, 1.1],
      easing: "easeInOutSine",
      direction: "alternate",
      delay: 100,
      duration: tempoDuration,
      loop: true,
    });

    this.animateShape3Tempo = anime({
      targets: [".emotion-shape .pathval-L-3"],
      scale: [0.9, 1.1],
      easing: "easeInOutSine",
      direction: "alternate",
      delay: 200,
      duration: tempoDuration,
      loop: true,
    });

    this.animateShape4Tempo = anime({
      targets: [".emotion-shape .pathval-L-4, .emotion-shape .pathval-L-5"],
      scale: [0.9, 1.1],
      easing: "easeInOutSine",
      direction: "alternate",
      delay: 200,
      duration: tempoDuration,
      loop: true,
    });

    this.animateOpacity = anime({
      targets: [".emotion-shape .pathval-L-4"],
      opacity: [1, 0.7],
      easing: "easeInOutSine",
      direction: "alternate",
      duration: tempoDuration,
      loop: true,
    });

    //background luminosity/brightness
    this.animateLuminosity = anime({
      targets: [".svgContainer"],
      filter: [
        {
          value: `brightness(0.3)`,
        },
        {
          value: `brightness(0.4)`,
        },
        {
          value: `brightness(0.5)`,
        },
        {
          value: `brightness(0.6)`,
        },
        {
          value: `brightness(0.7)`,
        },
        {
          value: `brightness(0.8)`,
        },
        {
          value: `brightness(0.9)`,
        },
        {
          value: `brightness(1)`,
        },
      ],
      easing: "easeInOutSine",
      direction: "alternate",
      duration: tempoDuration,
      autoplay: false,
    });

    //set luminosity/brightness value for layers 2, 3, and background shapes 2
    this.animateLuminosity.seek(
      this.animateLuminosity.duration *
        ((lumiParam - MinDragPosition) / (MaxDragPosition - MinDragPosition))
    );

    //background luminosity/brightness
    this.animateBackgroundLuminosity = anime({
      targets: [".bgcDisplay"],
      filter: [
        {
          value: `brightness(0.3)`,
        },
        {
          value: `brightness(0.4)`,
        },
        {
          value: `brightness(0.5)`,
        },
        {
          value: `brightness(0.6)`,
        },
        {
          value: `brightness(0.7)`,
        },
        {
          value: `brightness(0.8)`,
        },
        {
          value: `brightness(0.9)`,
        },
        {
          value: `brightness(1)`,
        },
      ],
      easing: "easeInOutSine",
      direction: "alternate",
      duration: tempoDuration,
      autoplay: false,
    });

    //set background luminosity/brightness value
    this.animateBackgroundLuminosity.seek(
      this.animateBackgroundLuminosity.duration *
        ((backgroundLumiParam - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    //set tempo animation value
    var computeParam =
      tempoParam == MinDragPosition
        ? 200
        : tempoDuration *
          ((tempoParam - MinDragPosition) /
            (MaxDragPosition - MinDragPosition));
    this.animateShape1Tempo.duration = computeParam;
    this.animateShape2Tempo.duration = computeParam;
    this.animateShape3Tempo.duration = computeParam;
    this.animateShape4Tempo.duration = computeParam;

    this.tone1 = new Tone.Player(
      "../assets/console-audio/CA_V1_L1.mp3"
    ).toDestination();
    this.tone1.loop = true;
    this.tone1.mute = storedMuteStateString == "true" ? true : !this.toneFlag1;

    this.tone2 = new Tone.Player(
      "../assets/console-audio/CA_V1_L2.mp3"
    ).toDestination();
    this.tone2.loop = true;
    this.tone2.mute = storedMuteStateString == "true" ? true : !this.toneFlag2;

    this.tone3 = new Tone.Player(
      "../assets/console-audio/CA_V1_L3.mp3"
    ).toDestination();
    this.tone3.loop = true;
    this.tone3.mute = storedMuteStateString == "true" ? true : !this.toneFlag3;

    this.tone4 = new Tone.Player(
      "../assets/console-audio/CA_V1_L4.mp3"
    ).toDestination();
    this.tone4.loop = true;
    this.tone4.mute = storedMuteStateString == "true" ? true : !this.toneFlag4;

    this.AdjustPLaybackRate(this.tone1);
    this.AdjustPLaybackRate(this.tone2);
    this.AdjustPLaybackRate(this.tone3);
    this.AdjustPLaybackRate(this.tone4);

    this.animateShapeLayerOne = anime({
      targets: ".emotion-shape .pathval-L-1",
      d: [
        //shape1
        {
          value: `M554.31 111.539C618.431 79.4792 659.248 102.625 655.5 171.152C653.789 202.44 638.719 235.04 622.047 271.105C604.552 308.951 585.293 350.612 577.855 398.578C575.502 413.745 590.075 437.729 607.307 466.088C644.143 526.71 693.129 607.328 614.924 664.577C546.894 714.379 496.419 647.194 458.139 596.241C437.507 568.78 420.418 546.033 406.033 548.859C391.246 551.764 367.12 574.268 339.335 600.186C295.076 641.47 241.532 691.415 201.65 684.614C137.028 673.593 93.9469 640.531 122 551.365C131.253 521.953 175.29 490.02 214.339 461.704C254.434 432.629 289.27 407.368 275.788 392.567C183.615 291.377 125.506 211.728 125.506 171.152C125.506 113.891 165.081 77.4352 218.681 90C246.288 96.4716 286.786 146.908 324.842 194.303C360.673 238.928 394.34 280.857 413.046 280.857C438.122 280.857 461.006 241.338 484.944 199.999C506.433 162.887 528.773 124.308 554.31 111.539Z`,
        },
        //shape2
        {
          value: `M482.5 83C483 97 487 171.5 489.5 198.5C492.389 229.701 506 289.5 513.5 322C518.808 345 531.716 395.216 560 423.5C570.5 434 604.5 463.5 630 487.5C681.655 536.116 707 554 735 578.5C684 565 583.579 541.583 571.5 539.5C557 537 456 514 420.5 531C385.662 547.683 352.5 571.5 322.5 597.5C300 617 241 668.5 217.5 690C228.5 654 239.747 614.912 249 585.5C258.253 556.088 270.5 508 279 472.5C282.711 457 280.242 427.373 257 403C164.5 306 39 225 16.5 194C43.5 205 97.5 227.5 142.5 245.5C168.828 256.031 205.627 269.258 242.5 279.5C287.5 292 362 314.5 391 274.5C404.618 255.717 418.302 238.552 439 195.5C451.5 169.5 475.5 101.5 482.5 83Z`,
        },
        //shape3
        {
          value: `M670.5 88C725 114.5 711.5 194 708 223.5C704.308 254.616 692 308.5 681.5 348.5C675.507 371.331 657.5 394.415 657.5 447.5C657.5 463.5 681.5 524 694.5 555C708 587.192 730 639.5 703 680.5C673.984 724.561 628.548 755 563 755C514 755 465.5 693 433.5 678.5C398.317 662.558 375.868 668.994 329 704.5C296 729.5 223.5 755.5 171.5 750.5C117.5 745.308 82 678.5 82 643.5C82 608.5 90 560 136.5 515.5C154.5 498.274 218.69 436.73 204.5 400.5C181 340.5 136 287.5 113.5 247C99.3412 221.514 92.78 163.5 113.5 126.5C129.18 98.5 154 46 215 48.5C261.665 50.4125 337.709 89.2579 383 109C419.5 124.91 465 113.5 521 94.5C548.319 85.231 625.64 66.1872 670.5 88Z`,
        },
        //shape4
        {
          value: `M657.5 48C683.5 60.6422 617.223 183.315 609.5 212C595.5 264 593.198 324.588 602 373C606 395 634.5 465.5 644.5 481.5C657.201 501.821 715 612.5 728 643.5C741.5 675.692 802.304 752.926 770 776C756 786 708.124 762.638 688.5 748.5C642 715 581.503 675.314 531 660.5C418.5 627.5 326.5 612 224 648.5C184.999 662.388 123.5 686 88.5 695C61 702.071 43.7143 679 65.5 648.5C87.2857 618 127.696 574.415 141.5 553.5C158 528.5 213.5 422.5 213.5 382.5C213.5 342.5 185 263.5 162.5 223C148.341 197.514 139.26 178.686 113.5 145C87.5 111 18 14 79 16.5C108 17.6885 224 138 258.5 164.5C290.077 188.755 359.14 228.616 463 189.5C501.5 175 612.64 26.1872 657.5 48Z`,
        },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 10000,
      autoplay: false,
      loop: true,
    });
    //set shape group 1 animation value
    this.animateShapeLayerOne.seek(
      this.animateShapeLayerOne.duration *
        ((shapeGroup1Param - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    this.animateShapeLayerTwo = anime({
      targets: ".emotion-shape .pathval-L-2",
      d: [
        //shape1
        {
          value: `M422.126 655.565C422.126 628.529 410.697 566.192 454.869 530.862C488.589 503.891 568.735 592.913 608.319 606.593C647.385 620.095 698.417 627.743 734.891 620.274C775.452 611.968 795 583.763 795 537.213C795 484.934 761.987 461.97 699.216 461.97C646.735 461.97 579.714 478.582 572.155 436.075C568.246 414.088 601.966 388.782 608.319 386.727C641.55 375.978 679.185 356.085 699.216 339.822C764.212 287.054 777.595 253.83 777.595 225.98C777.595 182.687 751.506 148.294 710.456 138.615C649.363 124.21 614.672 167.624 595.124 186.893C572.711 208.986 551.756 267.09 523.286 312.95C496.897 355.457 485.534 378.35 446.879 361.32C408.224 344.291 433.761 240.911 446.879 203.994C460.074 166.861 463.169 150.249 457.142 114.093C449.986 71.1693 424.08 38.308 389.213 22.1026C350.607 4.15886 308.09 24.1918 277.791 65.2339C252.946 98.8877 247.175 136.079 254.017 159.043C267.013 202.663 272.266 202.732 286.271 249.921C299.465 294.383 342.302 370.147 305.818 386.727C295.067 391.613 259.249 388.327 239.356 368.649C193.419 323.21 173.871 304.644 134.287 304.644C88.1835 304.644 41.2746 324.415 26.7735 356.434C18.3651 375.001 -4.28701 411.173 9.18 445.847C28.7259 496.172 88.2027 541.079 142.593 534.282C173.868 530.373 264.278 457.084 292.134 493.729C310.505 517.897 287.735 552.848 277.473 575.812C260.326 614.18 208.207 655.565 225.671 721.901C241.27 781.152 323.85 797.258 362.994 778.089C386.94 766.363 422.126 715.061 422.126 655.565Z`,
        },
        //shape2
        {
          value: `M407.5 494C419 494 436.365 495.371 461 512C481 525.5 524 559 557.5 587C592.028 615.859 638.5 654.5 674.5 680.5C648 650 615 613 595 592.5C568.634 565.474 502.194 494.428 494.5 474C480 435.5 481.667 423.348 509 398.5C525.5 383.5 601.777 332.159 607 328C634 306.5 667.969 282.263 688 266C747.5 217.694 773.5 196.5 794 178.5C748.5 204.5 673.367 259.067 645 279C626.5 292 595.291 313.801 570 328C541.5 344 519 354.5 504 361.32C483 370.868 450.351 353.009 443.5 344C415.121 306.68 392 256 383.5 228.5C377.942 210.518 361.741 166.5 354.5 144.5C341.5 105 327 61 316 33.5C321 59 327.5 89 335.5 127.5C344.04 168.598 352 202 357 226C366.087 269.617 380.057 311 370 333C362 350.5 336.5 357.372 322.5 354.5C310.931 352.127 287.5 337.5 262.5 320.5C209.069 284.167 163.381 256.268 131 233.5C99 211 28.5 159 6 147.5C18.5 162.5 113.164 242.786 143 265C175.907 289.5 265.468 349 305.5 385.5C322.5 401 331.5 442.5 313 464C294.5 485.5 247.5 526 223.5 543C202.088 558.167 179 574 159 589.5C186.5 580.5 255.745 543.32 291 530.5C318.5 520.5 374.5 494 407.5 494Z`,
        },
        //shape3
        {
          value: `M468.999 756.642C515.528 765.142 546.548 698.642 557.054 678.642C567.561 658.642 588.574 616.142 604.584 593.642C620.594 571.142 658.618 540.142 670.125 530.642C681.632 521.142 717.154 498.642 720.656 474.142C724.159 449.642 711.651 433.142 709.65 429.142C707.648 425.142 691.138 395.142 683.133 381.642C675.128 368.142 659.618 336.642 653.615 318.642C647.611 300.642 644.109 283.142 644.109 268.642C644.109 254.142 644.109 209.142 644.109 191.642C644.109 172.642 639.747 148.751 635.603 132.142C631.611 116.142 612.386 95.895 594.578 84.6425C585.082 78.6424 577.577 73.5273 553.052 71.6427C540.044 70.6432 503.02 75.1445 490.513 77.1438C478.005 79.1432 452.489 85.2034 440.481 86.6436C428.474 88.0839 402.458 89.6431 388.449 89.6431C374.44 89.6431 360.442 89.6431 348.434 88.6424C336.347 87.635 297.903 82.6431 284.384 82.6431C271.876 82.6431 239.366 85.9076 219.844 89.6431C181.83 96.9168 181.329 126.142 177.818 141.142C171.029 170.142 165.82 187.142 162.318 201.642C159.943 211.471 155.563 231.642 154.813 238.642C154.063 245.642 147.808 284.642 147.808 292.142C147.808 299.642 147.809 348.642 144.807 361.642C141.805 374.642 131.681 402.583 125.284 425.642C120.291 443.642 114.287 461.142 111.776 488.642C109.484 513.734 111.776 521.642 132.289 556.142C152.802 590.642 177.827 626.519 222.355 631.142C260.879 635.142 269.885 633.142 291.899 638.142C313.913 643.142 345.422 681.142 357.43 689.142C369.437 697.142 422.47 748.142 468.999 756.642Z`,
        },
        //shape4
        {
          value: `M344.5 720C364.996 796.869 442.217 773.376 442.44 715.5C443.03 562.889 538.407 741.77 579.44 720C620.474 698.23 585 633.258 604.44 600.5C623.881 567.742 700.94 668.844 756.135 615.696C792.026 581.136 717.732 541.923 714.751 502.746C711.771 463.569 784.873 491.408 786.94 437.015C789.008 382.621 736.577 428.277 709.367 393.383C682.156 358.489 769 354.77 763.235 305.27C757.47 255.77 687.476 309.721 666.635 270.625C645.794 231.529 734.481 231.714 710.042 175.517C685.603 119.32 622.121 178.85 595.249 161.105C568.377 143.359 621.79 91.5697 572.01 67.6355C522.23 43.7013 518.806 136.933 479.257 119.506C439.708 102.079 478.798 32.5845 438.991 37.3622C399.184 42.1398 420.661 106.599 376.389 111.397C332.116 116.195 345.026 30.4195 305.686 30.5001C266.347 30.5806 294.301 128.984 253.355 141.462C212.41 153.939 211.931 41.065 165.707 46.7847C119.483 52.5044 154.289 109.176 137.295 156.595C120.3 204.013 77.2826 147.64 61.813 186.281C46.3433 224.922 97.4724 237.178 98.1561 265.801C98.8399 294.425 17.2879 293.556 25.1335 320.288C32.9792 347.021 74.7645 361.601 73.2324 397.538C71.7004 433.475 10.3196 431.732 14.1742 469.601C18.0288 507.47 115.992 476.117 121.907 508.238C127.821 540.359 80.8929 534.475 77.3685 583.886C73.8442 633.298 159.388 580.058 183.496 591.947C207.603 603.837 152.328 630.3 171.169 684.013C186.955 729.015 237.44 721.581 263.806 685.614C279.282 664.503 325.137 647.38 344.5 720Z`,
        },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 10000,
      autoplay: false,
      loop: true,
    });
    //set shape group 2 animation value
    this.animateShapeLayerTwo.seek(
      this.animateShapeLayerTwo.duration *
        ((shapeGroup1Param - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    this.animateShapeLayerThree = anime({
      targets: ".emotion-shape .pathval-L-3",
      d: [
        //shape1
        {
          value: `M504.435 587.076C492.822 588.891 475.039 577.278 479.031 555.503C480.73 546.235 486.652 538.445 499.717 536.268C506.613 535.119 525.893 536.51 538.55 541.349C550.889 546.067 565.031 561.571 575.204 566.39C588.995 572.923 597.705 556.262 597.705 525.38C597.705 505.42 610.176 491.532 618.754 488C624.924 485.46 637.989 484.008 651.417 492.355C662.304 499.123 664.588 516.67 659.764 527.195C655.772 535.905 649.602 544.252 644.884 551.147C641.58 555.977 636.9 567.842 636.9 574.374C636.9 577.64 641.864 591.136 675.732 571.471C686.982 564.938 704.04 563.124 722.185 583.447C734.525 597.267 731.621 605.948 724 622.642C718.514 634.659 704.266 637.88 694.604 640.062C683.353 642.602 673.192 636.433 667.748 631.352C662.278 626.247 645.55 606.555 632.182 615.021C621.295 621.916 639.078 652.038 640.166 658.934C641.026 664.377 655.046 686.153 648.15 702.847C643.953 713.008 639.073 728.252 617.302 731.88C606.415 733.695 593.55 720.495 590.81 716.638C581.011 702.847 582.825 682.16 588.632 669.821C592.389 661.837 600.81 648.287 593.35 625.908C589.721 615.021 575.325 617.844 568.309 629.537C565.042 634.981 551.977 658.208 547.622 665.829C541.816 675.991 521.855 701.395 496.451 688.33C488.83 684.411 483.629 677.449 482.66 668.732C481.209 655.667 487.741 645.506 498.629 634.618C504.072 629.175 522.944 615.747 539.275 611.029C545.445 609.246 554.881 597.601 544.719 589.617L537.098 587.076H504.435Z`,
        },
        //shape2
        {
          value: `M369.5 413C377 405.333 394.9 383.3 406.5 356.5C421 323 438 298 451.5 286C465 274 500.5 235.5 525 230.5C549.5 225.5 578 224 618 260.5C632.942 274.135 647.326 286.862 660.5 297.77C682.593 316.064 701.284 329.241 713.5 332.999C723.181 335.978 737.421 342.9 752 350.584C766.787 358.379 781.922 366.957 793 372.999C804.726 379.395 823.269 391.046 840 404.167C854.66 415.665 867.929 428.291 874 439.499C887 463.499 896 486.499 885 522.499C874 558.499 868 576.999 862 611.999C856 646.999 846 662.499 840 682.999C834 703.499 828.5 759.499 827 772.499C825.5 785.499 822.972 824.499 817 854.499C811.586 881.696 810 906.999 783.5 920.499C757 933.999 729.5 949.499 704 934.999C678.5 920.499 655.5 906.499 626.5 901.499C597.5 896.499 563.5 889.499 539.5 895.499C515.5 901.499 510.5 904.499 474.5 912.499C438.5 920.499 431 929.499 419 912.499C407 895.499 401.5 898.999 393.5 867.499C385.5 835.999 390 837.499 375.5 804.999C361 772.499 357.5 739.499 337 717.999C316.5 696.499 309.5 685.499 286.5 659.999C263.5 634.499 250.5 608.999 245.5 593.499C240.5 577.999 237 555.499 255 529.999C273 504.499 282 496.499 309.5 467.999C331.5 445.199 346.667 432.833 351.5 429.499L369.5 413Z`,
        },
        //shape3
        {
          value: `M435.999 471.5C445.999 473.499 457.998 482.001 461.999 477.501C471.332 467.006 458.499 426.005 467.501 424.5C484.499 421.657 488.319 452.819 506.001 470.5C512.001 476.5 524.116 498 528.501 502.001C536.501 509.301 547.9 528 569.001 528C589.501 528 620.101 506.43 630.501 499.001C641.001 491.5 654.422 473.816 669.001 481.5C683.788 489.294 669.181 510.67 666.501 523.001C664.001 534.5 663.673 550.697 680.501 555.501C684 556.5 700.5 559 708.654 559.501C729.994 560.812 758.31 559.493 756.001 569.5C754.039 578 722.345 587.213 712.501 591C706.001 593.5 689.659 602.5 686.001 615.001C681.464 630.5 712.001 673.993 712.001 693C712.001 699 706.501 714 698.001 714C684.001 714 656.001 696.5 630.501 685.626C609.501 676.671 607.326 686.524 599.001 693C590.001 700 578.501 716.5 572.001 723.001C564.503 730.5 539.961 763.606 528.001 758C512.001 750.5 544.465 710.071 548.001 703C551.001 697 560.671 681.709 545.501 683C534.667 683.333 496.834 674.5 495.001 669C491.001 657 509.485 638.532 514.001 629C518.501 619.5 529.501 602 521.501 586.501C513.501 571.002 487.158 552 484.001 548.5C474.53 538 450.501 519 446.001 515C440.001 509.667 420.001 492 416 489.5C403.406 481.63 376.838 467.224 373.5 463.501C360.5 449 380.999 445.5 391 451L435.999 471.5Z`,
        },
        //shape4
        {
          value: `M459 487.5C466 492 547.5 557.5 551.999 555.001C555.51 553.051 469.5 461.5 468.5 454.5C474 459 477.319 461.819 495 479.5C501 485.5 522.803 505.803 527 510C535 518 567.5 551.326 583 552C612 553.261 633.05 526.5 638 521C647 511 657.5 498.501 671 482C666.5 491.5 649 525 642.5 536C636.218 546.632 613 583 633.5 584.5C637.13 584.766 675.38 580.402 683.5 579.5C706 577 740.5 575 758.5 573.5C750 576.5 718 585 707 587.5C700.209 589.043 638.658 601.499 634.999 614C630.463 629.499 698 689 709.5 703C694 692.5 674.001 677 668 672.5C656.8 664.1 630.999 644.241 620.499 637C610 629.759 592.774 634.873 584.999 642C579 647.5 555.5 675 549.499 683C543.136 691.483 533.5 701 523.5 713C534.5 693.5 552.5 668 556.999 661.5C560.818 655.984 587.671 626.209 572.5 627.5C561.667 627.833 500.499 656.5 491.999 660C505.999 650 524.751 637.581 534.499 630C543.5 623 566.499 603 563.499 590.5C560.5 578 522.501 549 519 546C508.263 536.799 479 513 474.5 509C468.5 503.667 453.15 490.82 449 487.5C439 479.5 421.486 464.518 416 460C407.5 453 377 427.5 373.5 424L459 487.5Z`,
        },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 10000,
      autoplay: false,
      loop: true,
    });
    //set shape group 1 animation value
    this.animateShapeLayerThree.seek(
      this.animateShapeLayerThree.duration *
        ((shapeGroup2Param - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    this.animateColorPaletteA = anime({
      targets:
        ".emotion-shape .pathval-L-2, .emotion-shape .pathval-L-5, .emotion-blured-shape1 .pathval-B-L-1",
      fill: [
        { value: "#808080" },
        { value: "#BF8440" },
        { value: "#FF8A00" },
        { value: "#BFB240" },
        { value: "#FFE600" },
        { value: "#97BF40" },
        { value: "#AFFF00" },
        { value: "#408FBF" },
        { value: "#009EFF" },
        { value: "#BF40B2" },
        { value: "#FF00E4" },
        { value: "#BF5240" },
        { value: "#FF2500" },
        { value: "#808080" },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 20000,
      autoplay: false,
      loop: true,
    });
    //set color group 1 animation value
    this.animateColorPaletteA.seek(
      this.animateColorPaletteA.duration *
        ((colorGroup1Param - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    this.animateColorPaletteB = anime({
      targets:
        ".emotion-shape .pathval-L-1, .emotion-shape .pathval-L-3, .emotion-shape .pathval-L-4-d, .emotion-blured-shape2 .pathval-B-L-2",
      fill: [
        { value: "#808080" },
        { value: "#BF8440" },
        { value: "#FF8A00" },
        { value: "#BFB240" },
        { value: "#FFE600" },
        { value: "#97BF40" },
        { value: "#AFFF00" },
        { value: "#408FBF" },
        { value: "#009EFF" },
        { value: "#BF40B2" },
        { value: "#FF00E4" },
        { value: "#BF5240" },
        { value: "#FF2500" },
        { value: "#808080" },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 20000,
      autoplay: false,
      loop: true,
    });
    //set color group 2 animation value
    this.animateColorPaletteB.seek(
      this.animateColorPaletteB.duration *
        ((colorGroup2Param - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    // this.safeAreaElement = document.getElementById("safe-area");
    // if (this.safeAreaElement) {
    //   this.safeAreaElement.style.backgroundColor = "#F2F2F2";
    // }

    this.animateBackgroundColor = anime({
      targets: "#backgroundColorDisplay",
      backgroundColor: [
        { value: "#808080" },
        { value: "#BF8440" },
        { value: "#FF8A00" },
        { value: "#BFB240" },
        { value: "#FFE600" },
        { value: "#97BF40" },
        { value: "#AFFF00" },
        { value: "#408FBF" },
        { value: "#009EFF" },
        { value: "#BF40B2" },
        { value: "#FF00E4" },
        { value: "#BF5240" },
        { value: "#FF2500" },
        { value: "#808080" },
      ],
      easing: "easeInOutSine",
      //direction: 'alternate',
      duration: 20000,
      autoplay: false,
      loop: true,
    });
    //set color group 2 animation value
    this.animateBackgroundColor.seek(
      this.animateBackgroundColor.duration *
        ((backgroundParam - MinDragPosition) /
          (MaxDragPosition - MinDragPosition))
    );

    // this.leftSliderPulse = this.add
    //   .image(this.cameras.main.centerX + 10, 800, "button-pulse")
    //   .setScale(this.GetScale(1))
    //   .setOrigin(0.5)
    //   .setAlpha(0);

    this.consoleButton1 = this.add
      .image(this.cameras.main.centerX + 635, 552, "button-active")
      .on("pointerover", () => {
        if (!this.toneFlag1) {
          this.consoleButton1?.setTexture("button-hover");
        }
      })
      .on("pointerout", () => {
        if (!this.toneFlag1) {
          this.consoleButton1?.setTexture("button-active");
        }
      })
      .on("pointerdown", () => {
        this.leftSliderPulse.setAlpha(0);
        this.rightSliderPulse.setAlpha(0);
        if (this.toneFlag1) {
          this.consoleButton1?.setTexture("button-down-yellow");
        } else {
          this.consoleButton1?.setTexture("button-down");
        }
      })
      .on("pointerup", () => {
        this.ButtonPointerUp(this.consoleButton1);
      })
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);
    this.consoleButton1.name = "button1";
    if (this.toneFlag1) {
      this.consoleButton1?.setTexture("button-down-yellow");
    }

    this.consoleButton2 = this.add
      .image(this.cameras.main.centerX + 700, 600, "button-active")
      .on("pointerover", () => {
        if (!this.toneFlag2) {
          this.consoleButton2?.setTexture("button-hover");
        }
      })
      .on("pointerout", () => {
        if (!this.toneFlag2) {
          this.consoleButton2?.setTexture("button-active");
        }
      })
      .on("pointerdown", () => {
        this.leftSliderPulse.setAlpha(0);
        this.rightSliderPulse.setAlpha(0);
        if (this.toneFlag2) {
          this.consoleButton2?.setTexture("button-down-yellow");
        } else {
          this.consoleButton2?.setTexture("button-down");
        }
      })
      .on("pointerup", () => {
        this.ButtonPointerUp(this.consoleButton2);
      })
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);
    this.consoleButton2.name = "button2";
    if (this.toneFlag2) {
      this.consoleButton2?.setTexture("button-down-yellow");
    }

    this.consoleButton3 = this.add
      .image(this.cameras.main.centerX + 745, 670, "button-active")
      .on("pointerover", () => {
        if (!this.toneFlag3) {
          this.consoleButton3?.setTexture("button-hover");
        }
      })
      .on("pointerout", () => {
        if (!this.toneFlag3) {
          this.consoleButton3?.setTexture("button-active");
        }
      })
      .on("pointerdown", () => {
        this.leftSliderPulse.setAlpha(0);
        this.rightSliderPulse.setAlpha(0);
        if (this.toneFlag3) {
          this.consoleButton3?.setTexture("button-down-yellow");
        } else {
          this.consoleButton3?.setTexture("button-down");
        }
      })
      .on("pointerup", () => {
        this.ButtonPointerUp(this.consoleButton3);
      })
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);
    this.consoleButton3.name = "button3";
    if (this.toneFlag3) {
      this.consoleButton3?.setTexture("button-down-yellow");
    }

    this.consoleButton4 = this.add
      .image(this.cameras.main.centerX + 768, 755, "button-active")
      .on("pointerover", () => {
        if (!this.toneFlag4) {
          this.consoleButton4?.setTexture("button-hover");
        }
      })
      .on("pointerout", () => {
        if (!this.toneFlag4) {
          this.consoleButton4?.setTexture("button-active");
        }
      })
      .on("pointerdown", () => {
        this.leftSliderPulse.setAlpha(0);
        this.rightSliderPulse.setAlpha(0);
        if (this.toneFlag4) {
          this.consoleButton4?.setTexture("button-down-yellow");
        } else {
          this.consoleButton4?.setTexture("button-down");
        }
      })
      .on("pointerup", () => {
        this.ButtonPointerUp(this.consoleButton4);
      })
      .setScale(this.GetScale(1))
      .setOrigin(0.5)
      .setAlpha(0);
    this.consoleButton4.name = "button4";
    if (this.toneFlag4) {
      this.consoleButton4?.setTexture("button-down-yellow");
    }

    this.leftSliderPulse = this.add
      .sprite(
        this.cameras.main.centerX -
          605 -
          (shapeGroup1Param - MinDragPosition) / 3,
        shapeGroup1Param,
        "left-slider-pulse"
      )
      .setScale(this.GetScale(1))
      .setAlpha(0);
    this.leftSliderPulse.name = "left-slider-pulse";

    this.rightSliderPulse = this.add
      .sprite(this.cameras.main.centerX, 0, "right-slider-pulse")
      .setScale(this.GetScale(1))
      .setAlpha(0); //hide at the start
    this.rightSliderPulse.name = "right-slider-pulse";

    // this.consoleHandleInactive = this.add
    //   .sprite(this.cameras.main.centerX - 170, 430, "handle", "inactive")
    //   .setScale(this.GetScale(1));

    this.consoleSlider1 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX -
        605 -
        (shapeGroup1Param - MinDragPosition) / 3,
      shapeGroup1Param,
      "slider-left"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider1.name = "slider_activity1";

    this.consoleSlider2 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX -
        485 -
        (shapeGroup2Param - MinDragPosition) / 3,
      shapeGroup2Param,
      "slider-left"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider2.name = "slider_activity2";

    this.consoleSlider3 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX -
        365 -
        (colorGroup1Param - MinDragPosition) / 3,
      colorGroup1Param,
      "slider-left"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider3.name = "slider_activity3";

    this.consoleSlider4 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX -
        240 -
        (colorGroup2Param - MinDragPosition) / 3,
      colorGroup2Param,
      "slider-left"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider4.name = "slider_activity4";

    this.consoleSlider5 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 230 + (lumiParam - MinDragPosition) / 3,
      lumiParam,
      "slider-right"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider5.name = "slider_activity5";

    this.consoleSlider6 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 350 + (backgroundParam - MinDragPosition) / 3,
      backgroundParam,
      "slider-right"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider6.name = "slider_activity6";

    this.consoleSlider7 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX +
        465 +
        (backgroundLumiParam - MinDragPosition) / 3,
      backgroundLumiParam,
      "slider-right"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider7.name = "slider_activity7";

    this.consoleSlider8 = new Phaser.GameObjects.Image(
      this,
      this.cameras.main.centerX + 580 + (tempoParam - MinDragPosition) / 3,
      tempoParam,
      "slider-right"
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScale(this.GetScale(1));
    this.consoleSlider8.name = "slider_activity8";

    super.resizeFunction = () => {
      this.consoleImage.x = this.cameras.main.centerX;
      this.ovalBackground.x = this.cameras.main.centerX;
      this.projector.x = this.cameras.main.centerX + 10;
      this.consoleButton1.x = this.cameras.main.centerX + 635;
      this.consoleButton2.x = this.cameras.main.centerX + 700;
      this.consoleButton3.x = this.cameras.main.centerX + 745;
      this.consoleButton4.x = this.cameras.main.centerX + 768;

      this.consoleSlider1.x =
        this.cameras.main.centerX -
        605 -
        (shapeGroup1Param - MinDragPosition) / 3;
      this.consoleSlider2.x =
        this.cameras.main.centerX -
        485 -
        (shapeGroup2Param - MinDragPosition) / 3;
      this.consoleSlider3.x =
        this.cameras.main.centerX -
        365 -
        (colorGroup1Param - MinDragPosition) / 3;
      this.consoleSlider4.x =
        this.cameras.main.centerX -
        240 -
        (colorGroup2Param - MinDragPosition) / 3;
      this.consoleSlider5.x =
        this.cameras.main.centerX + 230 + (lumiParam - MinDragPosition) / 3;
      this.consoleSlider6.x =
        this.cameras.main.centerX +
        350 +
        (backgroundParam - MinDragPosition) / 3;
      this.consoleSlider7.x =
        this.cameras.main.centerX +
        465 +
        (backgroundLumiParam - MinDragPosition) / 3;
      this.consoleSlider8.x =
        this.cameras.main.centerX + 580 + (tempoParam - MinDragPosition) / 3;

      if (sliderPulse === "slider_activity1") {
        this.leftSliderPulse.x =
          this.cameras.main.centerX -
          605 -
          (shapeGroup1Param - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity2") {
        this.leftSliderPulse.x =
          this.cameras.main.centerX -
          485 -
          (shapeGroup2Param - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity3") {
        this.leftSliderPulse.x =
          this.cameras.main.centerX -
          365 -
          (colorGroup1Param - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity4") {
        this.leftSliderPulse.x =
          this.cameras.main.centerX -
          240 -
          (colorGroup2Param - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity5") {
        this.rightSliderPulse.x =
          this.cameras.main.centerX + 230 + (lumiParam - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity6") {
        this.rightSliderPulse.x =
          this.cameras.main.centerX +
          350 +
          (backgroundParam - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity7") {
        this.rightSliderPulse.x =
          this.cameras.main.centerX +
          465 +
          (backgroundLumiParam - MinDragPosition) / 3;
      } else if (sliderPulse === "slider_activity8") {
        this.rightSliderPulse.x =
          this.cameras.main.centerX + 580 + (tempoParam - MinDragPosition) / 3;
      }
    };

    super.update = () => {};

    this.optionsContainer = new Phaser.GameObjects.Container(this);
    this.optionsContainer.setDepth(400);

    this.sceneContainer.add([
      this.consoleImage,
      this.ovalBackground,
      this.projector,
      this.consoleSlider1,
      this.consoleSlider2,
      this.consoleSlider3,
      this.consoleSlider4,
      this.consoleSlider5,
      this.consoleSlider6,
      this.consoleSlider7,
      this.consoleSlider8,
      this.leftSliderPulse,
      this.rightSliderPulse,
      this.consoleButton1,
      this.consoleButton2,
      this.consoleButton3,
      this.consoleButton4,
    ]);

    this.sceneContainer.sendToBack(this.ovalBackground);
    this.sceneContainer.sendToBack(this.projector);
    this.sceneContainer.moveTo(this.leftSliderPulse, 3);
    this.sceneContainer.moveTo(this.rightSliderPulse, 4);
    this.add.existing(this.sceneContainer);
    const aspectRatio = this.scale.baseSize.aspectRatio;

    // if (aspectRatio > 1920 / 1080) {
    //   const newX = (this.scale.baseSize.width - 1920) / 2;
    //   this.sceneContainer.x = newX;
    //   this.sceneContainer.y = 0;
    // } else {
    //   const newY = (this.scale.baseSize.height - 1080) / 2;
    //   this.sceneContainer.y = newY;
    //   this.sceneContainer.x = 0;
    // }

    // add close button dom element
    let finishButtonDiv = document.createElement("div");
    finishButtonDiv.className = "console-finish-button";
    let finishButton = document.createElement("button");
    finishButton.classList.add("btn-v2");
    finishButton.classList.add("btn-finished");
    finishButton.innerHTML = "Finished";

    finishButton.onclick = () => {
      this.finishButton.visible = false;
      this.tone1.stop();
      this.tone2.stop();
      this.tone3.stop();
      this.tone4.stop();
      this.FinishActivityCurrentSelection();
    };

    finishButtonDiv.appendChild(finishButton);

    this.finishButton = this.add.dom(
      window.innerWidth - 40,
      parseInt(this.game.canvas.style.height) - 40,
      finishButtonDiv
    );

    this.finishButton.setOrigin(1);
    this.finishButton.setDepth(250);
    this.finishButton.visible = false;

    let sampleThis = this;
    this.time.addEvent({
      delay: 1000,
      callback: () => {
        const uiBlock = new UIBlock();
        let speechJSON = this.cache.json.get("jsonVisualiser")[this.jsonName];
        this.textEngine = new TextEngine(sampleThis, speechJSON, "scenario");
        uiBlock.add(this.textEngine);
      },
      callbackScope: this,
      loop: false,
    });

    this.events.on("Activity1", () => {
      this.Activity1();
    });

    this.events.on("Activity2", () => {
      this.Activity2();
    });

    this.events.on("Activity3", () => {
      this.Activity3();
    });

    this.events.on("FinishActivityCurrentSelection", () => {
      this.FinishActivityCurrentSelection();
    });

    this.events.on("EndActivityLastSelection", () => {
      this.textEngine.dismissText();
    });

    this.input.on(Phaser.Input.Events.DRAG, this.HandleInputDrag, this);
    this.input.on(Phaser.Input.Events.DRAG_END, this.HandleInputDragEnd, this);

    this.sceneExitFunction = () => {
      this.resizeFunction = () => {};

      this.muteTones(true);
      //this.textEngine.dismissText();
      this.removeSvgLayer();
      this.sceneExitFunction = () => {};
    };

    this.events.on("EndActivity1", () => {
      this.removeSvgLayer();
      this.muteTones(true);

      sceneFadeInFadeOut(
        this,
        "CinematicScene",
        {
          video: "3_3",
          nextScene: "ControlPanelVisualiserScene",
          json: {
            episode: "ep3",
            jsonName: "ep3start2",
            counter: 2,
          },
        },
        "FadeToBlack"
      );
    });

    this.events.on("EndActivity2", () => {
      this.removeSvgLayer();
      this.muteTones(true);

      sceneFadeInFadeOut(
        this,
        "CinematicScene",
        {
          video: "3_4.1",
          nextScene: "TextEngineScene",
          json: "ep3sc4",
        },
        "Dissolve"
      );
    });

    this.events.on("EndActivity3", () => {
      this.removeSvgLayer();
      this.muteTones(true);

      sceneFadeInFadeOut(
        this,
        "CinematicScene",
        {
          video: "4_3",
          nextScene: "TextEngineScene",
          json: "ep4sc1",
        },
        "Dissolve"
      );
    });
  }

  Activity1() {
    this.tone1.start();
    this.tone2.start();
    this.tone3.start();
    this.tone4.start();

    this.time.addEvent({
      delay: 400,
      callback: () => {
        this.EnableConsole(true, () => {}, true);
      },
      loop: false,
      callbackScope: this,
    });
  }

  Activity2() {
    //empty for now
  }

  Activity3() {
    const isInitialised =
      sessionStorage.getItem(SessionNames.VISUALISER_STATE_INITIALISED) !==
      "false";
    if (!isInitialised) {
      sessionStorage.setItem(SessionNames.VISUALISER_STATE_INITIALISED, "true");
      this.scale.refresh(
        this.game.scale.gameSize.width,
        this.game.scale.gameSize.height
      );
    }

    this.textEngine.dismissText();
    this.Activity1();
    this.Activity2();
    this.scene.stop("TextEngine");
    this.finishButton.visible = true;
    sessionStorage.setItem("app-finish-button-visible", "true");
  }

  muteTones(value) {
    this.tone1.stop();
    this.toneFlag1 = value;
    this.tone2.stop();
    this.toneFlag2 = value;
    this.tone3.stop();
    this.toneFlag3 = value;
    this.tone4.stop();
    this.toneFlag4 = value;
  }

  AdjustPLaybackRate(toneFlag) {
    toneFlag.playbackRate =
      tempoParam == MinDragPosition
        ? 1.5
        : 0.5 +
          Number(
            (
              (tempoParam - MaxDragPosition) /
              (MinDragPosition - MaxDragPosition)
            ).toFixed(2)
          );
  }

  FinishActivityCurrentSelection() {
    this.textEngine.dismissText();

    if (this.episode === "ep4") {
      this.removeSvgLayer();
      this.muteTones(true);

      sceneFadeInFadeOut(
        this,
        "TextEngineScene",
        {
          json: "ep4Mindworker2",
        },
        "Dissolve"
      );
    } else {
      let speechJSON =
        this.cache.json.get("jsonVisualiser")[
          `${this.episode}end${this.counter === 2 ? 2 : ""}`
        ];

      if (speechJSON) {
        const uiBlock = new UIBlock();
        let sample = this;
        this.textEngine = new TextEngine(sample, speechJSON, "scenario");
        uiBlock.add(this.textEngine);
      }
    }
  }

  ButtonPointerUp(imageObject) {
    storedMuteStateString = localStorage.getItem("muteState");
    switch (imageObject.name) {
      case "button1":
        this.toneFlag1 = !this.toneFlag1;
        if (this.toneFlag1) {
          this.tone1.mute =
            storedMuteStateString != "true" ? false : this.tone1.mute;
          imageObject?.setTexture("button-down-yellow");
        } else {
          this.tone1.mute =
            storedMuteStateString != "true" ? true : this.tone1.mute;
          imageObject?.setTexture("button-active");
        }
        this.UpdateParams("tn1", this.toneFlag1 ? "1" : "0");
        break;
      case "button2":
        this.toneFlag2 = !this.toneFlag2;
        if (this.toneFlag2) {
          this.tone2.mute =
            storedMuteStateString != "true" ? false : this.tone2.mute;
          imageObject?.setTexture("button-down-yellow");
        } else {
          this.tone2.mute =
            storedMuteStateString != "true" ? true : this.tone2.mute;
          imageObject?.setTexture("button-active");
        }
        this.UpdateParams("tn2", this.toneFlag2 ? "1" : "0");
        break;
      case "button3":
        this.toneFlag3 = !this.toneFlag3;
        if (this.toneFlag3) {
          this.tone3.mute =
            storedMuteStateString != "true" ? false : this.tone3.mute;
          imageObject?.setTexture("button-down-yellow");
        } else {
          this.tone3.mute =
            storedMuteStateString != "true" ? true : this.tone3.mute;
          imageObject?.setTexture("button-active");
        }
        this.UpdateParams("tn3", this.toneFlag3 ? "1" : "0");
        break;
      case "button4":
        this.toneFlag4 = !this.toneFlag4;
        if (this.toneFlag4) {
          this.tone4.mute =
            storedMuteStateString != "true" ? false : this.tone4.mute;
          imageObject?.setTexture("button-down-yellow");
        } else {
          this.tone4.mute =
            storedMuteStateString != "true" ? true : this.tone4.mute;
          imageObject?.setTexture("button-active");
        }
        this.UpdateParams("tn4", this.toneFlag4 ? "1" : "0");
        break;
    }
  }

  HandleInputDrag(pointer, gameObject, dragX, dragY) {
    if (
      gameObject.name === "slider_activity1" ||
      gameObject.name === "slider_activity2" ||
      gameObject.name === "slider_activity3" ||
      gameObject.name === "slider_activity4" ||
      gameObject.name === "slider_activity5" ||
      gameObject.name === "slider_activity6" ||
      gameObject.name === "slider_activity7" ||
      gameObject.name === "slider_activity8"
    ) {
      let cnterXvar = this.cameras.main.centerX - 170;
      this.leftSliderPulse.setAlpha(0);
      this.rightSliderPulse.setAlpha(0);

      if (gameObject.name === "slider_activity1") {
        cnterXvar = this.cameras.main.centerX - 605;
        this.leftSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity2") {
        cnterXvar = this.cameras.main.centerX - 485;
        this.leftSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity3") {
        cnterXvar = this.cameras.main.centerX - 365;
        this.leftSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity4") {
        cnterXvar = this.cameras.main.centerX - 240;
        this.leftSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity5") {
        cnterXvar = this.cameras.main.centerX + 230;
        this.rightSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity6") {
        cnterXvar = this.cameras.main.centerX + 350;
        this.rightSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity7") {
        cnterXvar = this.cameras.main.centerX + 465;
        this.rightSliderPulse.setAlpha(1);
      } else if (gameObject.name === "slider_activity8") {
        cnterXvar = this.cameras.main.centerX + 580;
        this.rightSliderPulse.setAlpha(1);
      }

      if (dragY > MinDragPosition && dragY < MaxDragPosition) {
        gameObject.y = dragY;

        if (
          gameObject.name === "slider_activity1" ||
          gameObject.name === "slider_activity2" ||
          gameObject.name === "slider_activity3" ||
          gameObject.name === "slider_activity4"
        ) {
          gameObject.x = cnterXvar - (dragY - MinDragPosition) / 3;
        } else {
          gameObject.x = cnterXvar + (dragY - MinDragPosition) / 3;
        }

        let percentage =
          (dragY - MinDragPosition) / (MaxDragPosition - MinDragPosition);
        let invertPercentage =
          (dragY - MaxDragPosition) / (MinDragPosition - MaxDragPosition);

        if (
          gameObject.name === "slider_activity1" ||
          gameObject.name === "slider_activity2" ||
          gameObject.name === "slider_activity3" ||
          gameObject.name === "slider_activity4" ||
          gameObject.name === "slider_activity5" ||
          gameObject.name === "slider_activity6" ||
          gameObject.name === "slider_activity7" ||
          gameObject.name === "slider_activity8"
        ) {
          if (percentage >= 0 && percentage <= 1) {
            handlerValue = Math.round(dragY);

            if (gameObject.name === "slider_activity1") {
              // morph slider
              this.animateShapeLayerOne.seek(
                this.animateShapeLayerOne.duration * percentage
              );
              this.animateShapeLayerTwo.seek(
                this.animateShapeLayerTwo.duration * percentage
              );
            } else if (gameObject.name === "slider_activity2") {
              // morph slider
              this.animateShapeLayerThree.seek(
                this.animateShapeLayerThree.duration * percentage
              );
            } else if (gameObject.name === "slider_activity3") {
              // color slider
              this.animateColorPaletteA.seek(
                this.animateColorPaletteA.duration * percentage
              );
            } else if (gameObject.name === "slider_activity4") {
              // color slider
              this.animateColorPaletteB.seek(
                this.animateColorPaletteB.duration * percentage
              );
            } else if (gameObject.name === "slider_activity5") {
              // luminosity slider
              this.animateLuminosity.seek(
                this.animateLuminosity.duration * percentage
              );
            } else if (gameObject.name === "slider_activity6") {
              // background color slider
              this.animateBackgroundColor.seek(
                this.animateBackgroundColor.duration * percentage
              );
            } else if (gameObject.name === "slider_activity7") {
              //background luminosity slider
              this.animateBackgroundLuminosity.seek(
                this.animateBackgroundLuminosity.duration * percentage
              );
            } else if (gameObject.name === "slider_activity8") {
              this.tone1.playbackRate =
                0.5 + Number(invertPercentage.toFixed(2));
              this.tone2.playbackRate =
                0.5 + Number(invertPercentage.toFixed(2));
              this.tone3.playbackRate =
                0.5 + Number(invertPercentage.toFixed(2));
              this.tone4.playbackRate =
                0.5 + Number(invertPercentage.toFixed(2));

              if (tempoDuration * percentage <= 200) {
                this.animateShape1Tempo.duration = 200;
                this.animateShape2Tempo.duration = 200;
                this.animateShape3Tempo.duration = 200;
                this.animateShape4Tempo.duration = 200;
              } else {
                this.animateShape1Tempo.duration = tempoDuration * percentage;
                this.animateShape2Tempo.duration = tempoDuration * percentage;
                this.animateShape3Tempo.duration = tempoDuration * percentage;
                this.animateShape4Tempo.duration = tempoDuration * percentage;
              }
            }
          }
        }
      } else if (dragY < MinDragPosition) {
        gameObject.y = MinDragPosition;
        gameObject.x = cnterXvar;

        handlerValue = MinDragPosition;

        //highest most lever position
        if (gameObject.name === "slider_activity1") {
          // morph slider
          this.animateShapeLayerOne.seek(0);
          this.animateShapeLayerTwo.seek(0);
        } else if (gameObject.name === "slider_activity2") {
          // morph slider
          this.animateShapeLayerThree.seek(0);
        } else if (gameObject.name === "slider_activity3") {
          // color slider
          this.animateColorPaletteA.seek(0);
        } else if (gameObject.name === "slider_activity4") {
          // color slider
          this.animateColorPaletteB.seek(0);
        } else if (gameObject.name === "slider_activity5") {
          //luminosity
          this.animateLuminosity.seek(0);
        } else if (gameObject.name === "slider_activity6") {
          // color slider
          this.animateBackgroundColor.seek(0);
        } else if (gameObject.name === "slider_activity7") {
          //background luminosity
          this.animateBackgroundLuminosity.seek(0);
        } else if (gameObject.name === "slider_activity8") {
          // tempo slider
          this.animateShape1Tempo.duration = 200;
          this.animateShape2Tempo.duration = 200;
          this.animateShape3Tempo.duration = 200;
          this.animateShape4Tempo.duration = 200;

          this.tone1.playbackRate = 1.5;
          this.tone2.playbackRate = 1.5;
          this.tone3.playbackRate = 1.5;
          this.tone4.playbackRate = 1.5;
        }
      } else if (dragY > MaxDragPosition) {
        gameObject.y = MaxDragPosition;

        if (
          gameObject.name === "slider_activity1" ||
          gameObject.name === "slider_activity2" ||
          gameObject.name === "slider_activity3" ||
          gameObject.name === "slider_activity4"
        ) {
          gameObject.x = cnterXvar - (MaxDragPosition - MinDragPosition) / 3;
        } else {
          gameObject.x = cnterXvar + (MaxDragPosition - MinDragPosition) / 3;
        }

        handlerValue = MaxDragPosition;

        //lowest most lever position
        if (gameObject.name === "slider_activity1") {
          // morph slider
          this.animateShapeLayerOne.seek(this.animateShapeLayerOne.duration);
          this.animateShapeLayerTwo.seek(this.animateShapeLayerTwo.duration);
        } else if (gameObject.name === "slider_activity2") {
          // morph slider
          this.animateShapeLayerThree.seek(
            this.animateShapeLayerThree.duration
          );
        } else if (gameObject.name === "slider_activity3") {
          // color slider
          this.animateColorPaletteA.seek(this.animateColorPaletteA.duration);
        } else if (gameObject.name === "slider_activity4") {
          this.animateColorPaletteB.seek(this.animateColorPaletteB.duration);
        } else if (gameObject.name === "slider_activity5") {
          //luminosity
          this.animateLuminosity.seek(this.animateLuminosity.duration);
        } else if (gameObject.name === "slider_activity6") {
          // color slider
          this.animateBackgroundColor.seek(
            this.animateBackgroundColor.duration
          );
        } else if (gameObject.name === "slider_activity7") {
          //background luminosity
          this.animateBackgroundLuminosity.seek(
            this.animateBackgroundLuminosity.duration
          );
        } else if (gameObject.name === "slider_activity8") {
          // tempo slider
          this.animateShape1Tempo.duration = tempoDuration;
          this.animateShape2Tempo.duration = tempoDuration;
          this.animateShape3Tempo.duration = tempoDuration;
          this.animateShape4Tempo.duration = tempoDuration;

          this.tone1.playbackRate = 0.5;
          this.tone2.playbackRate = 0.5;
          this.tone3.playbackRate = 0.5;
          this.tone4.playbackRate = 0.5;
        }
      }

      this.leftSliderPulse.setPosition(gameObject.x, gameObject.y);
      this.rightSliderPulse.setPosition(gameObject.x, gameObject.y);
      sliderPulse = gameObject.name;
    }
  }

  GetScale(scale: number): number {
    return scale * (1600 / 1920);
  }

  UpdateParams(param: string, paramVal: string) {
    const url = new URL(window.location.href);
    //url.searchParams.set("skip", "ControlPanelVisualiserScene");
    url.searchParams.set(param, paramVal);
    window.history.pushState(null, "", url.toString());
  }

  HandleInputDragEnd(pointer, gameObject) {
    if (gameObject.name === "slider_activity1") {
      this.UpdateParams("sg1", handlerValue.toString());
      shapeGroup1Param = handlerValue;
    } else if (gameObject.name == "slider_activity2") {
      this.UpdateParams("sg2", handlerValue.toString());
      shapeGroup2Param = handlerValue;
    } else if (gameObject.name == "slider_activity3") {
      this.UpdateParams("cg1", handlerValue.toString());
      colorGroup1Param = handlerValue;
    } else if (gameObject.name == "slider_activity4") {
      this.UpdateParams("cg2", handlerValue.toString());
      colorGroup2Param = handlerValue;
    } else if (gameObject.name == "slider_activity5") {
      this.UpdateParams("lum", handlerValue.toString());
      lumiParam = handlerValue;
    } else if (gameObject.name == "slider_activity6") {
      this.UpdateParams("bgc", handlerValue.toString());
      backgroundParam = handlerValue;
    } else if (gameObject.name == "slider_activity7") {
      this.UpdateParams("blum", handlerValue.toString());
      backgroundLumiParam = handlerValue;
    } else if (gameObject.name == "slider_activity8") {
      this.UpdateParams("tmpo", handlerValue.toString());
      tempoParam = handlerValue;
    }
  }

  EnableConsole(
    enableButton: boolean = false,
    onCompleteFunction?: () => void,
    addButtonPulse: boolean = false
  ) {
    const tweenDuration = 400;
    if (enableButton) {
      // this.tweens.add({
      //   targets: this.consoleGoButtonInactive,
      //   delay: 200,
      //   alpha: { value: 0, duration: 100, ease: "Power1" },
      // });
    }

    this.tweens.add({
      targets: this.consoleSlider1,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider1) {
          this.consoleSlider1.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider1);
        }

        if (addButtonPulse) {
          //left pulse
          this.leftSliderPulse.setAlpha(1);

          let timeline = this.tweens.createTimeline();
          timeline.loop = -1;
          let originalScale = this.leftSliderPulse.scale;

          timeline.add({
            targets: this.leftSliderPulse,
            scale: originalScale,
            ease: "Power1",
            duration: 300,
          });

          timeline.add({
            targets: this.leftSliderPulse,
            scale: originalScale * 1.05,
            ease: "Power1",
            duration: 300,
          });

          timeline.add({
            targets: this.leftSliderPulse,
            scale: originalScale,
            ease: "Power1",
            duration: 300,
          });

          timeline.play();

          //right pulse
          let timeline2 = this.tweens.createTimeline();
          timeline2.loop = -1;
          let originalScale2 = this.rightSliderPulse.scale;

          timeline2.add({
            targets: this.rightSliderPulse,
            scale: originalScale2,
            ease: "Power1",
            duration: 300,
          });

          timeline2.add({
            targets: this.rightSliderPulse,
            scale: originalScale2 * 1.05,
            ease: "Power1",
            duration: 300,
          });

          timeline2.add({
            targets: this.rightSliderPulse,
            scale: originalScale2,
            ease: "Power1",
            duration: 300,
          });

          timeline2.play();
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider2,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider2) {
          this.consoleSlider2.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider2);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider3,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider3) {
          this.consoleSlider3.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider3);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider4,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider4) {
          this.consoleSlider4.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider4);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider5,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider5) {
          this.consoleSlider5.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider5);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider6,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider6) {
          this.consoleSlider6.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider6);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider7,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider7) {
          this.consoleSlider7.setInteractive({ useHandCursor: true });
          this.input.setDraggable(this.consoleSlider7);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleSlider8,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        if (onCompleteFunction) {
          onCompleteFunction();
        }

        if (this.consoleSlider8) {
          this.consoleSlider8.setInteractive({ useHandCursor: false });
          this.input.setDraggable(this.consoleSlider8);
        }
      },
    });

    this.tweens.add({
      targets: this.consoleButton1,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        this.consoleButton1?.setInteractive({ useHandCursor: true });
      },
    });
    this.tweens.add({
      targets: this.consoleButton2,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        this.consoleButton2?.setInteractive({ useHandCursor: true });
      },
    });
    this.tweens.add({
      targets: this.consoleButton3,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        this.consoleButton3?.setInteractive({ useHandCursor: true });
      },
    });
    this.tweens.add({
      targets: this.consoleButton4,
      alpha: { value: 1, duration: tweenDuration, ease: "Power1" },
      onComplete: () => {
        this.consoleButton4?.setInteractive({ useHandCursor: true });
      },
    });
  }

  addAudioFileToQue(audioKey: string) {
    const audioVl = this.sound.add(audioKey, { volume: 2.5 });
    audioVl.stop();
    // check if we have anything currently playing, if not play
    if (this.audioSequence.length == 0) {
      audioVl.play();
    }

    this.audioSequence.push(audioVl);
  }

  cancelAudio() {
    this.audioSequence.forEach((audio) => {
      audio.stop();
      audio.destroy();
    });

    this.audioSequence = [];
  }

  removeSvgLayer() {
    this.backgroundColorElement.style.backgroundColor = "";
    const svgShapeVar = document.getElementById("svgShape");
    svgShapeVar?.remove();
    document.getElementById("backgroundColorDisplay")?.remove();
  }
}
