const REFERENCE_WIDTH: number = 1600;
const REFERENCE_HEIGHT: number = 900;
const MAX_WIDTH: number = 1920;
const MAX_HEIGHT: number = 1080;

export function scaleGame(Game: Phaser.Game) {
  return () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Window size as a fraction of the reference dimensions, whichever edge is smaller
    const smallest = Math.min(
      windowWidth / REFERENCE_WIDTH,
      windowHeight / REFERENCE_HEIGHT
    );

    // Divide the window dimensions by the smallest fraction to get the new dimensions in pixels
    const newWidth = Math.min(windowWidth / smallest, MAX_WIDTH);
    const newHeight = Math.min(windowHeight / smallest, MAX_HEIGHT);

    // Scale the new dimensions by the smallest fraction to get the scaled dimensions
    const scaledWidth = newWidth * smallest;
    const scaledHeight = newHeight * smallest;

    // Calculate the difference between the window dimensions and the scaled dimensions
    const deltaWidth = windowWidth - scaledWidth;
    const deltaHeight = windowHeight - scaledHeight;

    const referenceRatio = REFERENCE_WIDTH / REFERENCE_HEIGHT;
    const maxWRatio = MAX_WIDTH / REFERENCE_HEIGHT;
    const maxHRatio = REFERENCE_WIDTH / MAX_HEIGHT;
    const maxSmoothScale = 1.05;
    const normalize = (value: number, min: number, max: number) => {
      return (value - min) / (max - min);
    };
    const smooth =
      referenceRatio < windowWidth / windowHeight
        ? (-1 * normalize(newWidth / newHeight, referenceRatio, maxWRatio)) /
            (1 / (maxSmoothScale - 1)) +
          maxSmoothScale
        : normalize(newWidth / newHeight, referenceRatio, maxHRatio) /
            (1 / (maxSmoothScale - 1)) +
          maxSmoothScale;

    Game.scale.resize(newWidth * smooth, newHeight * smooth); // Resize the game
    // console.log("smooth",smooth);
    // console.log("newWidth",newWidth);
    // console.log("newHeight",newHeight);
    Game.canvas.style.setProperty("width", `${scaledWidth}px`); // Scale the width of the css
    Game.canvas.style.setProperty("height", `${scaledHeight}px`); // Scale the height of the css

    // Provide canvas dimensions to CSS
    const rootEl = document.documentElement;
    if (!rootEl) return;
    rootEl.style.setProperty("--canvas-width", `${scaledWidth}px`);
    rootEl.style.setProperty("--canvas-height", `${scaledHeight}px`);
    window.__CUIO__ = window.__CUIO__ || {};
    window.__CUIO__.canvasWidth = scaledWidth;
    window.__CUIO__.canvasHeight = scaledHeight;

    // Center the safe area
    const safeArea = Game.canvas?.parentElement?.parentElement;
    if (!safeArea) return;

    safeArea.style.marginLeft = `${deltaWidth / 2}px`;
    rootEl.style.setProperty(
      "--safe-area-margin-left",
      `${safeArea.style.marginLeft}`
    ); //pass this to css to resize overlay
    safeArea.style.marginTop = `${deltaHeight / 2}px`;
    rootEl.style.setProperty(
      "--safe-area-margin-top",
      `${safeArea.style.marginTop}`
    ); //pass this to css to resize overlay

    //  console.log("scaledWidth",scaledWidth);
    //  console.log("scaledHeight",scaledHeight);
    //  console.log("referenceRatio",referenceRatio);
    //  console.log("ratio",scaledWidth/scaledHeight);
    //  console.log("greater than reference ratio",referenceRatio < windowWidth / windowHeight);
    //  console.log("smooth",smooth);
  };
}
