import { getSiblings } from "../../lib/util";
import { ITab } from "./Tabs.Models";

export class Tabs {
  data: ITab;
  element: HTMLDivElement = document.createElement("div");

  classEnums = {
    containerElem: "tabs",
    tabTitleContainerElem: "tab-titles",
    tabTitleElem: "tab-title",
    tabContentContainerElem: "tab-contents",
    tabContentElem: "tab-content",
  };

  constructor(data: ITab) {
    this.data = data;
    this.init();
  }

  private init = () => {
    this.element.className = [this.classEnums.containerElem]
      .concat(this.data.className ?? [])
      .join(" ");

    if (this.data.id) this.element.id = this.data.id;

    const tabTitleContainer = document.createElement("div");
    tabTitleContainer.className = this.classEnums.tabTitleContainerElem;

    const tabContentContainer = document.createElement("div");
    tabContentContainer.className = this.classEnums.tabContentContainerElem;

    this.element.appendChild(tabTitleContainer);
    this.element.appendChild(tabContentContainer);

    this.data.tabs.forEach((item, index) => {
      const tabTitle = document.createElement("div");
      tabTitle.innerHTML = item.title;
      tabTitle.className = this.classEnums.tabTitleElem;

      if (index == 0) {
        tabTitle.className += " active";
      }

      tabTitleContainer.appendChild(tabTitle);

      const tabContent = document.createElement("div");
      tabContent.className = this.classEnums.tabContentElem;
      tabContent.setAttribute("data-tabindex", index.toString());
      item.element = tabContent;

      if (index == 0) {
        tabContent.className += " active";
      }

      tabContent.appendChild(item.content);
      tabContentContainer.appendChild(tabContent);
    });

    this.bindEvents();
    this.data.onCreated && this.data.onCreated(this.element, this.data);
  };

  private bindEvents = () => {
    this.handleTabbing();
  };

  private handleTabbing = () => {
    (
      this.element.querySelectorAll(`.${this.classEnums.tabTitleElem}`) ?? []
    ).forEach((elem, index) => {
      if (!elem) return;

      elem.addEventListener("click", (e: Event) => {
        const target = e.target as HTMLElement;

        if (this.data.onTabbing) this.data.onTabbing(e);

        const siblings = getSiblings(target);

        target.classList.add("active");

        siblings.forEach((sibling) =>
          (sibling as HTMLElement).classList.remove("active")
        );

        const tabContent = this.element.querySelector(
          `[data-tabindex="${index}"]`
        ) as HTMLElement;
        const tabContentSiblings = getSiblings(tabContent);

        tabContent.classList.add("active");

        tabContentSiblings.forEach((sibling) =>
          (sibling as HTMLElement).classList.remove("active")
        );

        if (this.data.onTabChanged) this.data.onTabChanged(e);
      });
    });
  };
}
