import Phaser from "phaser";
import BaseScene from "./common/BaseScene";
import { createCopyright, resizeImage, sceneFadeInFadeOut } from "~/lib/util";

export default class EndOfExperienceScene extends BaseScene {
  private copyrightElem!: HTMLElement;
  private crownLogoElem!: Phaser.GameObjects.DOMElement;
  private contentElem!: Phaser.GameObjects.DOMElement;

  constructor() {
    super("EndOfExperienceScene");
  }

  preload() {
    super.preload();
    this.load.path = "assets/";
    this.load.image("EndOfExperienceSceneBg", "end-of-experience.jpg");
  }

  create() {
    super.create();

    this.time.addEvent({
      delay: 500,
      loop: false,
      callbackScope: this,
      callback: () => {
        const bg = this.add.image(0, 0, "EndOfExperienceSceneBg");

        this.setImagePosition(bg);

        window.addEventListener("resize", () =>
          resizeImage(
            this,
            bg,
            this.backgroundWidth,
            this.backgroundHeight,
            (image) => this.setImagePosition(image)
          )
        );

        createCopyright(this);

        this.createContent();
      },
    });
  }

  setImagePosition(image: Phaser.GameObjects.Image) {
    image.setOrigin(0.5);
    image.setPosition(
      this.scale.canvas.width / 2,
      this.scale.canvas.height / 2
    );
  }

  createContent() {
    const container = document.createElement("div");
    container.classList.add("end-content", "end-of-experience");

    const title = document.createElement("h1");
    title.innerHTML = "Thanks for<br/>playing!";

    const returnToHomeButton = document.createElement("button");
    returnToHomeButton.classList.add("take-a-break");
    returnToHomeButton.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path d="M10 24C9.44772 24 9 23.5523 9 23V13.8286C9 13.5165 9.14571 13.2223 9.39396 13.0332L15.394 8.46175C15.752 8.18899 16.248 8.18899 16.606 8.46175L22.606 13.0332C22.8543 13.2223 23 13.5165 23 13.8286V23C23 23.5523 22.5523 24 22 24H18.875C18.3227 24 17.875 23.5523 17.875 23V19.6667C17.875 19.1144 17.4273 18.6667 16.875 18.6667H15.125C14.5727 18.6667 14.125 19.1144 14.125 19.6667V23C14.125 23.5523 13.6773 24 13.125 24H10Z" fill="#AF97FF"/>
      </svg>
      <span>Return Home</span>
    `;
    returnToHomeButton.onclick = () => {
      sceneFadeInFadeOut(this, "HomeSceneNew");
    };

    container.appendChild(title);
    container.appendChild(returnToHomeButton);

    this.contentElem = this.add.dom(0, 0, container);
    this.contentElem.setOrigin(0, 0.5);
    this.contentElem.setDepth(2);
  }
}
