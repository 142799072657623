import Phaser from "phaser";
import BaseScene from "./common/BaseScene";
import { sceneFadeInFadeOut } from "../lib/util";
import { Tabs } from "~/components/Tabs/Tabs";
import { EpisodeConfig } from "~/config/episodes";
import { AvailableEpisodes, SessionNames, AppStates } from "~/config/enums";
import Modal from "~/components/Modal/Modal";
import { title } from "process";

const epCallback = (e: MouseEvent | TouchEvent, epStartFn: () => void) => {
  if (epStartFn) {
    const target = e.target as HTMLElement;
    const parent = target.closest(".card") as HTMLElement;
    parent.classList.add("pressed");
    epStartFn();
  }
};

export default class HomeSceneNew extends BaseScene {
  domElement!: Phaser.GameObjects.DOMElement;
  epCards!: any;
  overlay: any;
  titleElem!: HTMLDivElement;
  dragElement!: HTMLElement;
  pos1: number = 0;
  pos2: number = 0;
  offset: number = 0;
  isDragging = false;
  isClick = false;
  bounceTimer!: Phaser.Time.TimerEvent;
  mindworker!: Phaser.GameObjects.Image;
  swipeElement!: HTMLElement;
  homeTitle!: HTMLElement;
  logo!: HTMLElement;
  contentContainer!: HTMLElement;
  overlay2!: HTMLElement;
  modal!: Modal;

  constructor() {
    super("HomeSceneNew"); //"/assets/home-background.jpg"
  }

  preload() {
    super.preload();
    this.load.path = "assets/";
    //this.load.multiatlas("mindworker-sprite", "animation.json");

    //update url at the start of home scene
    const url = new URL(window.location.href);
    localStorage.setItem("currentParam", "");
    window.history.pushState(null, "", url.origin.toString());
  }

  create() {
    super.create(true);

    const ep1 = EpisodeConfig.getEpisode(AvailableEpisodes.EP1);
    const ep2 = EpisodeConfig.getEpisode(AvailableEpisodes.EP2);
    const ep3 = EpisodeConfig.getEpisode(AvailableEpisodes.EP3);
    const ep4 = EpisodeConfig.getEpisode(AvailableEpisodes.EP4);

    this.epCards = [
      {
        ...ep1,
        ...{
          desc: "Meet<br/>the team",
          background: "#6B43EF",
          backgroundHover: "#4427A4",
          backgroundColor: "purple",
          callback: (e: MouseEvent | TouchEvent) => {
            if (!ep1?.start) return;

            this.cardFn(e);
            ep1.start(this);
          },
        },
      },
      {
        ...ep2,
        ...{
          desc: "Anger in<br/>action",
          background: "#A8321D",
          backgroundHover: "#952511",
          backgroundColor: "purple",
          callback: (e: MouseEvent | TouchEvent) => {
            if (!ep2?.start) return;

            this.cardFn(e);
            ep2.start(this);
          },
        },
      },
      {
        ...ep3,
        ...{
          desc: "Emotions and<br/>the body",
          background: "#FFF",
          backgroundHover: "#378BC0",
          backgroundColor: "purple",
          callback: (e: MouseEvent | TouchEvent) => {
            if (!ep3?.start) return;

            this.cardFn(e);
            ep3.start(this);
          },
        },
      },
      {
        ...ep4,
        ...{
          desc: "A powerful<br/>pause",
          background: "#FFF",
          backgroundHover: "#EBB516",
          backgroundColor: "purple",
          callback: (e: MouseEvent | TouchEvent) => {
            if (!ep4?.start) return;

            this.cardFn(e);
            ep4.start(this);
          },
        },
      },
    ];

    const body = document.querySelector("body") as HTMLElement;
    body?.classList.add("enable-scroll", "home");

    const phaser = document.querySelector("#phaser > div") as HTMLElement;
    phaser?.classList.add("home-container");

    const safeArea = document.getElementById("safe-area") as HTMLElement;
    safeArea?.classList.add("home-area");

    this.createSplashScreen(body);
    this.createBackgroundGroup(body);

    this.renderCards();

    this.handleToggle(false);

    let domELement = document.querySelector("#Phaser > div") as HTMLElement;

    this.time.addEvent({
      delay: 500,
      loop: false,
      callbackScope: this,
      callback: () => {
        domELement.style.height = this.game.canvas.style.height;
      },
    });

    if (sessionStorage.getItem(SessionNames.APP_STATE) === null) {
      sessionStorage.setItem(SessionNames.APP_STATE, AppStates.INITIALIZED);
    }
  }

  renderCards() {
    let overlayDiv = document.createElement("div");
    overlayDiv.id = "overlay";
    overlayDiv.className = "overlay";

    this.overlay = this.add.dom(0, 0, overlayDiv);
    this.overlay.setAlpha(0);
    this.overlay.setOrigin(0);

    const contentContainer = document.createElement("div");
    contentContainer.className = "content-container";

    const setBgImgHeight = () => {
      const bgGroup = document.querySelector(".home-background") as HTMLElement;
      const bgImg = bgGroup.querySelector(
        ".home-background-image"
      ) as HTMLElement;

      contentContainer.style.setProperty(
        "--bg-height",
        `${bgImg?.offsetHeight}px`
      );
    };

    if (window.innerWidth <= 599) {
      setBgImgHeight();
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth > 599) return;
      setBgImgHeight();
    });

    this.titleElem = document.createElement("div");
    this.titleElem.className = "home-title";
    this.titleElem.innerHTML = "<h2>Why do we do the things that we do?</h2>";
    this.titleElem.innerHTML +=
      "<p>Get to know your mind inside out with Riley and her team of emotions!</p>";

    this.homeTitle = this.titleElem;
    this.contentContainer = contentContainer;
    contentContainer.appendChild(this.titleElem);

    const cardCollectionWrapper = document.createElement("div");
    cardCollectionWrapper.id = "card-wrapper";
    cardCollectionWrapper.className = "card-collection-wrapper";

    for (let i = 0; i < this.epCards.length; i++) {
      const card = document.createElement("div");
      card.className =
        i == 0
          ? "card card-first"
          : this.epCards[i].active
          ? "card"
          : "card card-inactive";
      card.id = "card-" + i;
      if (this.epCards[i]?.callback) {
        let clickEnabled = true;
        let touchStartX = 0;
        let touchStartY = 0;
        let timeout;

        card.onclick = (e: MouseEvent | TouchEvent) => {
          if (clickEnabled) this.epCards[i].callback(e);
        };
        card.ontouchstart = (e: TouchEvent) => {
          touchStartX = e.touches[0].clientX;
          touchStartY = e.touches[0].clientY;
          clickEnabled = true;
        };

        card.ontouchmove = (e: TouchEvent) => {
          const touchEndX = e.touches[0].clientX;
          const touchEndY = e.touches[0].clientY;
          if (
            Math.abs(touchEndX - touchStartX) > 10 ||
            Math.abs(touchEndY - touchStartY) > 10
          ) {
            clickEnabled = false;
            timeout && clearInterval(timeout);
          }
        };

        card.ontouchend = () => {
          timeout = setTimeout(() => {
            clickEnabled = true;
          }, 100);
        };
      }

      const cardImage = document.createElement("div");
      cardImage.className = `card-image`;
      cardImage.innerHTML = `<img src=${this.epCards[i].image} loading="lazy" class="image"/>`;
      card.appendChild(cardImage);
      const cardDescriptionWrapper = document.createElement("div");
      cardDescriptionWrapper.className = `card-description ${this.epCards[i].descColor}`;

      const title = document.createElement("div");
      title.className = "card-title";
      title.innerText = this.epCards[i].title;
      cardDescriptionWrapper.appendChild(title);

      if (i != 7) {
        const playLockBtn = document.createElement("div");
        playLockBtn.className = `card-btn ${this.epCards[i].backgroundColor}`;

        if (this.epCards[i].active) {
          cardDescriptionWrapper.appendChild(playLockBtn);
        } else {
          playLockBtn.className = "card-btn card-lock";
          playLockBtn.style.backgroundColor = this.epCards[i].background;
          playLockBtn.innerHTML = `<?xml version="1.0" encoding="utf-8"?>
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 16 18"  xml:space="preserve" class="lock-svg">
          <path class="st0" fill=${this.epCards[i].lockColor} d="M14.7,6.9h-1.4V5.6c0-1.5-0.6-2.9-1.6-3.9C10.8,0.6,9.4,0,8,0S5.2,0.6,4.2,1.6c-1,1-1.6,2.5-1.6,3.9v1.4H1.3
            C1,6.9,0.6,7.1,0.4,7.3S0,7.9,0,8.3v8.4c0,0.4,0.1,0.7,0.4,1C0.6,17.9,1,18,1.3,18h13.4c0.2,0,0.3,0,0.5-0.1
            c0.2-0.1,0.3-0.2,0.4-0.3s0.2-0.3,0.3-0.4C16,17,16,16.8,16,16.7V8.3c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.2-0.3-0.3-0.4
            c-0.1-0.1-0.3-0.2-0.4-0.3S14.9,6.9,14.7,6.9z M5,5.9V5.6c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.6-0.5,1-0.7
            C7.2,2.5,7.6,2.4,8,2.4s0.8,0.1,1.2,0.2c0.4,0.2,0.7,0.4,1,0.7c0.3,0.3,0.5,0.6,0.7,1C10.9,4.7,11,5.1,11,5.6v1.4H5L5,5.9z"/>
          </svg>
          `;

          card.append(playLockBtn);
        }
      }

      const desc = document.createElement("div");
      desc.className = "card-desc";
      desc.innerHTML = this.epCards[i].desc;
      cardDescriptionWrapper.appendChild(desc);

      card.appendChild(cardDescriptionWrapper);
      cardCollectionWrapper.appendChild(card);

      if (this.epCards[i].active) {
        card.addEventListener("mouseenter", this.handleCardHover);
        card.addEventListener("mouseleave", this.handleCardMouseLeave);
      }
    }

    let cardsDiv = document.createElement("div");
    cardsDiv.className = "card-wrapper";
    cardsDiv.appendChild(cardCollectionWrapper);
    cardsDiv.appendChild(this.createCrownLogo());
    const forParentsContent = document.createElement("div");
    forParentsContent.className = "for-parents";
    forParentsContent.innerHTML = `
      <div class="for-parents-content">
        <h3>Starting the conversation about emotions</h3>
        <p>The Crown Institute worked directly with Pixar Animation Studios to create InsideU. This collaborative project aims to harness Disney and Pixar's <em>Inside Out</em>'s potential as a powerful, entertaining, and educational tool for improving peoples' mental and emotional wellness.  </p>
        <p>InsideU was collaboratively designed with youth using <em>Inside Out</em>'s unique characters and narratives that are deeply rooted in elements of evidence-based psychotherapies. Using a combination of clips from the iconic movie and interactive learning opportunities, InsideU facilitates the creative application of social and emotional learning skills to real-world dilemmas faced by elementary school-aged children.</p>
      </div>
      <div class="for-parents-content helpful-resources">
        <p>Helpful Resources</p>
        <ul class="helpful-resources-links">
          <li><a href="#" data-content-target="about-insideu">ABOUT INSIDEU</a></li>
          <li><a href="#" data-content-target="about-us">ABOUT US</a></li>
          <li><a href="#" data-content-target="privacy-page">PRIVACY PAGE</a></li>
          <li><a href="#" data-content-target="science-behind">THE SCIENCE BEHIND INSIDEU</a></li>
        </ul>
        <div class="helpful-resources-logo">
          <img src="assets/reneeCrown-cobrand.png" loading="lazy" />
        </div>
      </div>
    `;

    const tabs = new Tabs({
      tabs: [
        {
          title: "EPISODES",
          content: cardsDiv,
        },
        {
          title: "FOR GROWN UPS",
          content: forParentsContent,
        },
      ],
      onTabbing: (e: Event) => {
        this.handleToggle();
      },
      onCreated: (element, data) => {
        this.modal = new Modal({
          id: "for-grown-ups",
          className: "modal-for-grown-ups",
          fadeType: "slide-right",
        });

        const contentMap: {
          [key: string]: { title: string; content: string };
        } = {
          "about-insideu": {
            title: "About InsideU",
            content: `
              <p>
                Taking inspiration from Disney and Pixar's <em>Inside Out</em>, the overall goal of the Crown Institute's InsideU is to develop and study highly engaging social and emotional learning tools that are relevant to a diverse population of children and youth. 
              </p>
              <p>
                The Crown Institute worked directly with Pixar Animation Studios to create InsideU. This collaborative project aims to harness <em>Inside Out</em>'s potential as a powerful, entertaining, and educational tool for improving peoples' mental and emotional wellness. 
              </p>
              <p>
                InsideU was collaboratively designed with youth using unique characters and narratives from Pixar's <em>Inside Out</em>. A combination of clips from the iconic movie and interactive learning opportunities are deeply rooted in elements of evidence-based psychotherapies. InsideU facilitates the creative application of social and emotional learning skills to real-world dilemmas faced by elementary school-aged children. 
              </p>`,
          },
          "about-us": {
            title: "About us",
            content: `
              <p>
                InsideU was created by the Renée Crown Wellness Institute at the University of Colorado Boulder in partnership with Pixar Animation Studios, Boys and Girls Clubs of Metro Denver, and Liquid Interactive. It brings to life our vision of building a world in which all young people thrive, supported by the inner resources and caring relationships required for a lifetime of wellness.
              </p>
              <p>
                Our vision invites many disciplines, perspectives and people to come together. We seek to promote the wellness of young people and the systems and adults who support them through interdisciplinary research-practice partnerships. That is the shared work of the Renée Crown Wellness Institute.
              </p>
              <p>
                Please visit the <a href="https://www.colorado.edu/crowninstitute/" target="_blank">Renée Crown Wellness Institute website</a> for more information. 
              </p>
            `,
          },
          "privacy-page": {
            title: "Privacy Page",
            content: `
              <p>
                InsideU does not collect personally identifiable information. InsideU is made available within the University of Colorado domain.  
              </p>
              <p>
                For website administration purposes, information, other than personally identifiable information, is collected for analysis. This information is used to help diagnose problems, assess what information is most of interest, determine technical design specifications, identify system performance and/or problem areas, and other administrative functions. This information includes logs of user visits to University of Colorado Boulder websites, network traffic logs necessary to ensure security and optimum performance and small text files called "cookies" used to keep track of settings.
              </p>
              <p>
                The information provided in this privacy statement should not be construed as giving business, legal or other advice, or as guaranteeing the security of information provided through CU Boulder's websites.
              </p>
            `,
          },
          "science-behind": {
            title: "Science behind InsideU",
            content: `
              <p>
                Navigating an increasingly complex world can be challenging. Hundreds of studies have shown that effective Social and emotional learning (SEL) programming can have positive outcomes, including those related to emotional wellbeing and academics (Durlak et al, 2011). However, challenges remain including accessibility, cost, and uptake.
              </p>
              <p>
                Given the potential impact for SEL programming on positive youth development, it's important that such programs are widely accessible. Digital platforms and online applications may serve as effective methods for increasing accessibility to programming (Kazdin & Blase, 2011). Further, entertainment education which integrates health messaging in popular media, can help reduce resistance and increase engagement with educational material (Moyer-Gusé, 2008). Finally, designing programming in direct collaboration with youth and community members can help ensure learning opportunities reflect and honor the lived experiences of the audiences served.   
              </p>
              <p>
                InsideU builds on this body of research. The Crown Institute has integrated aspects of evidenced-based SEL, leveraging world class entertainment from Disney and Pixar's <em>Inside Out</em>, and collaboratively designing content with youth, practitioners, artists, and researchers.
              </p>
              <p>
                Durlak, J. A., Weissberg, R. P., Dymnicki, A. B., Taylor, R. D., & Schellinger, K. B. (2011). The impact of enhancing students' social and emotional learning: a meta-analysis of school-based universal interventions. Child development, 82(1), 405-432. <a href="https://doi.org/10.1111/j.1467-8624.2010.01564.x" target="_blank">https://doi.org/10.1111/j.1467-8624.2010.01564.x</a>
              </p>
              <p>
                Kazdin, A. E., & Blase, S. L. (2011). Rebooting Psychotherapy Research and Practice to Reduce the Burden of Mental Illness. Perspectives on psychological science : a journal of the Association for Psychological Science, 6(1), 21-37. <a href="https://doi.org/10.1177/1745691610393527" target="_blank">https://doi.org/10.1177/1745691610393527</a> 
              </p>
              <p>
                Moyer-Gusé, E. (2008), Toward a Theory of Entertainment Persuasion: Explaining the Persuasive Effects of Entertainment-Education Messages. Communication Theory, 18: 407-425. <a href="https://doi.org/10.1111/j.1468-2885.2008.00328.x" target="_blank">https://doi.org/10.1111/j.1468-2885.2008.00328.x</a> 
              </p>
            `,
          },
        };

        let linkClickFn = (e: MouseEvent) => {
          e.preventDefault();
          const target = e.target as HTMLLinkElement;
          const contentTarget =
            target.getAttribute("data-content-target") ?? "";
          const content = contentMap[contentTarget];

          if (content) {
            this.modal.setTitle(content.title);
            this.modal.setContent(content.content);
            this.modal.show();
          }
        };

        element
          .querySelectorAll(".helpful-resources-links a")
          .forEach((link) => {
            (link as HTMLElement).onclick = (e: MouseEvent) => linkClickFn(e);
          });
      },
    });

    window.addEventListener("scroll", (e: any) => {
      this.handleToggle(window.scrollY > 1);
    });

    contentContainer.appendChild(tabs.element);

    this.domElement = this.add.dom(0, 0, contentContainer);
    this.domElement.setOrigin(0);
  }

  handleToggle = (toggle: boolean = true) => {
    const action = toggle ? "add" : "remove";

    this.homeTitle?.classList[action]("toggled");
    this.logo?.classList[action]("toggled");
    this.contentContainer?.classList[action]("toggled");
    this.overlay2.classList[action]("toggled");
  };

  handleCardMouseLeave = (e) => {};

  handleCardHover = (e) => {};

  private cardFn = (e: MouseEvent | TouchEvent) => {
    const target = e.target as HTMLElement;
    const parent = target.closest(".card") as HTMLElement;
    parent.classList.add("pressed");
    this.modal.destroy();
  };

  mtpCallback = (e: MouseEvent | TouchEvent) => {
    this.cardFn(e);
    sceneFadeInFadeOut(this, "MTPScene");
  };

  private createBackgroundGroup(bodyElement: HTMLElement | null) {
    const homeBgElem = document.querySelector(".home-background");

    if (homeBgElem) {
      homeBgElem.classList.remove("hide");
      this.overlay2 = document.querySelector(
        ".home-background-overlay2"
      ) as HTMLElement;
    } else {
      const homeBg = document.createElement("div");
      homeBg.className = "home-background";

      const homeBgImage = document.createElement("div");
      homeBgImage.className = "home-background-image";

      const homeBgOverlay1 = document.createElement("div");
      homeBgOverlay1.className = "home-background-overlay1";

      const homeBgOverlay2 = document.createElement("div");
      homeBgOverlay2.className = "home-background-overlay2";

      this.overlay2 = homeBgOverlay2;

      const copyright = document.createElement("div");
      copyright.className = "copyright";
      copyright.innerHTML = `&copy;Disney/Pixar`;

      homeBg.appendChild(homeBgImage);
      homeBg.appendChild(homeBgOverlay1);
      homeBg.appendChild(homeBgOverlay2);

      bodyElement?.appendChild(homeBg);
      bodyElement?.appendChild(copyright);
    }
  }

  private createSplashScreen(bodyElement: HTMLElement | null) {
    const componentName = "splash-screen";
    const isAppInitialised =
      sessionStorage.getItem(SessionNames.APP_STATE) === AppStates.INITIALIZED;

    const logoClass = `${componentName}__logo`;
    const logo = document.createElement("img");
    logo.classList.add(logoClass);
    logo.src = "assets/Inside-U.png";
    logo.setAttribute("draggable", "false");
    logo.setAttribute("loading", "lazy");

    this.logo = logo;

    if (isAppInitialised) {
      logo.classList.add(`${logoClass}--fixed`);
    } else {
      const overlay = document.createElement("div");
      overlay.classList.add(`${componentName}__overlay`);

      bodyElement?.appendChild(overlay);
      bodyElement?.classList.add("splash-screen");

      setTimeout(() => {
        bodyElement?.classList.remove("splash-screen");
        overlay.remove();
        logo.classList.add(`${logoClass}--fixed`);
      }, 4200); // 3s timing from keyframes + 1s from animation delay + 200ms buffer time
    }

    bodyElement?.appendChild(logo);
  }

  private createCrownLogo(): HTMLElement {
    const crownLogo = document.createElement("div");
    crownLogo.classList.add("crown-logo");

    const crownLogoImg = document.createElement("img");
    crownLogoImg.setAttribute("draggable", "false");
    crownLogoImg.setAttribute("loading", "lazy");
    crownLogoImg.src = "assets/reneeCrown-cobrand.png";
    crownLogo.appendChild(crownLogoImg);

    return crownLogo;
  }
}
